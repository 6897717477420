import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { motion } from "framer-motion";
import Sesion from "./components/Sesion";
import { sesionIniciada} from './controllers/configuracionFirebase';
import toast, { Toaster } from 'react-hot-toast';
import Loading from './components/Loading';
import WebFont from 'webfontloader';
import TorneosPublicidad from './components/TorneosPublicidad';
import SobreNosotros from './components/SobreNosotros';

function App() {

  const [ usuario, setUsuario ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    sesionIniciada(setUsuario);

    WebFont.load({
      google: {
        families: [
          "Abril Fatface",
          "IBM Plex Mono",
          "Acme",
          "Yeseva One",
          "Fira Sans Extra Condensed",
          "Poppins",
          "Merriweather Sans",
          "Bebas Neue",
          "EB Garamond",
          "Alkatra",
          "Calistoga",
          "Oleo Script",
          "Libre Baskerville",
          "Lora",
          'Anton SC',
          'Gothic A1',
        ]
      }
    });

    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 920);
    if (seconds === 0) {
      setIsLoading(false);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [setUsuario, setIsLoading, seconds])

  return (
    <div className="App">
      {isLoading ?(
        <Loading/>
      ):(
      <Router>
        <motion.nav
          className="navbar navbar-expand-lg bg-light"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          style={{zIndex: 1000}}
        >
          <div className="container-fluid">
            <div>
              <img
                className='img-barra-menu'
                style={{ maxWidth: "50px" }}
                src={require("./img/img-logo-swimmingt.webp")}
                alt="logo prowebsports"
              />
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="w-100 navbar-nav justify-content-end me-auto mb-2 mb-lg-0">
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/"
                  >TORNEOS</Link>
                </li>
                {/*
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/afiliacion"
                  >AFILIACIÓN AL CLUB</Link>
                </li>
                
                 <li className="nav-item item-principal">
                   <Link
                     className="nav-link link-principal"
                     to="/"
                   >UNIFORME</Link>
                 </li>
                 <li className="nav-item item-principal">
                 <Link
                 className="nav-link link-principal"
                 to="/"
                 >CONTÁCTENOS</Link>
                 </li>
                
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/horarios"
                  >HORARIOS</Link>
                </li>
                */}
                <li className="nav-item item-principal">
                  <Link
                    className="nav-link link-principal"
                    to="/sobrenosotros"
                  >SOBRE NOSOTROS</Link>
                </li>
              </ul>
            </div>
          </div>
        </motion.nav>
        <Routes>
          <Route
            path="/*"
            element={
              <Sesion usuario={usuario} setUsuario={setUsuario}/>
            }
          />
          <Route
            path="/sobrenosotros"
            element={
              <SobreNosotros/>
            }
          />
        </Routes>
      </Router>
      )}
      <Toaster containerStyle={{textAlign: 'start'}}/>
    </div>
  );
}

export default App;
