import React, { useState, useEffect }  from 'react'
import { generarIdPrueba, registrarPrueba, eliminarPruebas, registrarCategoria, traerListadoCategoria } from '../controllers/configuracionFirebase';
import Swal from 'sweetalert2'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';

const Pruebas = ({ listPruebas, listadoCategorias }) => {

  const [ pruebas, setPruebas ] = useState([]);
  const [ prueba, setPrueba ] = useState(['']);
  const [ dialogPrueba, setDialogPrueba ] = useState(false);
  const [ dialogCategoria, setDialogCategoria ] = useState(false);
  const [ dialogGrupos, setDialogGrupos ] = useState(false);
  const [ expandedRows, setExpandedRows ] = useState(null);
  const [ premiacionElegida, setPremiacionElegida ] = useState('');
  const [ gruposPrueba, setGruposPrueba ] = useState([]);
  const [ requisitos, setRequisitos ] = useState([{
    edadInicio: '',
    edadFin: '',
    genero: ''
  }]);
  const [ categorias, setCategorias ] = useState([{
    valor: '',
    nombre: '',
    color: '',
    premiacion: '',
    titulo: '',
    grupos: []
  }]);

  function eliminarPrueba(prueba, categoria, index){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 0; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Esta seguro de eliminar la </br> prueba?
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        const auxPruebas = [...pruebas];
        auxPruebas.splice(index, 1);
        setPruebas(auxPruebas);
        eliminarPruebas(prueba, categoria);
      }
    })
  }

  function eliminarCategoria(index){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 10px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Esta seguro de eliminar la categoria?
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 4,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        const auxCategorias = [...listadoCategorias];
        auxCategorias.splice(index, 1);

        let contadorColor = 1;
        for (let i = 0; i < auxCategorias.length; i++) {
          switch (contadorColor) {
            case 1:
              auxCategorias[i].color = 'text-warning';
              break;
            case 2:
              auxCategorias[i].color = 'text-info';
              break;
            case 3:
              auxCategorias[i].color = 'text-danger';
              contadorColor = 0;
              break;
            default:
              break;
          }
        
          contadorColor++;
        }
        registrarCategoria(auxCategorias);
      }
    })
  }

  function anadirPrueba(){
    const auxPrueba = [...prueba];
    const aux = '';
    auxPrueba.push(aux);
    setPrueba(auxPrueba);
  } 

  function quitarPrueba(){
    const auxPrueba = [...prueba];
    auxPrueba.splice(auxPrueba.length - 1, 1);
    setPrueba(auxPrueba);
  }

  function agregarRequisito(){
    const requisito = {
      edadInicio: '',
      edadFin: '',
      genero: ''
    }

    const auxRequisito = [...requisitos, requisito];
    setRequisitos(auxRequisito);
  }

  function quitarRequisito(){
    const auxRequisito = [...requisitos];
    auxRequisito.splice(auxRequisito.length - 1, 1);
    setRequisitos(auxRequisito);
  }

  function anadirCategoria(){
    const categoria = {
      valor: '',
      nombre: '',
      color: '',
      premiacion: '',
      titulo: '',
      grupos: []
    }

    const auxCategoria = [...categorias, categoria];
    setCategorias(auxCategoria);
  }

  function quitarCategoria(){
    const auxCategoria = [...categorias];
    auxCategoria.splice(auxCategoria.length - 1, 1);
    setCategorias(auxCategoria);
  }

  function anadirGrupo(){
    const auxCategorias = [...categorias];
    const grupo = {
      nombre: '',
      edadInicio: '',
      edadFin: ''
    }

    auxCategorias[0].grupos.push(grupo);
    setCategorias(auxCategorias);
  }

  function quitarGrupo(){
    const auxCategorias = [...categorias];
    auxCategorias[0].grupos.splice(auxCategorias[0].grupos.length - 1, 1);
    setCategorias(auxCategorias);
  }

  const agregarPC = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button icon="pi pi-plus" severity="success" raised tooltip='Pruebas' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogPrueba(true)}/>
        <Button className='ms-1' icon="pi pi-plus" severity="info" raised tooltip='Categorias' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}} onClick={() => setDialogCategoria(true)}/>
      </div>
    );
  };

  function onSubmitRegistrarPrueba(e){
    e.preventDefault();
    const categoria = e.target.categoria.value;
    const auxPruebas = [...pruebas];
    const auxPrueba = [];
    
    for (let i = 0; i < requisitos.length; i++) {
      requisitos[i].edadInicio = document.getElementById(`edadInicio${i}`).value;
      requisitos[i].edadFin = document.getElementById(`edadFin${i}`).value;
      requisitos[i].genero = document.getElementById(`genero${i}`).value;
    }
    
    for (let i = 0; i < prueba.length; i++) {
      const id = generarIdPrueba();
      const prueba = document.getElementById(`prueba${i}`).value;
      
      const aux = {
        id,
        prueba,
        categoria,
        requisitos
      }
  
      auxPruebas.push(aux);
      auxPrueba.push(aux);
      setPruebas(auxPruebas);
    }
    
    registrarPrueba(auxPrueba);
    e.target.reset();

    const auxRequisito = [...requisitos];
    auxRequisito.splice(1, auxRequisito.length - 1);
    setRequisitos(auxRequisito);
    setPrueba(['']);
  }

  function onSubmitRegistrarCategoria(e){
    e.preventDefault();
    const auxCategorias = [...categorias];
    const auxListadoCategorias = [...listadoCategorias];
    
    for (let i = 0; i < auxCategorias.length; i++) {
      const auxGrupos = [];
      auxCategorias[i].valor = document.getElementById(`valor${i}`).value;
      auxCategorias[i].nombre = document.getElementById(`nombre${i}`).value;
      auxCategorias[i].premiacion = document.getElementById(`premiacion${i}`).value;
      auxCategorias[i].titulo = document.getElementById(`titulo${i}`).value;
      for (let j = 0; j < auxCategorias[i].grupos.length; j++) {
        const objGrupo = {
          nombre: document.getElementById(`nombreGru${i}${j}`).value,
          edadInicio: document.getElementById(`edadInicioGru${i}${j}`).value,
          edadFin: document.getElementById(`edadFinGru${i}${j}`).value
        }
  
        auxGrupos.push(objGrupo);
      }
      auxCategorias[i].grupos = auxGrupos;
    }

    

    auxListadoCategorias.push(...categorias);
    let contadorColor = 1;
    for (let i = 0; i < auxListadoCategorias.length; i++) {
      switch (contadorColor) {
        case 1:
          auxListadoCategorias[i].color = 'text-warning';
          break;
        case 2:
          auxListadoCategorias[i].color = 'text-info';
          break;
        case 3:
          auxListadoCategorias[i].color = 'text-danger';
          contadorColor = 0;
          break;
        default:
          break;
      }

      contadorColor++;
    }
    
    registrarCategoria(auxListadoCategorias);
    e.target.reset();

    const auxCategoria = [...categorias];
    auxCategoria.splice(1, auxCategoria.length - 1);
    setCategorias(auxCategoria);
  }

  const acciones = (prueba, categoria, indexPrueba) => {
    return (
      <div>
        <Button icon='pi pi-times' text severity='danger' tooltip='Eliminar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
        onClick={() => eliminarPrueba(prueba, categoria, indexPrueba)}/>
      </div>
    );
  };

  const accionesCategoria = (indexCate) => {
    return (
      <div>
        <Button icon='pi pi-times' text severity='danger' tooltip='Eliminar' tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}} style={{borderRadius: '50%'}}
        onClick={() => eliminarCategoria(indexCate)}/>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <DataTable value={data.requisitos} tableStyle={{ maxWidth: '400px', margin: 'auto'}} emptyMessage="No hay requisitos aún.">
        <Column field="edadInicio" header="Edad Inicio" style={{ width: '80px'}}></Column>
        <Column field="edadFin" header="Edad Fin" style={{ width: '80px'}}></Column>
        <Column field="genero" header="Genero" style={{ width: '80px'}}></Column>
      </DataTable>
    );
  };

  function guardarGruposDefinitivos(){
    const auxCategorias = [...categorias];
    auxCategorias[0].grupos = gruposPrueba;
    setCategorias(auxCategorias);
    setDialogGrupos(false);
    setGruposPrueba([]);
  }

  function generarGrupos(e){
    e.preventDefault();
    const edadInicio = e.target.edadInicio.value;
    const edadFin = e.target.edadFin.value;
    const xx = e.target.gruposDe.value;

    let contadorGrupos = 0;
    const auxGruposPrueba = [];
    for (let i = parseInt(edadInicio); i <= parseInt(edadFin); i+=parseInt(xx)) {
      const objGrupo = {
        nombre: `${i} - ${(i + parseInt(xx))}`,
        edadInicio: i,
        edadFin: (i + parseInt(xx))
      }

      if(contadorGrupos >= 0){
        i+=1;
      }
      contadorGrupos++;
      auxGruposPrueba.push(objGrupo);
    }

    setGruposPrueba(auxGruposPrueba);
    e.target.reset();
  }

  function generarGrupos1a1(){
    const edadInicio = document.getElementById('edadInicio').value;
    const edadFin = document.getElementById('edadFin').value;
    const xx = document.getElementById('gruposDe').value;

    let contadorGrupos = 0;
    const auxGruposPrueba = [];
    for (let i = parseInt(edadInicio); i <= parseInt(edadFin); i+=parseInt(xx)) {
      const objGrupo = {
        nombre: i,
        edadInicio: i,
        edadFin: i
      }

      contadorGrupos++;
      auxGruposPrueba.push(objGrupo);
    }

    setGruposPrueba(auxGruposPrueba);
  }

  useEffect(() => {
    setPruebas(listPruebas);
  }, [])

  useEffect(() =>{
    const auxCategorias = [...categorias];
    auxCategorias[0].grupos = [];
    setCategorias(auxCategorias);
  },[premiacionElegida])
  
  return (
    <>
      <div className='bg-light position-relative'>
        <Toolbar left={agregarPC}></Toolbar>
        <DataTable value={pruebas} size='small' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50, 100]} stripedRows footer={'Total pruebas: ' + pruebas.length} emptyMessage="No hay pruebas registradas aún."
        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate}>
          <Column expander />
          <Column header="#" body={(_, index)=>{return index.rowIndex + 1}}></Column>
          <Column field="prueba" header="Prueba"></Column>
          <Column field="categoria" header="Categoria"></Column>
          <Column body={(prueba, indexPrueba)=>{return acciones(prueba.prueba, prueba.categoria, indexPrueba.rowIndex)}}></Column>
        </DataTable>
      </div>
      <Dialog visible={dialogPrueba} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Registrar Pruebas`} modal className="p-fluid" onHide={() => setDialogPrueba(false)}>
        <div className="field">
          <form onSubmit={onSubmitRegistrarPrueba} className='m-auto pt-2 pb-2'>
            <div className="form-floating">
              <select className='form-select mt-2' name={`categoria`} id={`categoria`} required>
                <option value="">Elige la categoria</option>
                {listadoCategorias.map((prue, indexPrue)=>{
                  return <option key={indexPrue} value={prue.valor}>{prue.valor}</option>
                })}
              </select>
              <label htmlFor="categoria">{listadoCategorias.length} Categorias Disponibles</label>
            </div>
            <div className='d-flex flex-wrap justify-content-center'>
              <input type="button" className='btn btn-primary mt-1 mb-1' value='Añadir Prueba' onClick={() => anadirPrueba()}/>
              <input type="button" className='btn ms-1 btn-danger mt-1 mb-1' value='Quitar Prueba' onClick={() => quitarPrueba()}/>
            </div>
            {prueba.map((prue, indexPrue)=>{
              return <div key={indexPrue} className="form-floating">
              <input type="text" className='form-control' name={`prueba${indexPrue}`} id={`prueba${indexPrue}`}/>
              <label htmlFor={`prueba${indexPrue}`}>Prueba ej: 25m Patada Espalda</label>
              <hr className='mt-1 mb-1'/>
            </div>
            })}
            <div className='d-flex flex-wrap justify-content-center'>
              <input type="button" className='btn btn-primary mt-1 ms-1' value='Agregar otro Requisito' onClick={() => agregarRequisito()}/>
              <input type="button" className='btn btn-danger mt-1 ms-1' value='Quitar Requisito' onClick={() => quitarRequisito()}/>
            </div>
            {requisitos.map((req, indexRe)=>{
              return <div key={indexRe}>
                <div className='d-flex flex-wrap justify-content-between'>
                  <div className="form-floating">
                    <input type="number" className='form-control mt-1' name={`edadInicio${indexRe}`} id={`edadInicio${indexRe}`} style={{ width: '290px'}}/>
                    <label htmlFor={`edadInicio${indexRe}`}>Edad Inicio</label>
                  </div>
                  <div className="form-floating">
                    <input type="number" className='form-control mt-1' name={`edadFin${indexRe}`} id={`edadFin${indexRe}`} style={{ width: '290px'}}/>
                    <label htmlFor={`edadFin${indexRe}`}>Edad Fin</label>
                  </div>
                  <div className="form-floating">
                    <select name={`genero${indexRe}`} id={`genero${indexRe}`} className='form-select mt-1' required style={{ width: '150px'}}>
                      <option value="">Elige</option>
                      <option value="Femenino">Femenino</option>
                      <option value="Masculino">Masculino</option>
                      <option value="Mixto">Mixto</option>
                    </select>
                    <label htmlFor="genero">Género</label>
                  </div>
                </div>
                <hr className='mt-1 mb-1'/>
              </div>
            })}
            <div className='d-flex justify-content-center'>
              <input type="submit" className='btn btn-success mt-1' value='Registrar' />
            </div>
          </form>
        </div>
      </Dialog>
      <Dialog visible={dialogCategoria} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Registrar Categorias`} modal className="p-fluid" onHide={() => setDialogCategoria(false)}>
        <div className="field">
          <div className='d-flex flex-wrap justify-content-center'>
            <input type="button" className='btn btn-success mt-2' value='Añadir Categoria' onClick={() => anadirCategoria()}/> 
            <input type="button" className='btn ms-2 btn-danger mt-2' value='Quitar Categoria' onClick={() => quitarCategoria()}/>
          </div>
          <form onSubmit={onSubmitRegistrarCategoria} className='m-auto pt-2 pb-2'>
            {categorias.map((cate, indexCate)=>{
              return <div key={indexCate}>
                <div className='d-flex flex-wrap'>
                  <div className="form-floating w-100 mb-1">
                    <input type="text" className='form-control me-1' name={`titulo${indexCate}`} id={`titulo${indexCate}`}/>
                    <label htmlFor={`titulo${indexCate}`}>Titulo Prueba ej: Master/ Abierta 12 y Mas </label>
                  </div>
                  <div className="form-floating">
                    <input type="text" className='form-control me-1' name={`valor${indexCate}`} id={`valor${indexCate}`} style={{ width: '290px'}}/>
                    <label htmlFor={`valor${indexCate}`}>Codigo ej: menores5y6</label>
                  </div>
                  <div className="form-floating">
                    <input type="text" className='form-control me-1' name={`nombre${indexCate}`} id={`nombre${indexCate}`} style={{ width: '290px'}}/>
                    <label htmlFor={`nombre${indexCate}`}>Nombre ej: Menores 5 y 6 Juntos</label>
                  </div>
                  <div className="form-floating">
                    <select name={`premiacion${indexCate}`} id={`premiacion${indexCate}`} className='form-select me-1' required style={{ width: '143px'}} onChange={(e) => setPremiacionElegida(e.target.value)}>
                      <option value="">Elige</option>
                      <option value="juntos">Juntos</option>
                      <option value="separados">Separados</option>
                    </select>
                    <label htmlFor="premiacion">Premiación</label>
                  </div>
                </div>
                <hr className='mt-1 mb-1'/>
                <div className='d-flex justify-content-center flex-column'>
                  {premiacionElegida === 'separados' ?(
                    <div className='d-flex justify-content-center flex-column'>
                      <input type="button" className='btn btn-primary mb-1' value='Generar Grupos' onClick={() => setDialogGrupos(true)}/>
                      <div className='d-flex flex-wrap justify-content-center'>
                        <input type="button" className='btn btn-success mt-1 mb-1' value='Añadir Grupo' onClick={() => anadirGrupo()}/> 
                        <input type="button" className='btn ms-2 btn-danger mt-1 mb-1' value='Quitar Grupo' onClick={() => quitarGrupo()}/>
                      </div>
                      {cate.grupos.map((gru, indexGru)=>{
                        return <div className='d-flex flex-wrap justify-content-around'>
                          <div className="form-floating mb-1">
                            <input type="text" className='form-control' name={`nombreGru${indexCate}${indexGru}`} id={`nombreGru${indexCate}${indexGru}`} defaultValue={gru.nombre}/>
                            <label htmlFor={`nombreGru${indexCate}${indexGru}`}>Nombre ej: Abierta 12 y Mas </label>
                          </div>
                          <div className="form-floating mb-1">
                            <input type="number" className='form-control' name={`edadInicioGru${indexCate}${indexGru}`} id={`edadInicioGru${indexCate}${indexGru}`} defaultValue={gru.edadInicio} style={{ width: '200px'}}/>
                            <label htmlFor={`edadInicioGru${indexCate}${indexGru}`}>Edad Inicio</label>
                          </div>
                          <div className="form-floating mb-1">
                            <input type="number" className='form-control' name={`edadFinGru${indexCate}${indexGru}`} id={`edadFinGru${indexCate}${indexGru}`} defaultValue={gru.edadFin} style={{ width: '200px'}}/>
                            <label htmlFor={`edadFinGru${indexCate}${indexGru}`}>Edad Fin</label>
                          </div>
                        </div>
                      })}
                    </div>
                  ):(
                    <></>
                  )}
                </div>
              </div>
            })}
            <input type="submit" className='btn btn-success mb-1' value='Registrar' />
          </form>
          <DataTable value={listadoCategorias} size='small' paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50, 100]} stripedRows footer={'Total pruebas: ' + listadoCategorias.length} emptyMessage="No hay categorias registradas aún.">
          <Column header="#" body={(_, index)=>{return index.rowIndex + 1}}></Column>
          <Column field="valor" header="Valor"></Column>
          <Column field="nombre" header="Nombre"></Column>
          <Column field="titulo" header="Titulo"></Column>
          <Column field="premiacion" header="Premiación"></Column>
          <Column body={(_, indexCate)=>{return accionesCategoria(indexCate.rowIndex)}}></Column>
        </DataTable>
        </div>
      </Dialog>
      <Dialog visible={dialogGrupos} style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} header={`Creación de grupos`} modal className="p-fluid" onHide={() => setDialogGrupos(false)}>
        <div className="field">
          <form onSubmit={generarGrupos}>
            <div className='d-flex flex-wrap justify-content-around'>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' name={`gruposDe`} id={`gruposDe`} style={{ width: '200px'}}/>
                <label htmlFor={`gruposDe`}>Grupos</label>
              </div>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' name={`edadInicio`} id={`edadInicio`} style={{ width: '200px'}}/>
                <label htmlFor={`edadInicio`}>Edad Inicio</label>
              </div>
              <div className="form-floating mb-1">
                <input type="number" className='form-control' name={`edadFin`} id={`edadFin`} style={{ width: '200px'}}/>
                <label htmlFor={`edadFin`}>Edad Fin</label>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
              <input type="submit" className='btn btn-primary' value='Generar' />
              <input type="button" className='btn btn-warning ms-1' value='Generar 1 a 1' onClick={() => generarGrupos1a1()}/>
            </div>
          </form>
          <hr className='mt-1 mb-1'/>
          {gruposPrueba.length !== 0 ?(
            <div>
              <div className='d-flex flex-wrap justify-content-around'>
                {gruposPrueba.map((gruP, indexGruP)=>{
                  return <button key={indexGruP} className='btn btn-info mt-1 ms-1'>{`(${gruP.nombre})`}</button>
                })}
              </div>
              <hr className='mt-1 mb-1'/>
              <div className='d-flex justify-content-center'>
                <input type="button" className='btn btn-success' value='Guardar' onClick={() => guardarGruposDefinitivos()}/>
              </div>
            </div>
          ):(
            <></>
          )}
        </div>
      </Dialog>
    </>
  )
}

export default Pruebas;