import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { Tooltip as Mensaje } from "react-tooltip";
import { FcApproval, FcHighPriority } from "react-icons/fc";
import { abrirBaseDeDatosLocal, buscarNombresAutorizados, puntosEstablecidos10CarrilesFecna, puntosEstablecidos8CarrilesFecna, traerResultados } from '../controllers/configuracionFirebase';
import { pdfPruebaIndividual, pdfTiempos } from '../controllers/pdfs';
import { Tooltip } from 'primereact/tooltip';
import { SpeedDial } from 'primereact/speeddial';
import { exportTiempos } from '../controllers/xlsx';
import { arreglarTiempoEstandar } from '../controllers/pdfs';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { datosAdmin, departamentosColombia, edades, marcasMinimasInterclubes, marcasMinimasInterligas, pruebasRecords } from '../controllers/administrador';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';

const ResultadosTiempos = ({ 
  resultados, 
  infoUsuario, 
  setResultados, 
  idTorneoParticipando,
  torneoEspecifico,
  pruebasCompletas,
  recordsMundial,
  recordsNacional }) => {

  const [ animateList, setAnimateList ] = useState(false);
  const [ conexion, setConexion ] = useState(true);
  const [ dialogHistorial, setDialogHistorial ] = useState(false);
  const [ historialDeportista, setHistorialDeportista ] = useState();
  const [ indexDeportista, setIndexDeportista ] = useState(0);
  //Marcas Mundiales
  const [ edadMarcaMundialM, setEdadMarcaMundialM ] = useState(15);
  const [ edadMarcaMundialF, setEdadMarcaMundialF ] = useState(15);
  const [ mejorMarcaTecnicaM, setMejorMarcaTecnicaM ] = useState([]);
  const [ mejorMarcaTecnicaF, setMejorMarcaTecnicaF ] = useState([]);
  const [ recordsMundialesElegidosM, setRecordsMundialesElegidosM ] = useState([]);
  const [ recordsMundialesElegidosF, setRecordsMundialesElegidosF ] = useState([]);
  const [ opcionesElegidosM, setOpcionesElegidosM ] = useState([]);
  const [ opcionesElegidosF, setOpcionesElegidosF ] = useState([]);
  //Marcas Nacionales
  const [ edadMarcaNacionalM, setEdadMarcaNacionalM ] = useState(15);
  const [ edadMarcaNacionalF, setEdadMarcaNacionalF ] = useState(15);
  const [ mejorMarcaTecnicaNacionalM, setMejorMarcaTecnicaNacionalM ] = useState([]);
  const [ mejorMarcaTecnicaNacionalF, setMejorMarcaTecnicaNacionalF ] = useState([]);
  const [ recordsNacionalesElegidosM, setRecordsNacionalesElegidosM ] = useState([]);
  const [ recordsNacionalesElegidosF, setRecordsNacionalesElegidosF ] = useState([]);
  const [ opcionesNacionalesElegidosM, setOpcionesNacionalesElegidosM ] = useState([]);
  const [ opcionesNacionalesElegidosF, setOpcionesNacionalesElegidosF ] = useState([]);
  //Marcas Minimas Interligas
  const [ edadMarcaMinimaLigaM, setEdadMarcaMinimaLigaM ] = useState(15);
  const [ edadMarcaMinimaLigaF, setEdadMarcaMinimaLigaF ] = useState(14);
  const [ mejorMarcaMinimaLigaM, setMejorMarcaMinimaLigaM ] = useState([]);
  const [ mejorMarcaMinimaLigaF, setMejorMarcaMinimaLigaF ] = useState([]);
  const [ marcasMinimasLigaElegidosM, setMarcasMinimasLigaesElegidosM ] = useState([]);
  const [ marcasMinimasLigaElegidosF, setMarcasMinimasLigaesElegidosF ] = useState([]);
  const [ opcionesMarcasMinimasLigaElegidosM, setOpcionesMarcasMinimasLigaElegidosM ] = useState([]);
  const [ opcionesMarcasMinimasLigaElegidosF, setOpcionesMarcasMinimasLigaElegidosF ] = useState([]);
  //Marcas Minimas Interclubes
  const [ edadMarcaMinimaClubesM, setEdadMarcaMinimaClubesM ] = useState(15);
  const [ edadMarcaMinimaClubesF, setEdadMarcaMinimaClubesF ] = useState(14);
  const [ mejorMarcaMinimaClubesM, setMejorMarcaMinimaClubesM ] = useState([]);
  const [ mejorMarcaMinimaClubesF, setMejorMarcaMinimaClubesF ] = useState([]);
  const [ marcasMinimasClubesElegidosM, setMarcasMinimasClubesesElegidosM ] = useState([]);
  const [ marcasMinimasClubesElegidosF, setMarcasMinimasClubesesElegidosF ] = useState([]);
  const [ opcionesMarcasMinimasClubesElegidosM, setOpcionesMarcasMinimasClubesElegidosM ] = useState([]);
  const [ opcionesMarcasMinimasClubesElegidosF, setOpcionesMarcasMinimasClubesElegidosF ] = useState([]);

  const [ nombresAutorizados, setNombresAutorizados ] = useState([]);
  const [ deportistasSeparados, setDeportistaSeparados] = useState({});
  const [ globalFilter, setGlobalFilter ] = useState('');
  const [ cantPuestos, setCantPuestos ] = useState('');
  const [ pdfUrl, setPdfUrl ] = useState();
  const resultadosRef = React.useRef(resultados);

  function manejoRendimiento(record, tiempo){
    if(tiempo && record && record !== '0:0:0' && tiempo !== '0:0:0'){
      const dividirTiempoA = record.split(':');
      const dividirTiempoB = tiempo.split(':');
      const tiempoMinA = dividirTiempoA[0];
      const tiempoSegA = dividirTiempoA[1];
      const tiempoMiliA = dividirTiempoA[2];
      const tiempoMinB = dividirTiempoB[0];
      const tiempoSegB = dividirTiempoB[1];
      const tiempoMiliB = dividirTiempoB[2];
      const tiempoA = parseInt(tiempoMinA) * 60 * 1000 + parseInt(tiempoSegA) * 1000 + parseInt(tiempoMiliA);
      const tiempoB = parseInt(tiempoMinB) * 60 * 1000 + parseInt(tiempoSegB) * 1000 + parseInt(tiempoMiliB);

      const resultadoRecords = parseFloat((tiempoA - tiempoB));
      
      return resultadoRecords;
    }else{
      return 0;
    }
  }

  function arreglarFormatoTiempo(resultadoRecords){
    const auxResultadoRecords = Math.abs(resultadoRecords);
    const minutos = Math.floor(auxResultadoRecords / (60 * 1000));
    const segundos = Math.floor((auxResultadoRecords % (60 * 1000)) / 1000);
    const milisegundos = auxResultadoRecords % 1000;
    if(minutos !== 0){
      return minutos + ':' + segundos + '.' + milisegundos;
    }else{
      return segundos + '.' + milisegundos;
    }
  }

  function sacarHistorialDeportista(nad){
    setDialogHistorial(true);
    const auxHistorial = [];
    resultados.forEach((result)=>{
      const objHistorial = {};
      result.nadadores.forEach((nadad, indexNadad)=>{
        if(nadad.identificacion === nad.identificacion && !nadad.equipo){
          objHistorial.puesto = indexNadad + 1;
          objHistorial.nPrueba = result.id + 1;
          objHistorial.prueba = result.prueba.prueba;
          objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
          objHistorial.tiempo = nadad.tiempo;
          objHistorial.puntos = parseInt(torneoEspecifico.carril) <= 8 ? puntosEstablecidos8CarrilesFecna(0, indexNadad) : puntosEstablecidos10CarrilesFecna(0, indexNadad);
          auxHistorial.push(objHistorial);
        }

        if(nadad.equipo){
          const auxNombre = nadad.equipo.filter((eq) => {return eq === nad.nombre});
          if(auxNombre.length !== 0){
            objHistorial.puesto = indexNadad + 1;
            objHistorial.nPrueba = result.id + 1;
            objHistorial.prueba = result.prueba.prueba;
            objHistorial.record = nadad.equipo === null ? nadad.record : '0:0:0';
            objHistorial.tiempo = nadad.tiempo;
            objHistorial.puntos = parseInt(torneoEspecifico.carril) <= 8 ? puntosEstablecidos8CarrilesFecna(0, indexNadad) : puntosEstablecidos10CarrilesFecna(0, indexNadad);
            auxHistorial.push(objHistorial);
          }
        }
      })
    })
    nad.pruebas = [...auxHistorial];
    setHistorialDeportista(nad);
  }

  function sacarMarcasMundialesMElegidas(e){
    let auxMarcasElegidas = [...recordsMundialesElegidosM];
    let auxOpcionesElegidas = [...opcionesElegidosM];
    
    if(e.checked){
      const objMarca = {
        prueba: e.value,
        record: recordsMundial['masculino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][e.value]?.records
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setRecordsMundialesElegidosM(auxMarcasElegidas);
    setOpcionesElegidosM(auxOpcionesElegidas);
  }

  function sacarMarcasMundialesFElegidas(e){
    let auxMarcasElegidas = [...recordsMundialesElegidosF];
    let auxOpcionesElegidas = [...opcionesElegidosF];

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        record: recordsMundial['femenino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][e.value]?.records
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setRecordsMundialesElegidosF(auxMarcasElegidas);
    setOpcionesElegidosF(auxOpcionesElegidas);
  }

  function sacarMarcasNacionalesMElegidas(e){
    let auxMarcasElegidas = [...recordsNacionalesElegidosM];
    let auxOpcionesElegidas = [...opcionesNacionalesElegidosM];

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        record: recordsNacional['masculino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][e.value]?.records
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setRecordsNacionalesElegidosM(auxMarcasElegidas);
    setOpcionesNacionalesElegidosM(auxOpcionesElegidas);
  }

  function sacarMarcasNacionalesFElegidas(e){
    let auxMarcasElegidas = [...recordsNacionalesElegidosF];
    let auxOpcionesElegidas = [...opcionesNacionalesElegidosF];

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        record: recordsNacional['femenino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][e.value]?.records
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setRecordsNacionalesElegidosF(auxMarcasElegidas);
    setOpcionesNacionalesElegidosF(auxOpcionesElegidas);
  }
  
  function sacarMarcasMinimasLigaMElegidas(e){
    let auxMarcasElegidas = [...marcasMinimasLigaElegidosM];
    let auxOpcionesElegidas = [...opcionesMarcasMinimasLigaElegidosM];

    const mmMasculino = marcasMinimasInterligas.filter((marc)=>{return marc.genero === 'Masculino'});
    const mmM = mmMasculino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaLigaM) >= 19 ? 19 : parseInt(edadMarcaMinimaLigaM))})[0]?.pruebas;

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        marcaMinima: mmM.filter((rec)=>{return rec.prueba === e.value})[0].marcaMinima
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setMarcasMinimasLigaesElegidosM(auxMarcasElegidas);
    setOpcionesMarcasMinimasLigaElegidosM(auxOpcionesElegidas);
  }

  function sacarMarcasMinimasLigaFElegidas(e){
    let auxMarcasElegidas = [...marcasMinimasLigaElegidosF];
    let auxOpcionesElegidas = [...opcionesMarcasMinimasLigaElegidosF];

    const mmFemenino = marcasMinimasInterligas.filter((marc)=>{return marc.genero === 'Femenino'});
    const mmF = mmFemenino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaLigaF) >= 18 ? 18 : parseInt(edadMarcaMinimaLigaF))})[0]?.pruebas;

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        marcaMinima: mmF.filter((rec)=>{return rec.prueba === e.value})[0].marcaMinima
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setMarcasMinimasLigaesElegidosF(auxMarcasElegidas);
    setOpcionesMarcasMinimasLigaElegidosF(auxOpcionesElegidas);
  }

  function sacarMarcasMinimasClubesMElegidas(e){
    let auxMarcasElegidas = [...marcasMinimasClubesElegidosM];
    let auxOpcionesElegidas = [...opcionesMarcasMinimasClubesElegidosM];

    const mmMasculino = marcasMinimasInterclubes.filter((marc)=>{return marc.genero === 'Masculino'});
    const mmM = mmMasculino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaClubesM) >= 19 ? 19 : parseInt(edadMarcaMinimaClubesM))})[0]?.pruebas;

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        marcaMinima: mmM.filter((rec)=>{return rec.prueba === e.value})[0].marcaMinima
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setMarcasMinimasClubesesElegidosM(auxMarcasElegidas);
    setOpcionesMarcasMinimasClubesElegidosM(auxOpcionesElegidas);
  }

  function sacarMarcasMinimasClubesFElegidas(e){
    let auxMarcasElegidas = [...marcasMinimasClubesElegidosF];
    let auxOpcionesElegidas = [...opcionesMarcasMinimasClubesElegidosF];

    const mmFemenino = marcasMinimasInterclubes.filter((marc)=>{return marc.genero === 'Femenino'});
    const mmF = mmFemenino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaClubesF) >= 18 ? 18 : parseInt(edadMarcaMinimaClubesF))})[0]?.pruebas;

    if(e.checked){
      const objMarca = {
        prueba: e.value,
        marcaMinima: mmF.filter((rec)=>{return rec.prueba === e.value})[0].marcaMinima
      }
      auxMarcasElegidas.push(objMarca);
      auxOpcionesElegidas.push(e.value);
    }else{
      auxMarcasElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
      auxOpcionesElegidas.splice(auxOpcionesElegidas.indexOf(e.value), 1);
    }
    setMarcasMinimasClubesesElegidosF(auxMarcasElegidas);
    setOpcionesMarcasMinimasClubesElegidosF(auxOpcionesElegidas);
  }

  function separarEdades(resul){
    setIndexDeportista(resul.id);
    // Crear un objeto para almacenar todas las categorías
    let categoria = {};
    let auxEdades = [];
    const auxNadadores = {};
    let auxCategoriaPersonalizadas = resul.categoria.match(new RegExp(resul.categoriaEdad, 'g'));
    if (auxCategoriaPersonalizadas) {
      resul.nadadores.forEach((nad) => {
        const edad = nad.edad;
        if (!auxNadadores[edad]) {
            auxNadadores[edad] = [];
        }
        auxNadadores[edad].push(nad);
      });
      auxEdades = [auxNadadores];
      // Almacenar la categoría en el objeto todasLasCategorias
      categoria[resul.categoria] = auxEdades;
    }
    // Al final, establecer el objeto completo como estado
    setDeportistaSeparados(categoria);
  }

  const tablaEdadesSeparadas = () => {
    const auxEdades = edades;
    const idResultado = resultados.filter((resu)=>{return resu.id === indexDeportista})
    if(idResultado.length !== 0){
      if(deportistasSeparados[idResultado[0].categoria]){
        return auxEdades.map((auxEd, indexAuxEd)=>{
          return <form key={indexAuxEd} className='formulario-tablas table-responsive-sm'>
            {deportistasSeparados[idResultado[0].categoria][0][auxEd] ? (
              <table style={{margin: '4px', maxWidth: '530px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}} className='shadow'>
                <thead className='text-light'>
                  <tr style={{borderBottom: '1px solid gray'}}>
                    <th className='fs-4'>{idResultado[0] ? idResultado[0].id + 1 : ''}</th>
                    <th colSpan={5} style={{fontSize: '18px', borderLeft: '1px solid gray'}}>{`${idResultado[0] ? `${auxEd} años` : ''} / ${idResultado[0] ? idResultado[0].prueba.prueba: ''}`}</th>
                  </tr>
                  <tr>
                    <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Pto</th>
                    <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Deportista</th>
                    <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Record</th>
                    <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Tiempo</th>
                    <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Rendim.</th>
                    <th style={{fontSize: '15px'}}>Club</th>
                  </tr>
                </thead>
                <tbody>
                {deportistasSeparados[idResultado[0].categoria][0][auxEd].map((auxDeport, indexAuxDeport)=>{
                  return <tr key={indexAuxDeport} style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexAuxDeport}`}}>
                  {indexAuxDeport === 0 ? <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px'}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : indexAuxDeport === 1 ? <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px'}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  indexAuxDeport === 2 ? <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px'}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}>{indexAuxDeport + 1}</td>}
                  <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{auxDeport.nombre}</td>
                  <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{auxDeport.record && auxDeport.record !== '0:0:0' ? arreglarTiempoEstandar(auxDeport.record) : auxDeport.tiempo === '0:0:0' ? 'NS' : auxDeport.tiempo === '61:0:0' ? 'DC' : `${arreglarTiempoEstandar(auxDeport.tiempo)}`}</td>
                  <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{auxDeport.tiempo === '0:0:0' ? 'NS' : auxDeport.tiempo === '61:0:0' ? 'DC' : `${arreglarTiempoEstandar(auxDeport.tiempo)}`}</td>
                  <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{manejoRendimiento(auxDeport.record, auxDeport.tiempo) >= 0 && auxDeport.tiempo !== '0:0:0' && auxDeport.tiempo !== '61:0:0'? 
                    <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(auxDeport.record, auxDeport.tiempo))}</label>
                    : 
                    <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(auxDeport.record, auxDeport.tiempo === '61:0:0' ? '0:0:0' : auxDeport.tiempo))}</label>
                  }</td>
                  <td className={`${infoUsuario.nombreclub === auxDeport.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '10px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{auxDeport.nombreclub}</td>
                </tr>
              })}
                </tbody>
              </table>
            ):(
            <></>
            )}
          </form>
        })
      }
    }
  }

  const items = [
    {
        label: 'Tiempos excel',
        icon: 'pi pi-file-excel',
        command: () => exportTiempos(resultados)
    },
    {

        label: 'Tiempos .pdf',
        icon: 'pi pi-file-pdf',
        command: () => pdfTiempos(resultados, setPdfUrl, torneoEspecifico, infoUsuario, pruebasCompletas)
    },
  ];

  function banderas(nombreclub){
    const buscarClub = nombresAutorizados.filter((auto)=>{return auto.nombre === nombreclub});
    if(buscarClub.length !== 0){
      const buscarBandera = departamentosColombia.filter((depBan) => {return depBan.dep === buscarClub[0].departamento});
      if(buscarBandera.length !== 0){
        return <img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={buscarBandera[0].bandera} alt='Bandera'/>
      }
    }
  }

  useEffect(() => {
    // Comparamos los arreglos anterior y actual para detectar cambios de posición
    if (JSON.stringify(resultadosRef.current) !== JSON.stringify(resultados)) {
      // Si hay una diferencia en los arreglos, activamos la animación
      setAnimateList(true);
    }

    function calcularDiferenciaTiempo(tiempoDeportista, recordMundial) {
      const tiempoRecord = recordMundial.split(':').map(Number);
    
      const auxTiempoDeportista = tiempoDeportista[0] * 60 * 1000 + tiempoDeportista[1] * 1000 + tiempoDeportista[2];
      const auxTiempoRecord = tiempoRecord[0] * 60 * 1000 + tiempoRecord[1] * 1000 + tiempoRecord[2];
      const diferencia = auxTiempoRecord - auxTiempoDeportista;
  
      return diferencia;
    }

    function mejorMarcaTecnicaMundial() {
    
      const nadadoresMasculinos = [];
      const nadadoresFemeninos = [];
    
      resultados.forEach((resul) => {
        resul.nadadores.forEach((deportista) => {
          if (resul.categoria.match(/\bM\b/g) && parseInt(deportista.edad) >= edadMarcaMundialM) {   
            const recordMundial = recordsMundialesElegidosM.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (recordMundial) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                recordMundial.record
              );
    
              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresMasculinos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: recordsMundial['masculino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][resul.prueba.prueba]?.records,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
    
          if (resul.categoria.match(/\bF\b/g) && parseInt(deportista.edad) >= edadMarcaMundialF) {
            const recordMundial = recordsMundialesElegidosF.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (recordMundial) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                recordMundial.record
              );
    
              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresFemeninos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: recordsMundial['femenino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][resul.prueba.prueba]?.records,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
        });
      });
    
      // Ordenar los nadadores por diferencia de tiempo de menor a mayor
      nadadoresMasculinos.sort((a, b) => {
        const diferenciaA = a.diferencia; 
        const diferenciaB = b.diferencia;

        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });

      nadadoresFemeninos.sort((a, b) => {
        const diferenciaA = a.diferencia;
        const diferenciaB = b.diferencia;
            
        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });
    
      setMejorMarcaTecnicaM(nadadoresMasculinos);
      setMejorMarcaTecnicaF(nadadoresFemeninos);
    }

    function mejorMarcaTecnicaNacional() {
    
      const nadadoresMasculinos = [];
      const nadadoresFemeninos = [];
    
      resultados.forEach((resul) => {
        resul.nadadores.forEach((deportista) => {
          if (resul.categoria.match(/\bM\b/g) && parseInt(deportista.edad) >= edadMarcaNacionalM) {   
            const recordNacional = recordsNacionalesElegidosM.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (recordNacional) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                recordNacional.record
              );
    
              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresMasculinos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: recordsNacional['masculino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][resul.prueba.prueba]?.records,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
    
          if (resul.categoria.match(/\bF\b/g) && parseInt(deportista.edad) >= edadMarcaNacionalF) {
            const recordNacional = recordsNacionalesElegidosF.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (recordNacional) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                recordNacional.record
              );
    
              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresFemeninos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: recordsNacional['femenino'][torneoEspecifico.piscina === 'ninguno' ? '25' : torneoEspecifico.piscina][resul.prueba.prueba]?.records,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
        });
      });
    
      // Ordenar los nadadores por diferencia de tiempo de menor a mayor
      nadadoresMasculinos.sort((a, b) => {
        const diferenciaA = a.diferencia; 
        const diferenciaB = b.diferencia;

        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });

      nadadoresFemeninos.sort((a, b) => {
        const diferenciaA = a.diferencia;
        const diferenciaB = b.diferencia;
            
        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });
    
      setMejorMarcaTecnicaNacionalM(nadadoresMasculinos);
      setMejorMarcaTecnicaNacionalF(nadadoresFemeninos);
    }

    function mejorMarcaMinimaInterligas() {
    
      const nadadoresMasculinos = [];
      const nadadoresFemeninos = [];

      const mmFemenino = marcasMinimasInterligas.filter((marc)=>{return marc.genero === 'Femenino'});
      const mmF = mmFemenino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaLigaF) >= 18 ? 18 : parseInt(edadMarcaMinimaLigaF))})[0]?.pruebas;

      const mmMasculino = marcasMinimasInterligas.filter((marc)=>{return marc.genero === 'Masculino'});
      const mmM = mmMasculino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaLigaM) >= 19 ? 19 : parseInt(edadMarcaMinimaLigaM))})[0]?.pruebas;
    
      resultados.forEach((resul) => {
        resul.nadadores.forEach((deportista) => {
          const condicionInterLigasM = parseInt(edadMarcaMinimaLigaM) >= 19 ? parseInt(deportista.edad) >= 19 : parseInt(deportista.edad) === parseInt(edadMarcaMinimaLigaM);
          const condicionInterLigasF = parseInt(edadMarcaMinimaLigaF) >= 18 ? parseInt(deportista.edad) >= 18 : parseInt(deportista.edad) === parseInt(edadMarcaMinimaLigaF);
          if (resul.categoria.match(/\bM\b/g) && condicionInterLigasM && mmM) {
            const marcasMinimas = marcasMinimasLigaElegidosM.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (marcasMinimas) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                marcasMinimas.marcaMinima
              );
    
              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresMasculinos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: mmM.filter((reco)=>{return reco.prueba === resul.prueba.prueba})[0].marcaMinima,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
    
          if (resul.categoria.match(/\bF\b/g) && condicionInterLigasF && mmF) {
            const marcasMinimas = marcasMinimasLigaElegidosF.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (marcasMinimas) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                marcasMinimas.marcaMinima
              );

              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresFemeninos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: mmF.filter((reco)=>{return reco.prueba === resul.prueba.prueba})[0].marcaMinima,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
        });
      });
    
      // Ordenar los nadadores por diferencia de tiempo de menor a mayor
      nadadoresMasculinos.sort((a, b) => {
        const diferenciaA = a.diferencia; 
        const diferenciaB = b.diferencia;

        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });

      nadadoresFemeninos.sort((a, b) => {
        const diferenciaA = a.diferencia;
        const diferenciaB = b.diferencia;
            
        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });
    
      setMejorMarcaMinimaLigaM(nadadoresMasculinos);
      setMejorMarcaMinimaLigaF(nadadoresFemeninos);
    }

    function mejorMarcaMinimaInterclubes() {
    
      const nadadoresMasculinos = [];
      const nadadoresFemeninos = [];

      const mmFemenino = marcasMinimasInterclubes.filter((marc)=>{return marc.genero === 'Femenino'});
      const mmF = mmFemenino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaClubesF) >= 18 ? 18 : parseInt(edadMarcaMinimaClubesF))})[0]?.pruebas;

      const mmMasculino = marcasMinimasInterclubes.filter((marc)=>{return marc.genero === 'Masculino'});
      const mmM = mmMasculino.filter((mM)=>{return mM.edad.includes(parseInt(edadMarcaMinimaClubesM) >= 19 ? 19 : parseInt(edadMarcaMinimaClubesM))})[0]?.pruebas;
    
      resultados.forEach((resul) => {
        resul.nadadores.forEach((deportista) => {
          const condicionInterClubesM = parseInt(edadMarcaMinimaClubesM) >= 19 ? parseInt(deportista.edad) >= 19 : parseInt(deportista.edad) === parseInt(edadMarcaMinimaClubesM);
          const condicionInterClubesF = parseInt(edadMarcaMinimaClubesF) >= 18 ? parseInt(deportista.edad) >= 18 : parseInt(deportista.edad) === parseInt(edadMarcaMinimaClubesF);
          if (resul.categoria.match(/\bM\b/g) && condicionInterClubesM && mmM) {
            const marcasMinimas = marcasMinimasClubesElegidosM.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (marcasMinimas) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                marcasMinimas.marcaMinima
              );
    
              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresMasculinos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: mmM.filter((reco)=>{return reco.prueba === resul.prueba.prueba})[0].marcaMinima,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
    
          if (resul.categoria.match(/\bF\b/g) && condicionInterClubesF && mmF) {
            const marcasMinimas = marcasMinimasClubesElegidosF.find(
              (reco) => reco.prueba === resul.prueba.prueba
            );
            if (marcasMinimas) {
              const tiempoDeportista = deportista.tiempo.split(':').map(Number);
              const diferenciaTiempo = calcularDiferenciaTiempo(
                tiempoDeportista,
                marcasMinimas.marcaMinima
              );

              if(deportista.tiempo !== '0:0:0' && deportista.tiempo !== '61:0:0'){
                nadadoresFemeninos.push({
                  nombre: deportista.nombre,
                  club: deportista.nombreclub,
                  prueba: resul.prueba.prueba,
                  tiempo: arreglarTiempoEstandar(deportista.tiempo),
                  mt: mmF.filter((reco)=>{return reco.prueba === resul.prueba.prueba})[0].marcaMinima,
                  diferencia: diferenciaTiempo,
                });
              }
            }
          }
        });
      });
    
      // Ordenar los nadadores por diferencia de tiempo de menor a mayor
      nadadoresMasculinos.sort((a, b) => {
        const diferenciaA = a.diferencia; 
        const diferenciaB = b.diferencia;

        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });

      nadadoresFemeninos.sort((a, b) => {
        const diferenciaA = a.diferencia;
        const diferenciaB = b.diferencia;
            
        if (diferenciaA >= 0 && diferenciaB >= 0) {
          const splitTiempoA = a.tiempo.split(':');
          const splitTiempoB = b.tiempo.split(':');
          const tiemposA = splitTiempoA[0] * 60 * 1000 + splitTiempoA[1] * 1000 + splitTiempoA[2];
          const tiemposB = splitTiempoB[0] * 60 * 1000 + splitTiempoB[1] * 1000 + splitTiempoB[2];
          return tiemposA - tiemposB;
        } else if (diferenciaA < 0 && diferenciaB < 0) {
          return Math.abs(diferenciaA) - Math.abs(diferenciaB);
        } else {
          return diferenciaA >= 0 ? -1 : 1;
        }
      });
    
      setMejorMarcaMinimaClubesM(nadadoresMasculinos);
      setMejorMarcaMinimaClubesF(nadadoresFemeninos);
    }

    mejorMarcaTecnicaMundial();
    mejorMarcaTecnicaNacional();
    mejorMarcaMinimaInterligas();
    mejorMarcaMinimaInterclubes();
    // Actualizamos la referencia con el nuevo estado del arreglo
    resultadosRef.current = resultados;

    const animationDuration = 1000; // Duración de la animación en milisegundos (aquí 0.5 segundos)
    const timer = setTimeout(() => {
      setAnimateList(false);
    }, animationDuration);
 
    return () => clearTimeout(timer);
  }, [setAnimateList, resultadosRef, resultados, edadMarcaMundialF, edadMarcaMundialM, 
    recordsMundialesElegidosF, recordsMundialesElegidosM, edadMarcaMinimaLigaF, edadMarcaMinimaLigaM,
  marcasMinimasLigaElegidosF, marcasMinimasLigaElegidosM, edadMarcaMinimaClubesF, edadMarcaMinimaClubesM,
  marcasMinimasClubesElegidosF, marcasMinimasClubesElegidosM, edadMarcaNacionalF, edadMarcaNacionalM,
  recordsNacionalesElegidosF, recordsNacionalesElegidosM])

  useEffect(() => {
    traerResultados(idTorneoParticipando, setResultados, setConexion, infoUsuario.nombreclub);
  }, [infoUsuario])

  useEffect(() => {
    if(infoUsuario.nombreclub === datosAdmin.nombre || infoUsuario.nombreclub === 'PROWEBSPORTS'){
      abrirBaseDeDatosLocal(idTorneoParticipando);
    }
  },[infoUsuario])

  useEffect(() => {
    buscarNombresAutorizados(setNombresAutorizados);
  }, [setNombresAutorizados])

  return (
    <div className='position-relative'>
      <div className="alert alert-success alert-dismissible fade show text-start mb-0" role="alert">
        <h4>¡IMPORTANTE!</h4>
        <p className="mb-0">Queridos entrenadores queremos recordarles que no es necesario recargar la plataforma constantemente, ya que los resultados se actualizan en tiempo real. Si no observan ningún cambio, es posible que aún no hayamos actualizado los tiempos. ¡Gracias por su paciencia y comprensión!</p>
        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
      </div>
      {infoUsuario.nombreclub === datosAdmin.nombre || infoUsuario.nombreclub === 'PROWEBSPORTS'?(
        <>
          {!conexion ? <>
            <button className='btn btn-secondary ms-1 mt-2 shadow' id='sininternet' onClick={() => traerResultados(idTorneoParticipando, setResultados, setConexion, infoUsuario.nombreclub)}>{'Actualizar Resultados'}</button>
          </>
          :
          <></>
          }
        </>
      ):(
        <></>
      )}
      <TabView scrollable>
        <TabPanel header="Puestos" leftIcon='pi pi-users me-2'>
          <div className="d-flex flex-wrap justify-content-center align-items-center bg-light pt-2 pb-2">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                  type="number"
                  onChange={(e) => setGlobalFilter(e.target.value)}
                  placeholder="Numero de prueba"
                />
            </span>
            <span className="p-input-icon-left">
                <i className="pi pi-list" />
                <InputText
                  type="number"
                  onChange={(e) => setCantPuestos(e.target.value)}
                  placeholder="Cantidad Puestos"
                />
            </span>
          </div>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: '10px'}}>
            {resultados.length !== 0 ?(
              resultados.filter((resu)=>((resu.id + 1) + '').includes(globalFilter)).map((result, indexResult)=>{
                return <form key={indexResult} className='formulario-tablas table-responsive-sm'>
                <motion.table style={{margin: '4px', width: '530px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='shadow tabla-tiempos'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <td style={{fontSize: '16px', fontFamily: 'Fira Sans Extra Condensed'}} colSpan={7}>
                        {resultados.length - 1 === indexResult ?(
                          <span className='d-flex justify-content-around aling-items-center'>
                            {result.nadadores.length === 1 ? 'No Acumula Para El Trofeo - ' : ''} En Curso...
                            <i className='d-flex align-items-center pi pi-external-link btn btn-primary' data-bs-toggle="modal" data-bs-target="#staticBackdrop1"></i>
                          </span>
                        ):(
                          <span>
                            {result.nadadores.length === 1 ? 'No Acumula Para El Trofeo - ' : ''} Prueba Terminada ✔
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th className='id fs-4'>{result.id + 1}</th>
                      <th style={{fontSize: '18px', borderLeft: '1px solid gray'}} colSpan={6}>{result.categoria}</th>
                    </tr>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th>{result.categoria.match(new RegExp(result.categoriaEdad, 'g')) && result.categoriaEdad?.split('-').length >= 2 ? <Button type='button' icon='pi pi-arrow-right-arrow-left' text severity='danger' data-bs-toggle="modal" data-bs-target="#staticBackdrop2" onClick={() => separarEdades(result)} style={{borderRadius: '10px', padding: '2px 0'}}/> : ''}</th>
                      <th style={{fontSize: '18px', borderLeft: '1px solid gray', borderRight: '1px solid gray'}} colSpan={5}>{result.prueba.prueba}</th>
                      <th><Button type='button' icon='pi pi-file-pdf' text severity='danger' onClick={() => pdfPruebaIndividual(result, torneoEspecifico, setPdfUrl)} style={{borderRadius: '10px', padding: '2px 0'}}/></th>
                    </tr>
                    <tr>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{fontSize: '15px'}}></th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>T. Insc.</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Tiempo</th>
                      <th style={{fontSize: '15px', borderRight: '1px solid gray'}}>Rendim.</th>
                      <th style={{fontSize: '15px'}}>Equipo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result.nadadores.slice(0, cantPuestos === '' ? result.nadadores.length : cantPuestos).map((nadador, indexNadador)=>{
                      return <motion.tr key={indexNadador}
                      initial={{ x: -1000, opacity: 0}}
                      animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                      style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexNadador}`}}
                      transition={{ duration: 1, delay: `0.${indexNadador}`, type: 'spring'}}
                      >
                        {indexNadador === 0 ? <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : indexNadador === 1 ? <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  indexNadador === 2 ? <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}><img style={{width: '35px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '20px 0 0 0'}}>{indexNadador + 1}</td>}
                        {nadador.equipo === null ?(
                          <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            <Button type='button' severity='success' icon='pi pi-user-plus' className='btn' style={{borderRadius: '50%', width: '2rem', height: '2rem', padding: 0}} onClick={() => sacarHistorialDeportista(nadador)}/>
                          </td>
                        ):(
                          <td style={{background: 'white'}}></td>
                        )}
                        {nadador.equipo !== null ?(
                          <td className={`text-start ${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>
                            {nadador.equipo.map((eq, indexEq)=>{
                              return <span key={indexEq}>{eq} <hr className='m-0'/></span>
                            })}
                          </td>
                          ):(
                          <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.nombre}</td>
                        )}
                        <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.record && nadador.record !== '0:0:0' ? arreglarTiempoEstandar(nadador.record) : nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                        <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{manejoRendimiento(nadador.record, nadador.tiempo) >= 0 && nadador.tiempo !== '61:0:0' && nadador.tiempo !== '0:0:0'? 
                          <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo))}</label>
                          : 
                          <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(nadador.record, nadador.tiempo === '61:0:0' ? '0:0:0' : nadador.tiempo))}</label>
                        }</td>
                        <td className={`${infoUsuario.nombreclub === nadador.nombreclub ? 'bg-info text-light' : ''}`} style={{fontSize: '13px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{nadador.nombreclub}</td>
                      </motion.tr>
                    })}
                  </tbody>
                </motion.table>
              </form> 
              }) 
            ):(
              <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{duration: 1}}
              className='w-75 m-auto bg-light'
              style={{borderRadius: '10px'}}
              >
                <div className='p-5'>
                  <img className='w-25' src={require('../img/img-logo-swimmingt.webp')} alt="logo prowebsports" />
                  <h1>¡Bienvenido!</h1>
                  <p>No tenemos registros de tiempos aún.</p>
                </div>
              </motion.div>
            )}
          </div>
        </TabPanel>
        <TabPanel header="Ms. Mundiales" leftIcon='pi pi-globe me-2'>
          <TabView>
            <TabPanel header="Femenino" leftIcon='pi pi-user me-2'>
              <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-user" />
                    <InputText
                      type="number"
                      onChange={(e) => setEdadMarcaMundialF(e.target.value === '' ? 0 : e.target.value)}
                      defaultValue={edadMarcaMundialF}
                      placeholder="Edad Minima Femenino"
                    />
                </span>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Mundiales Femenino">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recF, indexRecF)=>{
                      return <div key={indexRecF} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recF} name="pizza" value={recF} onChange={sacarMarcasMundialesFElegidas} checked={opcionesElegidosF.includes(recF)}/>
                      <label htmlFor={recF} className="ml-2">{recF}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCA TECNICA MUNDIAL FEMENINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>Record</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                  {mejorMarcaTecnicaF.length !== 0 ?(
                      mejorMarcaTecnicaF.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='pt-2 pb-0 mb-0' style={{fontSize: '10px'}}>Información extraída de Wikipedia <b>(https://es.wikipedia.org/wiki/Anexo:Plusmarcas_mundiales_de_nataci%C3%B3n)</b>.</p>
              <p className='pb-2' style={{fontSize: '10px'}}>Información extraída de Olympics <b>(https://olympics.com/es/noticias/natacion-lista-records-mundiales-pruebas-olimpicas)</b>.</p>
            </TabPanel>
            <TabPanel header="Masculino" leftIcon='pi pi-user me-2'>
              <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                <span className="p-input-icon-left">
                  <i className="pi pi-user" />
                  <InputText
                    type="number"
                    onChange={(e) => setEdadMarcaMundialM(e.target.value === '' ? 0 : e.target.value)}
                    defaultValue={edadMarcaMundialM}
                    placeholder="Edad Minima Masculino"
                  />
                </span>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Mundiales Masculino">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recM, indexRecM)=>{
                      return <div key={indexRecM} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recM} name="pizza" value={recM} onChange={sacarMarcasMundialesMElegidas} checked={opcionesElegidosM.includes(recM)}/>
                      <label htmlFor={recM} className="ml-2">{recM}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCA TECNICA MUNDIAL MASCULINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>Record</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mejorMarcaTecnicaM.length !== 0 ?(
                      mejorMarcaTecnicaM.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='pt-2 pb-0 mb-0' style={{fontSize: '10px'}}>Información extraída de Wikipedia <b>(https://es.wikipedia.org/wiki/Anexo:Plusmarcas_mundiales_de_nataci%C3%B3n)</b>.</p>
              <p className='pb-2' style={{fontSize: '10px'}}>Información extraída de Olympics <b>(https://olympics.com/es/noticias/natacion-lista-records-mundiales-pruebas-olimpicas)</b>.</p>
            </TabPanel>
          </TabView>
        </TabPanel>
        <TabPanel header="Ms. Nacionales" leftIcon='pi pi-globe me-2'>
          <TabView>
            <TabPanel header="Femenino" leftIcon='pi pi-user me-2'>
              <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                <span className="p-input-icon-left">
                    <i className="pi pi-user" />
                    <InputText
                      type="number"
                      onChange={(e) => setEdadMarcaNacionalF(e.target.value === '' ? 0 : e.target.value)}
                      defaultValue={edadMarcaNacionalF}
                      placeholder="Edad Minima Femenino"
                    />
                </span>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Nacionales Femenino">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recF, indexRecF)=>{
                      return <div key={indexRecF} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recF} name="pizza" value={recF} onChange={sacarMarcasNacionalesFElegidas} checked={opcionesNacionalesElegidosF.includes(recF)}/>
                      <label htmlFor={recF} className="ml-2">{recF}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCA TECNICA NACIONAL FEMENINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>Record</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                  {mejorMarcaTecnicaNacionalF.length !== 0 ?(
                      mejorMarcaTecnicaNacionalF.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
            </TabPanel>
            <TabPanel header="Masculino" leftIcon='pi pi-user me-2'>
              <div className="d-flex justify-content-center align-items-center mt-2 mb-2">
                <span className="p-input-icon-left">
                  <i className="pi pi-user" />
                  <InputText
                    type="number"
                    onChange={(e) => setEdadMarcaNacionalM(e.target.value === '' ? 0 : e.target.value)}
                    defaultValue={edadMarcaNacionalM}
                    placeholder="Edad Minima Masculino"
                  />
                </span>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Nacionales Masculino">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recM, indexRecM)=>{
                      return <div key={indexRecM} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recM} name="pizza" value={recM} onChange={sacarMarcasNacionalesMElegidas} checked={opcionesNacionalesElegidosM.includes(recM)}/>
                      <label htmlFor={recM} className="ml-2">{recM}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCA TECNICA NACIONAL MASCULINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>Record</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mejorMarcaTecnicaNacionalM.length !== 0 ?(
                      mejorMarcaTecnicaNacionalM.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
            </TabPanel>
          </TabView>
        </TabPanel>
        <TabPanel header="Ms. Mini. Interligas" leftIcon='pi pi-stopwatch me-2'>
          <TabView>
            <TabPanel header="Femenino" leftIcon='pi pi-user me-2'>
              <div className="form-floating mt-2 mb-2 m-auto" style={{maxWidth: '850px'}}>
                <select className='form-select'
                onChange={(e) => setEdadMarcaMinimaLigaF(e.target.value)}
                >
                  <option value={14}>14 Años</option>
                  <option value={15}>15 Años</option>
                  <option value={16}>16 Años</option>
                  <option value={17}>17 Años</option>
                  <option value={18}>18 Años Y Mas</option>
                </select>
                <label htmlFor="edades">Categorias Femenino</label>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Minimas Interligas">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recF, indexRecF)=>{
                      return <div key={indexRecF} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recF} name="pizza" value={recF} onChange={sacarMarcasMinimasLigaFElegidas} checked={opcionesMarcasMinimasLigaElegidosF.includes(recF)}/>
                      <label htmlFor={recF} className="ml-2">{recF}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCAS MINIMAS INTERLIGAS FEMENINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>M. Minima</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                  {mejorMarcaMinimaLigaF.length !== 0 ?(
                      mejorMarcaMinimaLigaF.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
            </TabPanel>
            <TabPanel header="Masculino" leftIcon='pi pi-user me-2'>
              <div className="form-floating mt-2 mb-2 m-auto" style={{maxWidth: '850px'}}>
                <select className='form-select'
                onChange={(e) => setEdadMarcaMinimaLigaM(e.target.value)}
                >
                  <option value={15}>15 Años</option>
                  <option value={16}>16 Años</option>
                  <option value={17}>17 Años</option>
                  <option value={18}>18 Años</option>
                  <option value={19}>19 Años Y Mas</option>
                </select>
                <label htmlFor="edades">Categorias Masculino</label>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Miminas Masculino">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recM, indexRecM)=>{
                      return <div key={indexRecM} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recM} name="pizza" value={recM} onChange={sacarMarcasMinimasLigaMElegidas} checked={opcionesMarcasMinimasLigaElegidosM.includes(recM)}/>
                      <label htmlFor={recM} className="ml-2">{recM}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCAS MINIMAS INTERLIGAS MASCULINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>M. Minima</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mejorMarcaMinimaLigaM.length !== 0 ?(
                      mejorMarcaMinimaLigaM.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
            </TabPanel>
          </TabView>
        </TabPanel>
        <TabPanel header="Ms. Mini. Interclubes" leftIcon='pi pi-stopwatch me-2'>
          <TabView>
            <TabPanel header="Femenino" leftIcon='pi pi-user me-2'>
              <div className="form-floating mt-2 mb-2 m-auto" style={{maxWidth: '850px'}}>
                <select className='form-select'
                onChange={(e) => setEdadMarcaMinimaClubesF(e.target.value)}
                >
                  <option value={14}>14 Años</option>
                  <option value={15}>15 Años</option>
                  <option value={16}>16 Años</option>
                  <option value={17}>17 Años</option>
                  <option value={18}>18 Años Y Mas</option>
                </select>
                <label htmlFor="edades">Categorias Femenino</label>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Minimas Interclubes">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recF, indexRecF)=>{
                      return <div key={indexRecF} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recF} name="pizza" value={recF} onChange={sacarMarcasMinimasClubesFElegidas} checked={opcionesMarcasMinimasClubesElegidosF.includes(recF)}/>
                      <label htmlFor={recF} className="ml-2">{recF}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCAS MINIMAS INTERCLUBES FEMENINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>M. Minima</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                  {mejorMarcaMinimaClubesF.length !== 0 ?(
                      mejorMarcaMinimaClubesF.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
            </TabPanel>
            <TabPanel header="Masculino" leftIcon='pi pi-user me-2'>
              <div className="form-floating mt-2 mb-2 m-auto" style={{maxWidth: '850px'}}>
                <select className='form-select'
                onChange={(e) => setEdadMarcaMinimaClubesM(e.target.value)}
                >
                  <option value={15}>15 Años</option>
                  <option value={16}>16 Años</option>
                  <option value={17}>17 Años</option>
                  <option value={18}>18 Años</option>
                  <option value={19}>19 Años Y Mas</option>
                </select>
                <label htmlFor="edades">Categorias Masculino</label>
              </div>
              <Accordion className='m-auto' activeIndex={0} style={{maxWidth: '850px'}}>
                <AccordionTab header="Marcas Miminas Masculino">
                  <div className="d-flex flex-wrap justify-content-evenly">
                    {pruebasRecords().map((recM, indexRecM)=>{
                      return <div key={indexRecM} className="d-flex align-items-center" style={{width: '180px'}}>
                      <Checkbox inputId={recM} name="pizza" value={recM} onChange={sacarMarcasMinimasClubesMElegidas} checked={opcionesMarcasMinimasClubesElegidosM.includes(recM)}/>
                      <label htmlFor={recM} className="ml-2">{recM}</label>
                    </div>
                    })}
                  </div>
                </AccordionTab>
              </Accordion>
              <form className='formulario-tablas table-responsive-sm m-auto' style={{maxWidth: '850px'}}>
                <motion.table style={{margin: '4px', borderRadius: '20px 0', background: 'rgb(50, 51, 51)'}}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{duration: 1}}
                className='w-100 shadow'
                >
                  <thead className='text-light'>
                    <tr style={{borderBottom: '1px solid gray'}}>
                      <th colSpan={7}>MARCAS MINIMAS INTERCLUBES MASCULINO</th>
                    </tr>
                    <tr>
                      <th style={{borderRight: '1px solid gray'}}>Pto</th>
                      <th style={{borderRight: '1px solid gray'}}>Deportista</th>
                      <th style={{borderRight: '1px solid gray'}}>Club</th>
                      <th style={{borderRight: '1px solid gray'}}>Prueba</th>
                      <th style={{borderRight: '1px solid gray'}}>M. Minima</th>
                      <th style={{borderRight: '1px solid gray'}}>Tiempo</th>
                      <th>Diferencia</th>
                    </tr>
                  </thead>
                  <tbody>
                    {mejorMarcaMinimaClubesM.length !== 0 ?(
                      mejorMarcaMinimaClubesM.map((marca, indexMarca)=>{
                        return <motion.tr key={indexMarca}
                        initial={{ x: -1000, opacity: 0}} 
                        animate={{ x: animateList ? -1000 : 0.1, opacity: animateList ? 0 : 1}}
                        style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexMarca}`}}
                        transition={{ duration: 0.5, delay: `0.${indexMarca}`, type: 'spring'}}
                        >
                          <td className={`pt-2 pb-2 ${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{indexMarca + 1}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.nombre}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.club}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.prueba}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}><FcApproval />{arreglarTiempoEstandar(marca.mt)}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>{marca.tiempo}</td>
                          <td className={`${infoUsuario.nombreclub === marca.club ? 'bg-info text-light' : ''}`} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{marca.diferencia >= 0? 
                            <label><FcApproval />{arreglarFormatoTiempo(marca.diferencia)}</label>
                            : 
                            <label><FcHighPriority />{arreglarFormatoTiempo(marca.diferencia)}</label>
                          }</td>
                        </motion.tr>
                      })
                    ):(
                      <tr>
                        <th colSpan={7} style={{fontSize: '14px', fontFamily: 'Poppins', background: 'white'}}>No hay deportistas aún.</th>
                      </tr>
                    )}
                  </tbody>
                </motion.table>
              </form>
              <p className='p-2' style={{fontSize: '10px'}}>Información extraída de la Federación Colombiana de Natación <b>(https://fecna.com.co/)</b>.</p>
            </TabPanel>
          </TabView>
        </TabPanel>
      </TabView>
      <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
      <SpeedDial className='position-fixed speeddial-bottom-right planilla' model={items} direction="up" transitionDelay={80} showIcon="pi pi-bars" hideIcon="pi pi-times" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      <Dialog visible={pdfUrl ? true : false} maximizable style={{ width: '50em' }} breakpoints={{ '641px': '100%' }} modal className="p-fluid" onHide={() => setPdfUrl()}>
        <div className="field">
          <div className='mb-2 pdf-min pdf-max' style={{width: '100%'}}>
            <embed src={pdfUrl} width='100%' height='100%' type='application/pdf'/>
          </div>
        </div>
      </Dialog>
      <Dialog visible={dialogHistorial} style={{ width: '50em' }} breakpoints={{ '700px': '100%' }} header="Historial Deportivo" modal className="p-fluid" onHide={() => setDialogHistorial(false)}>
        <div className="field">
          {historialDeportista ?(
            <div>
              <h1 className='text-center mb-0' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                {historialDeportista?.equipo === null ? (
                  historialDeportista?.nombre
                ):(
                  historialDeportista?.equipo.map((eq, indexEq)=>{
                    return (historialDeportista?.equipo.length - 1) !== indexEq ? eq + ' - ' : eq;
                  })
                )}
              </h1>
              <h5 className='text-center bg-light' style={{fontFamily: 'Poppins'}}>
                {historialDeportista?.nombreclub}
              </h5>
              <div className='mt-4 mb-4 p-3 d-flex flex-wrap justify-content-around align-items-center' style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                <p className='m-0'><b>Edad:</b> {historialDeportista?.edad}</p>
              </div>
              {historialDeportista?.pruebas.map((prue, indexPrue)=>{
                return <table key={indexPrue} className='w-100 table table-bordered table-striped text-center'>
                <thead>
                  <tr style={{fontFamily: 'Poppins', background: 'rgb(212,212,212)'}}>
                    <th className='fs-6'>{prue.nPrueba}</th>
                    <th className='fs-6' colSpan={4}>{prue.prueba}</th>
                  </tr>
                  <tr>
                    <th>Posición</th>
                    <th>T. Insc.</th>
                    <th>Tiempo</th>
                    <th>Rendim.</th>
                    <th>Puntos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  {prue.puesto === 1 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : prue.puesto === 2 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  prue.puesto === 3 ? <td style={{fontFamily: 'Poppins'}}><img style={{width: '25px'}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className={`pt-2 pb-2`} style={{fontFamily: 'Poppins'}}>{prue.puesto}</td>}
                    <td>{arreglarTiempoEstandar(prue.record)}</td>
                    <td>{prue.tiempo !== '61:0:0' ? prue.tiempo !== '0:0:0' ? arreglarTiempoEstandar(prue.tiempo) : 'NSP' : 'DSC' }</td>
                    <td>{manejoRendimiento(prue.record, prue.tiempo) >= 0 && prue.tiempo !== '61:0:0' && prue.tiempo !== '0:0:0'? 
                      <label className='d-flex align-items-center justify-content-center'><FcApproval />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo))}</label>
                      : 
                      <label className='d-flex align-items-center justify-content-center'><FcHighPriority />{arreglarFormatoTiempo(manejoRendimiento(prue.record, prue.tiempo === '61:0:0' ? '0:0:0' : prue.tiempo))}</label>
                    }</td>
                    <td>{prue.tiempo !== '61:0:0' ? prue.tiempo !== '0:0:0' ? prue.puntos : '0' : '0' }</td>
                  </tr>
                </tbody>
              </table>
              })}
            </div>
          ):(
            <></>
          )}
        </div>
      </Dialog>
      <div className="modal fade" id="staticBackdrop1" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content" style={{borderRadius: '20px 0', background: 'linear-gradient(80deg, rgb(217, 255, 3), rgb(0, 119, 255), rgb(255, 0, 0))'}}>
            <div className="modal-body p-0 position-relative">
              <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close" style={{top: '18px', paddingLeft: '12px'}}></button>
              <table className='w-100' style={{height: '180px'}}>
                <thead>
                  <tr style={{color: 'white', textShadow: '-2px 0 3px black'}}>
                    <th colSpan={2}><img className='ps-2' src={require('../img/img_logo.png')} alt="logo pws" style={{width: '130px'}}/></th>
                    <th style={{fontSize: '38px', textAlign: 'center'}} colSpan={2}>
                      {`${resultados[resultados.length - 1] ? resultados[resultados.length - 1].categoria : ''} / ${resultados[resultados.length - 1] ? resultados[resultados.length - 1].prueba.prueba: ''}`}
                      <div className="marquee text-white">
                        <span style={{fontFamily: 'Poppins', fontSize: '20px', letterSpacing: '2px'}}>{`¡BIENVENIDOS! AL GRAN EVENTO ${torneoEspecifico.titulo} - ${datosAdmin.nombre}`}</span>
                      </div>
                    </th>
                    <th><img className='pe-2' src={require('../img/pwsmascota-derecha.png')} alt="logo pws" style={{width: '150px', filter: "drop-shadow(-1px 1px 2px black)"}}/></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    resultados[resultados.length - 1] ?(
                    resultados[resultados.length - 1].nadadores.slice(0, parseInt(torneoEspecifico.carril)).map((nadador, indexNadador)=>{
                    return <motion.tr key={indexNadador}
                    initial={{ x: -3000, opacity: 0}}
                    animate={{ x: animateList ? -3000 : 0.1, opacity: animateList ? 0 : 1}}
                    style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexNadador}`}}
                    transition={{ duration: 0.5, delay: `0.${indexNadador}`}}
                    >
                      {indexNadador === 0 ? <td className='submit' style={{width: '65px', borderRadius: '20px 0 0 0'}}><img style={{width: '55px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-1.png")} alt="medalla primer lugar" /></td> : indexNadador === 1 ? <td className='submit' style={{borderRadius: '20px 0 0 0'}}><img style={{width: '55px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-2.png")} alt="medalla segundo lugar" /></td> :  indexNadador === 2 ? <td className='submit' style={{borderRadius: '20px 0 0 0'}}><img style={{width: '55px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-medalla-3.png")} alt="medalla tercer lugar" /></td> : <td className='submit text-warning' style={{fontSize: '30px', borderRadius: '20px 0 0 0'}}>{indexNadador + 1}</td>}
                      <td className='ps-2 submit' style={{width: '150px', fontSize: '30px', fontFamily: 'Poppins', textAlign: 'start'}}>{nadador.club}</td>
                      <td className='ps-1 pe-1 submit' style={{width: '50px', textAlign: 'start'}}>{banderas(nadador.nombreclub)}</td>
                      <td className='ps-2 submit' style={{fontSize: '30px', fontFamily: 'Poppins', textAlign: 'start'}}>{nadador.nombre}</td>
                      <td className='ps-2 submit' style={{fontSize: '29px', fontFamily: 'Fira Sans Extra Condensed', borderRadius: '0 0 20px 0', letterSpacing: '2px'}}>{nadador.tiempo === '0:0:0' ? 'NSP' : nadador.tiempo === '61:0:0' ? 'DSC' : `${arreglarTiempoEstandar(nadador.tiempo)}`}</td>
                    </motion.tr>
                  })):(
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='w-100 submit d-flex justify-content-between' style={{fontSize: '18px', fontFamily: 'Fira Sans Extra Condensed', borderRadius: '20px 0 20px 0', letterSpacing: '2px'}}>
                <p className='m-0 p-1 ps-3'>PROWEBSPORTS</p>
                <p className='m-0 p-1 pe-3'>www.prowebsports.com.co</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="staticBackdrop2" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body d-flex flex-wrap">
              {deportistasSeparados ?(
                <>
                {tablaEdadesSeparadas()}
                </>
              ):(
                <table style={{maxWidth: '530px'}} className='table table-striped table-hover table-bordered table-light shadow m-auto'>
                  <thead>
                    <tr>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>No tenemos resultados aún.</td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResultadosTiempos;