import axios from 'axios';

const weatherConditions = [
  { icon: 1, name: "Sunny", translation: "Soleado", day: true, night: false },
  { icon: 2, name: "Mostly Sunny", translation: "Mayormente Soleado", day: true, night: false },
  { icon: 3, name: "Partly Sunny", translation: "Parcialmente Soleado", day: true, night: false },
  { icon: 4, name: "Intermittent Clouds", translation: "Nubes Intermitentes", day: true, night: false },
  { icon: 5, name: "Hazy Sunshine", translation: "Sol Brumoso", day: true, night: false },
  { icon: 6, name: "Mostly Cloudy", translation: "Mayormente Nublado", day: true, night: false },
  { icon: 7, name: "Cloudy", translation: "Nublado", day: true, night: true },
  { icon: 8, name: "Dreary", translation: "Gris (Nublado)", day: true, night: true },
  { icon: 11, name: "Fog", translation: "Niebla", day: true, night: true },
  { icon: 12, name: "Showers", translation: "Lluvias", day: true, night: true },
  { icon: 13, name: "Mostly Cloudy w/ Showers", translation: "Mayormente Nublado con Lluvias", day: true, night: false },
  { icon: 14, name: "Partly Sunny w/ Showers", translation: "Parcialmente Soleado con Lluvias", day: true, night: false },
  { icon: 15, name: "T-Storms", translation: "Tormentas", day: true, night: true },
  { icon: 16, name: "Mostly Cloudy w/ T-Storms", translation: "Mayormente Nublado con Tormentas", day: true, night: false },
  { icon: 17, name: "Partly Sunny w/ T-Storms", translation: "Parcialmente Soleado con Tormentas", day: true, night: false },
  { icon: 18, name: "Rain", translation: "Lluvia", day: true, night: true },
  { icon: 19, name: "Flurries", translation: "Nieve Ligera", day: true, night: true },
  { icon: 20, name: "Mostly Cloudy w/ Flurries", translation: "Mayormente Nublado con Nieve Ligera", day: true, night: false },
  { icon: 21, name: "Partly Sunny w/ Flurries", translation: "Parcialmente Soleado con Nieve Ligera", day: true, night: false },
  { icon: 22, name: "Snow", translation: "Nieve", day: true, night: true },
  { icon: 23, name: "Mostly Cloudy w/ Snow", translation: "Mayormente Nublado con Nieve", day: true, night: false },
  { icon: 24, name: "Ice", translation: "Hielo", day: true, night: true },
  { icon: 25, name: "Sleet", translation: "Aguanieve", day: true, night: true },
  { icon: 26, name: "Freezing Rain", translation: "Lluvia Helada", day: true, night: true },
  { icon: 29, name: "Rain and Snow", translation: "Lluvia y Nieve", day: true, night: true },
  { icon: 30, name: "Hot", translation: "Caluroso", day: true, night: true },
  { icon: 31, name: "Cold", translation: "Frío", day: true, night: true },
  { icon: 32, name: "Windy", translation: "Ventoso", day: true, night: true },
  { icon: 33, name: "Clear", translation: "Despejado", day: false, night: true },
  { icon: 34, name: "Mostly Clear", translation: "Mayormente Despejado", day: false, night: true },
  { icon: 35, name: "Partly Cloudy", translation: "Parcialmente Nublado", day: false, night: true },
  { icon: 36, name: "Intermittent Clouds", translation: "Nubes Intermitentes", day: false, night: true },
  { icon: 37, name: "Hazy Moonlight", translation: "Luz de Luna Brumosa", day: false, night: true },
  { icon: 38, name: "Mostly Cloudy", translation: "Mayormente Nublado", day: false, night: true },
  { icon: 39, name: "Partly Cloudy w/ Showers", translation: "Parcialmente Nublado con Lluvias", day: false, night: true },
  { icon: 40, name: "Mostly Cloudy w/ Showers", translation: "Mayormente Nublado con Lluvias", day: false, night: true },
  { icon: 41, name: "Partly Cloudy w/ T-Storms", translation: "Parcialmente Nublado con Tormentas", day: false, night: true },
  { icon: 42, name: "Mostly Cloudy w/ T-Storms", translation: "Mayormente Nublado con Tormentas", day: false, night: true },
  { icon: 43, name: "Mostly Cloudy w/ Flurries", translation: "Mayormente Nublado con Nieve Ligera", day: false, night: true },
  { icon: 44, name: "Mostly Cloudy w/ Snow", translation: "Mayormente Nublado con Nieve", day: false, night: true }
];

const findClosestWeatherCondition = (description) => {
  // Identificar si la descripción incluye "noche" o "día"
  const isNight = description.toLowerCase().includes("night");
  const isDay = description.toLowerCase().includes("day");
  
  // Filtrar las condiciones para que coincidan con las horas del día o de la noche
  const filteredConditions = weatherConditions.filter(condition => {
    if (isNight) {
      return condition.night === true; // Solo condiciones de noche
    } else if (isDay) {
      return condition.day === true; // Solo condiciones de día
    }
    return condition.day === true || condition.night === true; // Por defecto, cualquier condición
  });

  // Buscar la condición más cercana en las condiciones filtradas
  const closestCondition = filteredConditions.reduce((closest, current) => {
    const keywords = description.toLowerCase().split('-');
    const isMatch = keywords.some(keyword => current.name.toLowerCase().includes(keyword));
    
    if (isMatch && (!closest || current.name.length < closest.name.length)) {
      return current; // Actualiza la condición más cercana
    }
    return closest; // Retorna la condición más cercana encontrada
  }, null);

  return closestCondition; // Retorna la condición más cercana encontrada
};

export const loadGoogleMapsAPI = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('googleMaps');

    if (existingScript) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.id = 'googleMaps';
    script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAYNSywNBKShlspq0X4ITBHmVkm8a0GVZA&libraries=places`;
    script.defer = true;
    script.async = true;
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error('No se pudo cargar la API de Google Maps.'));
    };

    document.body.appendChild(script);
  });
};

export const buscarLatitudYLongitud = (direccion) => {
  try {
    const geocoder = new window.google.maps.Geocoder();
    
    // Devolver una promesa desde la geocodificación
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address: direccion }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const { location } = results[0].geometry;
            const nuevaUbicacion = `${location.lat()}, ${location.lng()}`;
            
            // Geocodificación inversa para obtener el nombre de la ciudad
            const latlng = new window.google.maps.LatLng(location.lat(), location.lng());
            geocoder.geocode({ location: latlng }, (results, status) => {
              if (status === 'OK') {
                if (results[0]) {
                  const ciudad = results[0].address_components.find(component => 
                    component.types.includes('locality') || component.types.includes('administrative_area_level_1')
                  )?.long_name || "Ciudad no encontrada";
                  
                  resolve({ ubicacion: nuevaUbicacion, ciudad });
                } else {
                  reject('No se encontraron resultados para la geocodificación inversa.');
                }
              } else {
                reject(`Error en la geocodificación inversa: ${status}`);
              }
            });
          } else {
            reject('No se encontraron resultados para esa dirección.');
          }
        } else {
          reject(`Error: ${status}`);
        }
      });
    });
  } catch (error) {
    console.error(error);
  }
}

let contadorClima = 0;
//Funcion clima actual Accu Weather
export const getAccuWeatherLocationKey = async (torneos, lL, setClimaHoy, setClimasPredicciones, climasHoy, climasPredicciones, clima) => {
  const primaryApiKey = "e15uYZxgprX7vUaRycgGRWgda8ntInYj";
  const secondaryApiKey = "JbTgvo07ctpGUjz3DsRLGATdDabmxD4B";
  const url = `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?q=${lL}`;
  if(contadorClima === 0){
  try {
      const response = await axios.get(`${url}&apikey=${primaryApiKey}`);
      getClimaActual(response.data.Key, torneos, setClimaHoy, setClimasPredicciones, climasHoy, climasPredicciones, clima);
    } catch (error) {
      // Intento con el apiKey secundario
      try {
        const response = await axios.get(`${url}&apikey=${secondaryApiKey}`);
        getClimaActual(response.data.Key, torneos, setClimaHoy, setClimasPredicciones, climasHoy, climasPredicciones, clima);
      } catch (secondaryError) {
        const auxClimasHoy = [];
        const auxClimasDias = [];
        clima.forEach((cli)=>{
          acomodarClimaHoy(cli, auxClimasHoy, torneos);
          acomodarClimaDias(cli, auxClimasDias, torneos);
        })
        
        setClimaHoy(auxClimasHoy);
        setClimasPredicciones(auxClimasDias);
      }
    }
    contadorClima++;
  }
};

export const getClimaActual = async (locationKey, torneos, setClimaHoy, setClimasPredicciones, climasHoy, climasPredicciones, clima) => {
  const primaryApiKey = "e15uYZxgprX7vUaRycgGRWgda8ntInYj";
  const secondaryApiKey = "JbTgvo07ctpGUjz3DsRLGATdDabmxD4B";
  const urlHoy = `https://dataservice.accuweather.com/currentconditions/v1/${locationKey}`;
  const urlDias = `https://dataservice.accuweather.com/forecasts/v1/daily/5day/${locationKey}`;

  try {
    const auxClimaHoy = [...climasHoy];
    const auxClimaDias = [...climasPredicciones];
    
    for (const [indexTor, tor] of torneos.entries()) {
      try {
        const responseHoy = await axios.get(`${urlHoy}?apikey=${primaryApiKey}&language=es&metric=true`);
        const responseDias = await axios.get(`${urlDias}?apikey=${primaryApiKey}&language=es&metric=true`);
        
        const climaActual = responseHoy.data[0];
        const climaDias = responseDias.data;

        const iconoClima = climaActual.WeatherIcon;
        climaActual.iconoUrl = `https://developer.accuweather.com/sites/default/files/${
          iconoClima < 10 ? '0' + iconoClima : iconoClima
        }-s.png`;
        climaActual.ciudad = tor.ciudad;
        auxClimaHoy[indexTor] = climaActual;

        climaDias.DailyForecasts.forEach(cliDias => {
          if (cliDias.Date === tor.fechaTorneo + 'T07:00:00-05:00') {
            const isDay = climaActual.IsDayTime;
            const dayOrNight = isDay ? cliDias.Day : cliDias.Night;
            const iconoUrl = `https://developer.accuweather.com/sites/default/files/${
              dayOrNight.Icon < 10 ? '0' + dayOrNight.Icon : dayOrNight.Icon
            }-s.png`;
            dayOrNight.iconoUrl = iconoUrl;
            dayOrNight.tempmax = cliDias.Temperature.Maximum.Value;
            dayOrNight.tempmin = cliDias.Temperature.Minimum.Value;
            dayOrNight.WeatherText = dayOrNight.IconPhrase;
            dayOrNight.ciudad = tor.ciudad;
            auxClimaDias[indexTor] = dayOrNight;
          }
        });
      } catch (error) {
        console.error("Error con apiKey principal, intentando con el secundario:", error.message);
        try {
          const responseHoy = await axios.get(`${urlHoy}?apikey=${secondaryApiKey}&language=es&metric=true`);
          const responseDias = await axios.get(`${urlDias}?apikey=${secondaryApiKey}&language=es&metric=true`);
          
          // Repetimos el mismo proceso con el apiKey secundario.
          const climaActual = responseHoy.data[0];
          const climaDias = responseDias.data;

          const iconoClima = climaActual.WeatherIcon;
          climaActual.iconoUrl = `https://developer.accuweather.com/sites/default/files/${
            iconoClima < 10 ? '0' + iconoClima : iconoClima
          }-s.png`;
          climaActual.ciudad = tor.ciudad;
          auxClimaHoy[indexTor] = climaActual;

          climaDias.DailyForecasts.forEach(cliDias => {
            if (cliDias.Date === tor.fechaTorneo + 'T07:00:00-05:00') {
              const isDay = climaActual.IsDayTime;
              const dayOrNight = isDay ? cliDias.Day : cliDias.Night;
              const iconoUrl = `https://developer.accuweather.com/sites/default/files/${
                dayOrNight.Icon < 10 ? '0' + dayOrNight.Icon : dayOrNight.Icon
              }-s.png`;
              dayOrNight.iconoUrl = iconoUrl;
              dayOrNight.tempmax = cliDias.Temperature.Maximum.Value;
              dayOrNight.tempmin = cliDias.Temperature.Minimum.Value;
              dayOrNight.WeatherText = dayOrNight.IconPhrase;
              dayOrNight.ciudad = tor.ciudad;
              auxClimaDias[indexTor] = dayOrNight;
            }
          });
        } catch (secondaryError) {
          console.error("Error con apiKey secundario:", secondaryError.message);
        }
      }
    }
    setClimaHoy(auxClimaHoy);
    setClimasPredicciones(auxClimaDias);
  } catch (generalError) {
    const auxClimasHoy = [];
    const auxClimasDias = [];
    clima.forEach((cli)=>{
      acomodarClimaHoy(cli, auxClimasHoy, torneos);
      acomodarClimaDias(cli, auxClimasDias, torneos);
    })
    
    setClimaHoy(auxClimasHoy);
    setClimasPredicciones(auxClimasDias);
  }
};

export const acomodarClimaHoy = (clima, auxClimasHoy, torneos) =>{
  const closestCondition = findClosestWeatherCondition(clima.currentConditions.icon)
  torneos.forEach((tor, indexTor)=>{
    const objClima = {
      ...clima.currentConditions, 
      ciudad: tor.ciudad,
      Temperature: {
        Metric: {Value: clima.currentConditions.temp}
      },
      WeatherText: closestCondition.translation
    }
    const iconoClima = closestCondition.icon;
    const iconoUrl = `https://developer.accuweather.com/sites/default/files/${
      iconoClima < 10 ? '0' + iconoClima : iconoClima
    }-s.png`;
    auxClimasHoy[indexTor] = {...objClima, iconoUrl};
  })
}

export const acomodarClimaDias = (clima, auxClimasDias, torneos) =>{
  const hora = new Date();
  clima.days.forEach((dias)=>{
    torneos.forEach((tor, indexTor)=>{
      if(dias.datetime === tor.fechaTorneo){
        dias.hours.forEach((hours)=>{
          if(hours.datetime === `${hora.getHours() < 10 ? '0' + hora.getHours() : hora.getHours()}:00:00`){
            const closestCondition = findClosestWeatherCondition(hours.icon);
            const objClima = {}
            objClima.ciudad = tor.ciudad;
            objClima.WeatherText = closestCondition.translation;
            const iconoClima = closestCondition.icon;
            const iconoUrl = `https://developer.accuweather.com/sites/default/files/${
              iconoClima < 10 ? '0' + iconoClima : iconoClima
            }-s.png`;
            objClima.iconoUrl = iconoUrl;
            auxClimasDias[indexTor] = {...objClima, ...dias};
          }
        })
      }
    })
  })
}

// clima visual crossing
export const getClimaVisualCrossing = async (locationKey, latLng, indexlL, clima, setClima, torneos) =>{
  try {
    const auxClimas = [...clima];
    if(latLng !== clima[indexlL]?.address){
      const apiKey = '3CHFPBTMWFFWZJU39MJBUNQG5'; //5 Reemplaza esto con tu clave de API
      const url = `https://weather.visualcrossing.com/VisualCrossingWebServices/rest/services/timeline/${locationKey.lat},${locationKey.lng}?key=${apiKey}&unitGroup=metric`;
      
      const response = await axios.get(url);

      auxClimas[indexlL] = {...response.data, ciudad: torneos[indexlL].ciudad};
    }
    setClima(auxClimas);
    //return response.data; // Devuelve los datos del clima actual junto con la URL del ícono
  } catch (error) {
    console.error('Error al obtener el clima actual:', error);
  } 
}