import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import { buscarNombresAutorizados, sacarMedalleriaDeportistas, sacarMedalleriaTrofeoClub, sacarPuntosClub, trofeo } from '../controllers/configuracionFirebase';
import { BsSubtract } from "react-icons/bs";
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { datosAdmin, departamentosColombia } from '../controllers/administrador';

const GanadorTrofeo = ({ idTorneoParticipando, usuario, torneoEspecifico }) => {

  const [ resultados, setResultados ] = useState([]);
  const [ trofeoClubes, setTrofeoClubes ] = useState([]);
  const [ trofeoPuntosClubes, setTrofeoPuntosClubes ] = useState([]);
  const [ trofeoDeportista, setTrofeoDeportista ] = useState([]);
  const [ categoriasDisponibles, setCategoriasDisponibles ] = useState([]);
  const [ categoriasElegidas, setCategoriasElegidas ] = useState([]);
  const [ categoriasDeportElegidas, setCategoriasDeportElegidas ] = useState([]);
  const [ categoriasPuntosClubElegidas, setCategoriasPuntosClubElegidas ] = useState([]);
  const [ nombresAutorizados, setNombresAutorizados ] = useState([]);
  const [ animateList, setAnimateList ] = useState(false);
  const [ conexion, setConexion ] = useState(true);
  const [ globalFilter, setGlobalFilter ] = useState(0);
  const resultadosRef = React.useRef(resultados);

  // Filtrar elementos basados en el término de búsqueda
  const filterResultados = trofeoDeportista.filter((item) =>
    (item.categoria.includes(globalFilter))
  );

  function sacarCategoriasElegidas(e){
    let auxCategoriasElegidas = [...categoriasElegidas];

    if(e.checked){
      auxCategoriasElegidas.push(e.value);
    }else{
      auxCategoriasElegidas.splice(auxCategoriasElegidas.indexOf(e.value), 1);
    }
    setCategoriasElegidas(auxCategoriasElegidas);
  }

  function sacarCategoriasDeportElegidas(e){
    let auxCategoriasElegidas = [...categoriasDeportElegidas];

    if(e.checked){
      auxCategoriasElegidas.push(e.value);
    }else{
      auxCategoriasElegidas.splice(auxCategoriasElegidas.indexOf(e.value), 1);
    }
    setCategoriasDeportElegidas(auxCategoriasElegidas);
  }

  function sacarCategoriasPuntosClubElegidas(e){
    let auxCategoriasElegidas = [...categoriasPuntosClubElegidas];

    if(e.checked){
      auxCategoriasElegidas.push(e.value);
    }else{
      auxCategoriasElegidas.splice(auxCategoriasElegidas.indexOf(e.value), 1);
    }
    setCategoriasPuntosClubElegidas(auxCategoriasElegidas);
  }

  function banderas(nombreclub){
    const buscarClub = nombresAutorizados.filter((auto)=>{return auto.nombre === nombreclub});
    if(buscarClub.length !== 0){
      const buscarBandera = departamentosColombia.filter((depBan) => {return depBan.dep === buscarClub[0].departamento});
      if(buscarBandera.length !== 0){
        return <img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={buscarBandera[0].bandera} alt='Bandera'/>
      }
    }
  }

  useEffect(() => {

    // Comparamos los arreglos anterior y actual para detectar cambios de posición
    if (JSON.stringify(resultadosRef.current) !== JSON.stringify(resultados)) {
      // Si hay una diferencia en los arreglos, activamos la animación
      setAnimateList(true);
    }

    // Actualizamos la referencia con el nuevo estado del arreglo
    resultadosRef.current = resultados;

    function sacarCategoriasRepetidas(arr) {
      const auxCategorias = new Set();
      arr.forEach((cate)=>{
        auxCategorias.add(cate.categoria);
      })
      return Array.from(auxCategorias);
    }

    setCategoriasDisponibles(sacarCategoriasRepetidas(resultados));
    setTrofeoClubes(sacarMedalleriaTrofeoClub(resultados, categoriasElegidas));
    setTrofeoDeportista(sacarMedalleriaDeportistas(resultados, categoriasDeportElegidas));
    setTrofeoPuntosClubes(sacarPuntosClub(resultados, torneoEspecifico.carril, categoriasPuntosClubElegidas));
    const animationDuration = 1000; // Duración de la animación en milisegundos (aquí 0.5 segundos)
    const timer = setTimeout(() => {
      setAnimateList(false);
    }, animationDuration);

    return () => clearTimeout(timer);
  }, [setAnimateList, resultados, resultadosRef, categoriasElegidas, categoriasDeportElegidas, categoriasPuntosClubElegidas])

  useEffect(() => {
    if(idTorneoParticipando !== ''){
      trofeo(setResultados, idTorneoParticipando, setConexion, usuario.nombreclub);
    }
  }, [usuario])

  useEffect(() => {
    buscarNombresAutorizados(setNombresAutorizados);
  }, [setNombresAutorizados])

  return (
    <>
      <TabView scrollable>
        <TabPanel header="Medalleria Club" leftIcon='pi pi-users me-2'>
          <Accordion className='m-auto pt-1' style={{maxWidth: '850px'}}>
            <AccordionTab header="Categorias">
              <div className="d-flex flex-wrap justify-content-evenly">
                {categoriasDisponibles.map((cate, indexCate)=>{
                  return <div key={indexCate} className="d-flex align-items-center" style={{width: '200px'}}>
                  <Checkbox inputId={cate} name="pizza" value={cate} onChange={sacarCategoriasElegidas} checked={categoriasElegidas.includes(cate)}/>
                  <label htmlFor={cate} className="ml-2">{cate}</label>
                </div>
                })}
              </div>
            </AccordionTab>
          </Accordion>
          <table className="shadow w-100" style={{background: 'rgb(50, 51, 51)'}}>
            <thead className='text-light'>
              {!conexion ?<tr>
                <th colSpan={15}>
                  <button className='btn btn-secondary ms-1 mt-2 shadow' id='sininternet' onClick={() => trofeo(setTrofeoClubes, idTorneoParticipando, setConexion, usuario.nombreclub)}>{'Actualizar Resultados'}</button>
                </th>
              </tr>
              :
              <></>}
              <tr style={{borderBottom: '1px solid gray'}}>
                <th style={{borderRight: '1px solid gray'}}><motion.button
                    className='btn btn-primary shadow'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{duration: 1}}
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop2"
                    ><BsSubtract/></motion.button>
                </th>
                <th className="titulo-consolidado" colSpan={15}>TROFEO</th>
              </tr>
              <tr>
                <th className="clubes" style={{padding: '8px', borderRight: '1px solid gray'}}>Puesto</th>
                <th className="clubes">Clubes</th>
                <th className="clubes" style={{borderRight: '1px solid gray'}}></th>
                <th style={{background: 'linear-gradient(150deg, #efb810, rgba(255, 255, 255, 0.8))', borderRight: '1px solid gray'}}>Oro</th>
                <th style={{background: 'linear-gradient(150deg, #848482, rgba(255, 255, 255, 0.8))', borderRight: '1px solid gray'}}>Plata</th>
                <th style={{background: 'linear-gradient(150deg, #CD7F32, rgba(255, 255, 255, 0.8))', borderRight: '1px solid gray'}}>Bronce</th>
                <th className="total">Total</th>
              </tr>
            </thead>
            <tbody>
              {trofeoClubes.length !== 0 ?(
                trofeoClubes.map((club, indexClub)=>{
                  return <motion.tr key={indexClub}
                  initial={{ scale: 0}}
                  animate={{ scale: animateList ? 0 : 1}}
                  transition={{ duration: 0.5, delay: `0.${indexClub}`, type: 'spring'}}
                  style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexClub}`}}
                  >
                    {indexClub === 0 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-1.png")} alt="trofeo primer lugar" /></td> : indexClub === 1 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-2.png")} alt="trofeo segundo lugar" /></td> :  indexClub === 2 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-3.png")} alt="trofeo tercer lugar" /></td> : <td className={`pt-2 pb-2 ${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}>{indexClub + 1}</td>}
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.club}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{banderas(club.club)}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.oro}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.plata}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.bronce}</td>
                    <th className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{club.total}</th>
                  </motion.tr>
                })
              ):(
                <tr>
                  <td colSpan={6} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>El torneo aún no empieza.</td>
                </tr>
              )
              }
            </tbody>
          </table>
        </TabPanel>
        <TabPanel header="Medalleria Deportista" leftIcon='pi pi-user me-2'>
        <Accordion className='m-auto pt-1' style={{maxWidth: '850px'}}>
            <AccordionTab header="Categorias">
              <div className="d-flex flex-wrap justify-content-evenly">
                {categoriasDisponibles.map((cate, indexCate)=>{
                  return <div key={indexCate} className="d-flex align-items-center" style={{width: '200px'}}>
                  <Checkbox inputId={cate} name="pizza" value={cate} onChange={sacarCategoriasDeportElegidas} checked={categoriasDeportElegidas.includes(cate)}/>
                  <label htmlFor={cate} className="ml-2">{cate}</label>
                </div>
                })}
              </div>
            </AccordionTab>
          </Accordion>
          <table className="shadow w-100" style={{background: 'rgb(50, 51, 51)'}}>
            <thead className='text-light'>
              <tr style={{borderBottom: '1px solid gray'}}>
                <th style={{borderRight: '1px solid gray'}}>
                  <select name="categoria" className='form-select' style={{width: '200px'}} onChange={(ev) => setGlobalFilter(ev.target.value)}>
                    <option value="">Todos</option>
                    {categoriasDisponibles.map((cate, indexCate)=>{
                      return <option key={indexCate} value={cate}>{cate}</option>
                    })}
                  </select>
                </th>
                <th className="titulo-consolidado" colSpan={14}>TROFEO</th>
              </tr>
              <tr>
                <th className="clubes" style={{padding: '8px', borderRight: '1px solid gray'}}>Puesto</th>
                <th className="clubes" style={{borderRight: '1px solid gray'}}>Deportista</th>
                <th className="clubes" style={{borderRight: '1px solid gray'}}>Categoria</th>
                <th className="clubes" style={{borderRight: '1px solid gray'}}>Club</th>
                <th style={{background: 'linear-gradient(150deg, #efb810, rgba(255, 255, 255, 0.8))', borderRight: '1px solid gray'}}>Oro</th>
                <th style={{background: 'linear-gradient(150deg, #848482, rgba(255, 255, 255, 0.8))', borderRight: '1px solid gray'}}>Plata</th>
                <th style={{background: 'linear-gradient(150deg, #CD7F32, rgba(255, 255, 255, 0.8))', borderRight: '1px solid gray'}}>Bronce</th>
                <th className="total">Total</th>
              </tr>
            </thead>
            <tbody>
              {trofeoDeportista.length !== 0 ?(
                filterResultados.map((club, indexClub)=>{
                  return <motion.tr key={indexClub}
                  initial={{ scale: 0}}
                  animate={{ scale: animateList ? 0 : 1}}
                  transition={{ duration: 0.5, delay: `0.${indexClub}`, type: 'spring'}}
                  style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexClub}`}}
                  >
                    {indexClub === 0 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-1.png")} alt="trofeo primer lugar" /></td> : indexClub === 1 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-2.png")} alt="trofeo segundo lugar" /></td> :  indexClub === 2 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-3.png")} alt="trofeo tercer lugar" /></td> : <td className={`pt-2 pb-2 ${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}>{indexClub + 1}</td>}
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.deportista}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.categoria}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.club}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.oro}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.plata}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.bronce}</td>
                    <th className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{club.total}</th>
                  </motion.tr>
                })
              ):(
                <tr>
                  <td colSpan={7} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>El torneo aún no empieza.</td>
                </tr>
              )
              }
            </tbody>
          </table>
        </TabPanel>
        <TabPanel header="Puntos Club" leftIcon='pi pi-users me-2'>
        <Accordion className='m-auto pt-1' style={{maxWidth: '850px'}}>
            <AccordionTab header="Categorias">
              <div className="d-flex flex-wrap justify-content-evenly">
                {categoriasDisponibles.map((cate, indexCate)=>{
                  return <div key={indexCate} className="d-flex align-items-center" style={{width: '200px'}}>
                  <Checkbox inputId={cate} name="pizza" value={cate} onChange={sacarCategoriasPuntosClubElegidas} checked={categoriasPuntosClubElegidas.includes(cate)}/>
                  <label htmlFor={cate} className="ml-2">{cate}</label>
                </div>
                })}
              </div>
            </AccordionTab>
          </Accordion>
          <table className='w-100' style={{background: 'rgb(50, 51, 51)'}}>
            <thead className='text-light'>
              <tr style={{borderBottom: '1px solid gray'}}>
                <th style={{borderRight: '1px solid gray'}}><motion.button
                    className='btn btn-primary shadow'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{duration: 1}}
                    data-bs-toggle="modal" data-bs-target="#staticBackdrop3"
                    ><BsSubtract/></motion.button>
                </th>
                <th className="titulo-consolidado" colSpan={parseInt(torneoEspecifico.carril) === 10 ? 22 : 18}>TROFEO</th>
              </tr>
              {parseInt(torneoEspecifico.carril) !== 10 ?(
                <tr>
                  <th className="clubes" style={{padding: '8px', borderRight: '1px solid gray'}}>Puesto</th>
                  <th className="clubes">Clubes</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}></th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>1</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>2</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>3</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>4</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>5</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>6</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>7</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>8</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>9</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>10</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>11</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>12</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>13</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>14</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>15</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>16</th>
                  <th className="total">Total</th>
                </tr>
              ):(
                <tr>
                  <th className="clubes" style={{padding: '8px', borderRight: '1px solid gray'}}>Puesto</th>
                  <th className="clubes">Clubes</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}></th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>1</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>2</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>3</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>4</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>5</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>6</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>7</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>8</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>9</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>10</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>11</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>12</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>13</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>14</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>15</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>16</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>17</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>18</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>19</th>
                  <th className="clubes" style={{borderRight: '1px solid gray'}}>20</th>
                  <th className="total">Total</th>
                </tr>
              )}
            </thead>
            <tbody>
              {trofeoPuntosClubes.length !== 0 ?(
                trofeoPuntosClubes.map((club, indexClub)=>{
                  return <motion.tr key={indexClub}
                  initial={{ scale: 0}}
                  animate={{ scale: animateList ? 0 : 1}}
                  transition={{ duration: 0.5, delay: `0.${indexClub}`, type: 'spring'}}
                  style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexClub}`}}
                  >
                    {indexClub === 0 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-1.png")} alt="trofeo primer lugar" /></td> : indexClub === 1 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-2.png")} alt="trofeo segundo lugar" /></td> :  indexClub === 2 ? <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-3.png")} alt="trofeo tercer lugar" /></td> : <td className={`pt-2 pb-2 ${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{background: 'white', width: '75px', borderRadius: '20px 0 0 0'}}>{indexClub + 1}</td>}
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.club}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{banderas(club.club)}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto1}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto2}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto3}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto4}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto5}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto6}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto7}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto8}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto9}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto10}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto11}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto12}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto13}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto14}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto15}</td>
                    <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto16}</td>
                    {parseInt(torneoEspecifico.carril) === 10 ?(
                      <>
                        <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto17}</td>
                        <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto18}</td>
                        <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto19}</td>
                        <td className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>{club.punto20}</td>
                      </>
                    ):(
                      <></>
                    )}
                    <th className={`${usuario.nombreclub === club.club ? 'bg-info text-light' : ''}`} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white', borderRadius: '0 0 20px 0'}}>{club.total}</th>
                  </motion.tr>
                })
              ):(
                <tr>
                  <td colSpan={19} style={{fontSize: '15px', fontFamily: 'Poppins', background: 'white'}}>El torneo aún no empieza.</td>
                </tr>
              )
              }
            </tbody>
          </table>
        </TabPanel>
      </TabView>
      <div className="modal fade" id="staticBackdrop2" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content" style={{borderRadius: '20px 0', background: 'linear-gradient(80deg, rgb(217, 255, 3), rgb(0, 119, 255), rgb(255, 0, 0))'}}>
            <div className="modal-body p-0 position-relative">
            <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close" style={{top: '18px', paddingLeft: '12px'}}></button>
              <table className='w-100'>
                <thead>
                  <tr style={{color: 'white', textShadow: '-2px 0 3px black'}}>
                    <th colSpan={2}><img className='ps-2' src={require('../img/img-logo.webp')} alt="logo pws" style={{width: '130px', filter: "drop-shadow(-1px 1px 2px black)"}}/></th>
                    <th style={{fontSize: '38px', textAlign: 'center'}} colSpan={4}>
                      TROFEO
                      <div className="marquee text-white">
                        <span style={{fontFamily: 'Poppins', fontSize: '20px', letterSpacing: '2px'}}>{`¡BIENVENIDOS! AL GRAN EVENTO ${torneoEspecifico.titulo} - ${datosAdmin.nombre}`}</span>
                      </div>
                    </th>
                    <th><img className='pe-2' src={require('../img/pwsmascota-derecha.png')} alt="logo pws" style={{width: '150px', filter: "drop-shadow(-1px 1px 2px black)"}}/></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    trofeoClubes.length !== 0 ? (
                    trofeoClubes.slice(0, 200).map((club, indexClub)=>{
                    return <motion.tr key={indexClub}
                    initial={{ x: -1000}}
                    animate={{ x: animateList ? -1000 : 0.1}}
                    style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexClub}`}}
                    transition={{ duration: 0.5, delay: `0.${indexClub}`, type: 'spring'}}
                    >
                      {indexClub === 0 ? <td className='submit' style={{width: '45px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-1.png")} alt="trofeo primer lugar" /></td> : indexClub === 1 ? <td className='submit' style={{borderRadius: '20px 0 0 0'}}><img style={{width:'50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-2.png")} alt="trofeo segundo lugar" /></td> :  indexClub === 2 ? <td className='submit' style={{borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-3.png")} alt="trofeo tercer lugar" /></td> : <td className='submit text-warning' style={{fontSize: '30px', borderRadius: '20px 0 0 0'}}>{indexClub + 1}</td>}
                      <td className='submit' style={{width: '50px', textAlign: 'center'}}>{banderas(club.club)}</td>
                      <td className='submit ps-1' style={{fontSize: '30px', fontFamily: 'Poppins', textAlign: 'start'}}>{club.club}</td>
                      <td className='submit' style={{fontSize: '29px', fontFamily: 'Fira Sans Extra Condensed', letterSpacing: '2px'}}>{club.oro}</td>
                      <td className='submit' style={{fontSize: '29px', fontFamily: 'Fira Sans Extra Condensed', letterSpacing: '2px'}}>{club.plata}</td>
                      <td className='submit' style={{fontSize: '29px', fontFamily: 'Fira Sans Extra Condensed', letterSpacing: '2px'}}>{club.bronce}</td>
                      <th className='submit text-warning' style={{fontSize: '29px', fontFamily: 'Fira Sans Extra Condensed', borderRadius: '0 0 20px 0', letterSpacing: '2px'}}>{club.total}</th>
                    </motion.tr>
                  })):(
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='w-100 submit d-flex justify-content-between' style={{fontSize: '18px', fontFamily: 'Fira Sans Extra Condensed', borderRadius: '20px 0 20px 0', letterSpacing: '2px'}}>
                <p className='m-0 p-1 ps-3'>PROWEBSPORTS</p>
                <p className='m-0 p-1 pe-3'>www.prowebsports.com.co</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="staticBackdrop3" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content" style={{borderRadius: '20px 0', background: 'linear-gradient(80deg, rgb(217, 255, 3), rgb(0, 119, 255), rgb(255, 0, 0))'}}>
            <div className="modal-body p-0 position-relative">
            <button type="button" className="btn-close position-absolute end-0" data-bs-dismiss="modal" aria-label="Close" style={{top: '18px', paddingLeft: '12px'}}></button>
              <table className='w-100'>
                <thead>
                  <tr style={{color: 'white', textShadow: '-2px 0 3px black'}}>
                    <th colSpan={2}><img className='ps-2' src={require('../img/img-logo.webp')} alt="logo pws" style={{width: '130px', filter: "drop-shadow(-1px 1px 2px black)"}}/></th>
                    <th style={{fontSize: '38px', textAlign: 'center'}}>
                      TROFEO
                      <div className="marquee text-white">
                        <span style={{fontFamily: 'Poppins', fontSize: '20px', letterSpacing: '2px'}}>{`¡BIENVENIDOS! AL GRAN EVENTO ${torneoEspecifico.titulo} - ${datosAdmin.nombre}`}</span>
                      </div>
                    </th>
                    <th><img className='pe-2' src={require('../img/pwsmascota-derecha.png')} alt="logo pws" style={{width: '150px', filter: "drop-shadow(-1px 1px 2px black)"}}/></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    trofeoPuntosClubes.length !== 0 ? (
                    trofeoPuntosClubes.slice(0, 200).map((club, indexClub)=>{
                    return <motion.tr key={indexClub}
                    initial={{ x: -1000}}
                    animate={{ x: animateList ? -1000 : 0.1}}
                    style={{borderRadius: '20px 0', boxShadow: '0 3px 8px black', zIndex: `1.${indexClub}`}}
                    transition={{ duration: 0.5, delay: `0.${indexClub}`, type: 'spring'}}
                    >
                      {indexClub === 0 ? <td className='submit' style={{width: '45px', borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-1.png")} alt="trofeo primer lugar" /></td> : indexClub === 1 ? <td className='submit' style={{borderRadius: '20px 0 0 0'}}><img style={{width:'50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-2.png")} alt="trofeo segundo lugar" /></td> :  indexClub === 2 ? <td className='submit' style={{borderRadius: '20px 0 0 0'}}><img style={{width: '50px', filter: "drop-shadow(-1px 1px 2px black)"}} src={require("../img/icon-trofeo-3.png")} alt="trofeo tercer lugar" /></td> : <td className='submit text-warning' style={{fontSize: '30px', borderRadius: '20px 0 0 0'}}>{indexClub + 1}</td>}
                      <td className='submit' style={{width: '50px', textAlign: 'center'}}>{banderas(club.club)}</td>
                      <td className='submit ps-1' style={{fontSize: '30px', fontFamily: 'Poppins', textAlign: 'start'}}>{club.club}</td>
                      <th className='submit text-warning' style={{fontSize: '29px', fontFamily: 'Fira Sans Extra Condensed', borderRadius: '0 0 20px 0', letterSpacing: '2px'}}>{club.total}</th>
                    </motion.tr>
                  })):(
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className='w-100 submit d-flex justify-content-between' style={{fontSize: '18px', fontFamily: 'Fira Sans Extra Condensed', borderRadius: '20px 0 20px 0', letterSpacing: '2px'}}>
                <p className='m-0 p-1 ps-3'>PROWEBSPORTS</p>
                <p className='m-0 p-1 pe-3'>www.prowebsports.com.co</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GanadorTrofeo;