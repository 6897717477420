export const datosAdmin = {
  nombre: 'ORCAS VALLE',
  conexion: 'orcas-valle',
  enlace: 'https://www.orcas-valle.com?source=qr',
  encargado: 'Willman Barbosa Velasquez',
  cel: '313-739-4026',
  celCorto: '3137394026',
  email: 'esnorcas@hotmail.com',
  telegram: ''
}

export const edades = Array.from({ length: 100 }, (_, index) => index + 1);

//export const pruebasAElegir = [
//  {valor: 'menores5y6', nombre: 'Menores 5 y 6 Juntos', color: 'text-warning'},
//  {valor: 'menores7y8', nombre: 'Menores 7 y 8 Juntos', color: 'text-info'},
//  {valor: 'menores9y10', nombre: 'Menores 9 y 10 Juntos', color: 'text-danger'},
//  {valor: 'infantil11y12', nombre: 'Infantil 11 y 12 Juntos', color: 'text-warning'},
//  {valor: 'infantil13y14', nombre: 'Infantil 13 y 14 Juntos', color: 'text-info'},
//  {valor: 'juvenil15y16', nombre: 'Juvenil 15 y 16 Juntos', color: 'text-danger'},
//  {valor: 'juvenil17ymayores', nombre: 'Juvenil 17 y Mayores Juntos', color: 'text-warning'},
//  {valor: 'relevosMixtoMenores5y6', nombre: 'Relevos Mixto Menores 5 y 6 Juntos', color: 'text-info'},
//  {valor: 'relevosMixtoMenores7y8', nombre: 'Relevos Mixto Menores 7 y 8 Juntos', color: 'text-danger'},
//  {valor: 'relevosMixtoMenores9y10', nombre: 'Relevos Mixto Menores 9 y 10 Juntos', color: 'text-warning'},
//  {valor: 'relevosMixtoInfantil11y12', nombre: 'Relevos Mixto Infantil 11 y 12 Juntos', color: 'text-info'},
//  {valor: 'relevosMixtoInfantil13y14', nombre: 'Relevos Mixto Infantil 13 y 14 Juntos', color: 'text-danger'},
//  {valor: 'relevosMixtoJuvenil15y16', nombre: 'Relevos Mixto Juvenil 15 y 16 Juntos', color: 'text-warning'},
//  {valor: 'relevosMixtoJuvenil17ymayores', nombre: 'Relevos Mixto Juvenil 17 y Mayores Juntos', color: 'text-info'},
//];

export const departamentosColombia = [
  { dep: "Amazonas", bandera: require('../banderasCol/amazonas.webp')},
  { dep: "Antioquia", bandera: require("../banderasCol/antioquia.webp")},
  { dep: "Arauca", bandera: require("../banderasCol/arauca.webp")},
  { dep: "Atlántico", bandera: require("../banderasCol/atlantico.webp")},
  { dep: "Bolívar", bandera: require("../banderasCol/bolivar.webp")},
  { dep: "Boyacá", bandera: require("../banderasCol/boyaca.webp")},
  { dep: "Caldas", bandera: require("../banderasCol/caldas.webp")},
  { dep: "Caquetá", bandera: require("../banderasCol/caqueta.webp")},
  { dep: "Casanare", bandera: require("../banderasCol/casanare.webp")},
  { dep: "Cauca", bandera: require("../banderasCol/cauca.webp")},
  { dep: "Cesar", bandera: require("../banderasCol/cesar.webp")},
  { dep: "Chocó", bandera: require("../banderasCol/choco.webp")},
  { dep: "Córdoba", bandera: require("../banderasCol/cordoba.webp")},
  { dep: "Cundinamarca", bandera: require("../banderasCol/cundinamarca.webp")},
  { dep: "Guainía", bandera: require("../banderasCol/guainia.webp")},
  { dep: "Guaviare", bandera: require("../banderasCol/guaviare.webp")},
  { dep: "Huila", bandera: require("../banderasCol/huila.webp")},
  { dep: "La Guajira", bandera: require("../banderasCol/la_guajira.webp")},
  { dep: "Magdalena", bandera: require("../banderasCol/magdalena.webp")},
  { dep: "Meta", bandera: require("../banderasCol/meta.webp")},
  { dep: "Nariño", bandera: require("../banderasCol/nariño.webp")},
  { dep: "Norte de Santander", bandera: require("../banderasCol/norte_de_santander.webp")},
  { dep: "Putumayo", bandera: require("../banderasCol/putumayo.webp")},
  { dep: "Quindío", bandera: require("../banderasCol/quindio.webp")},
  { dep: "Risaralda", bandera: require("../banderasCol/risaralda.webp")},
  { dep: "San Andrés y Providencia", bandera: require("../banderasCol/san_andres_y_providencia.webp")},
  { dep: "Santander", bandera: require("../banderasCol/santander.webp")},
  { dep: "Sucre", bandera: require("../banderasCol/sucre.webp")},
  { dep: "Tolima", bandera: require("../banderasCol/tolima.webp")},
  { dep: "Valle del Cauca", bandera: require('../banderasCol/valle_del_cauca.webp')},
  { dep: "Vaupés", bandera: require("../banderasCol/vaupes.webp")},
  { dep: "Vichada", bandera: require("../banderasCol/vichada.webp")},
];

export function pruebasRecords(){
  const auxPruebas = [
    '50 Libre',
    '100 Libre',
    '200 Libre',
    '400 Libre',
    '800 Libre',
    '1500 Libre',

    '50 Espalda',
    '100 Espalda',
    '200 Espalda',

    '50 Pecho',
    '100 Pecho',
    '200 Pecho',

    '50 Mariposa',
    '100 Mariposa',
    '200 Mariposa',

    '100 Combinado Ind.',
    '200 Combinado Ind.',
    '400 Combinado Ind.',
  ];

  return auxPruebas;
}

export const marcasMinimasInterligas = [
  {categoria: 'Juvenil A', edad: [15], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:28:97' },
    { prueba: '100 Mariposa', marcaMinima: '1:5:38' },
    { prueba: '200 Mariposa', marcaMinima: '2:41:17' },
    { prueba: '50 Pecho', marcaMinima: '0:34:91' },
    { prueba: '100 Pecho', marcaMinima: '1:19:22' },
    { prueba: '200 Pecho', marcaMinima: '2:55:65' },
    { prueba: '50 Espalda', marcaMinima: '0:32:64' },
    { prueba: '100 Espalda', marcaMinima: '1:22:26' },
    { prueba: '200 Espalda', marcaMinima: '2:34:53' },
    { prueba: '50 Libre', marcaMinima: '0:26:87' },
    { prueba: '100 Libre', marcaMinima: '0:58:85' },
    { prueba: '200 Libre', marcaMinima: '2:10:54' },
    { prueba: '400 Libre', marcaMinima: '4:39:53' },
    { prueba: '800 Libre', marcaMinima: '9:52:3' },
    { prueba: '1500 Libre', marcaMinima: '19:24:88' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:31:77' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:32:62' },
  ]},
  {categoria: 'Juvenil A', edad: [16], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:27:99' },
    { prueba: '100 Mariposa', marcaMinima: '1:3:28' },
    { prueba: '200 Mariposa', marcaMinima: '2:38:16' },
    { prueba: '50 Pecho', marcaMinima: '0:33:92' },
    { prueba: '100 Pecho', marcaMinima: '1:16:31' },
    { prueba: '200 Pecho', marcaMinima: '2:54:94' },
    { prueba: '50 Espalda', marcaMinima: '0:31:55' },
    { prueba: '100 Espalda', marcaMinima: '1:9:83' },
    { prueba: '200 Espalda', marcaMinima: '2:34:32' },
    { prueba: '50 Libre', marcaMinima: '0:26:19' },
    { prueba: '100 Libre', marcaMinima: '0:57:37' },
    { prueba: '200 Libre', marcaMinima: '2:8:20' },
    { prueba: '400 Libre', marcaMinima: '4:38:98' },
    { prueba: '800 Libre', marcaMinima: '9:48:7' },
    { prueba: '1500 Libre', marcaMinima: '19:5:18' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:28:47' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:20:59' },
  ]},
  {categoria: 'Juvenil B', edad: [17, 18], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:27:13' },
    { prueba: '100 Mariposa', marcaMinima: '1:1:4' },
    { prueba: '200 Mariposa', marcaMinima: '2:25:45' },
    { prueba: '50 Pecho', marcaMinima: '0:32:72' },
    { prueba: '100 Pecho', marcaMinima: '1:14:44' },
    { prueba: '200 Pecho', marcaMinima: '2:44:23' },
    { prueba: '50 Espalda', marcaMinima: '0:30:54' },
    { prueba: '100 Espalda', marcaMinima: '1:6:22' },
    { prueba: '200 Espalda', marcaMinima: '2:27:34' },
    { prueba: '50 Libre', marcaMinima: '0:25:70' },
    { prueba: '100 Libre', marcaMinima: '0:55:60' },
    { prueba: '200 Libre', marcaMinima: '2:3:71' },
    { prueba: '400 Libre', marcaMinima: '4:29:5' },
    { prueba: '800 Libre', marcaMinima: '9:30:71' },
    { prueba: '1500 Libre', marcaMinima: '18:24:83' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:23:11' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:9:29' },
  ]},
  {categoria: 'Mayores', edad: [19], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:26:39' },
    { prueba: '100 Mariposa', marcaMinima: '0:58:95' },
    { prueba: '200 Mariposa', marcaMinima: '2:23:37' },
    { prueba: '50 Pecho', marcaMinima: '0:31:70' },
    { prueba: '100 Pecho', marcaMinima: '1:11:11' },
    { prueba: '200 Pecho', marcaMinima: '2:39:14' },
    { prueba: '50 Espalda', marcaMinima: '0:29:44' },
    { prueba: '100 Espalda', marcaMinima: '1:5:10' },
    { prueba: '200 Espalda', marcaMinima: '2:25:50' },
    { prueba: '50 Libre', marcaMinima: '0:24:71' },
    { prueba: '100 Libre', marcaMinima: '0:54:59' },
    { prueba: '200 Libre', marcaMinima: '1:58:85' },
    { prueba: '400 Libre', marcaMinima: '4:23:21' },
    { prueba: '800 Libre', marcaMinima: '9:25:88' },
    { prueba: '1500 Libre', marcaMinima: '18:10:19' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:21:2' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:5:19' },
  ]},


  {categoria: 'Juvenil A', edad: [14], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:33:85' },
    { prueba: '100 Mariposa', marcaMinima: '1:21:52' },
    { prueba: '200 Mariposa', marcaMinima: '3:16:20' },
    { prueba: '50 Pecho', marcaMinima: '0:41:54' },
    { prueba: '100 Pecho', marcaMinima: '1:32:21' },
    { prueba: '200 Pecho', marcaMinima: '3:22:29' },
    { prueba: '50 Espalda', marcaMinima: '0:37:49' },
    { prueba: '100 Espalda', marcaMinima: '1:21:91' },
    { prueba: '200 Espalda', marcaMinima: '2:56:18' },
    { prueba: '50 Libre', marcaMinima: '0:31:38' },
    { prueba: '100 Libre', marcaMinima: '1:7:66' },
    { prueba: '200 Libre', marcaMinima: '2:31:93' },
    { prueba: '400 Libre', marcaMinima: '5:21:53' },
    { prueba: '800 Libre', marcaMinima: '11:3:53' },
    { prueba: '1500 Libre', marcaMinima: '20:59:87' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:54:64' },
    { prueba: '400 Combinado Ind.', marcaMinima: '6:4:57' },
  ]},
  {categoria: 'Juvenil A', edad: [15], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:33:5' },
    { prueba: '100 Mariposa', marcaMinima: '1:17:44' },
    { prueba: '200 Mariposa', marcaMinima: '3:3:92' },
    { prueba: '50 Pecho', marcaMinima: '0:41:42' },
    { prueba: '100 Pecho', marcaMinima: '1:32:14' },
    { prueba: '200 Pecho', marcaMinima: '3:19:19' },
    { prueba: '50 Espalda', marcaMinima: '0:35:96' },
    { prueba: '100 Espalda', marcaMinima: '1:21:1' },
    { prueba: '200 Espalda', marcaMinima: '2:55:37' },
    { prueba: '50 Libre', marcaMinima: '0:30:47' },
    { prueba: '100 Libre', marcaMinima: '1:6:65' },
    { prueba: '200 Libre', marcaMinima: '2:27:36' },
    { prueba: '400 Libre', marcaMinima: '5:16:25' },
    { prueba: '800 Libre', marcaMinima: '10:37:38' },
    { prueba: '1500 Libre', marcaMinima: '20:10:21' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:53:36' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:50:20' },
  ]},
  {categoria: 'Juvenil B', edad: [16, 17], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:31:69' },
    { prueba: '100 Mariposa', marcaMinima: '1:12:94' },
    { prueba: '200 Mariposa', marcaMinima: '2:57:86' },
    { prueba: '50 Pecho', marcaMinima: '0:39:34' },
    { prueba: '100 Pecho', marcaMinima: '1:27:7' },
    { prueba: '200 Pecho', marcaMinima: '3:10:19' },
    { prueba: '50 Espalda', marcaMinima: '0:34:60' },
    { prueba: '100 Espalda', marcaMinima: '1:15:98' },
    { prueba: '200 Espalda', marcaMinima: '2:45:53' },
    { prueba: '50 Libre', marcaMinima: '0:29:56' },
    { prueba: '100 Libre', marcaMinima: '1:4:47' },
    { prueba: '200 Libre', marcaMinima: '2:22:8' },
    { prueba: '400 Libre', marcaMinima: '5:4:21' },
    { prueba: '800 Libre', marcaMinima: '10:14:92' },
    { prueba: '1500 Libre', marcaMinima: '19:27:56' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:42:72' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:37:86' },
  ]},
  {categoria: 'Mayores', edad: [18], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:30:99' },
    { prueba: '100 Mariposa', marcaMinima: '1:10:51' },
    { prueba: '200 Mariposa', marcaMinima: '2:56:10' },
    { prueba: '50 Pecho', marcaMinima: '0:38:32' },
    { prueba: '100 Pecho', marcaMinima: '1:25:33' },
    { prueba: '200 Pecho', marcaMinima: '3:8:59' },
    { prueba: '50 Espalda', marcaMinima: '0:33:55' },
    { prueba: '100 Espalda', marcaMinima: '1:14:38' },
    { prueba: '200 Espalda', marcaMinima: '2:44:88' },
    { prueba: '50 Libre', marcaMinima: '0:28:79' },
    { prueba: '100 Libre', marcaMinima: '1:1:77' },
    { prueba: '200 Libre', marcaMinima: '2:16:12' },
    { prueba: '400 Libre', marcaMinima: '4:58:51' },
    { prueba: '800 Libre', marcaMinima: '10:6:77' },
    { prueba: '1500 Libre', marcaMinima: '19:12:10' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:41:75' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:33:38' },
  ]},
]


export const marcasMinimasInterclubes = [
  {categoria: 'Juvenil A', edad: [15], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:29:42' },
    { prueba: '100 Mariposa', marcaMinima: '1:6:90' },
    { prueba: '200 Mariposa', marcaMinima: '2:54:16' },
    { prueba: '50 Pecho', marcaMinima: '0:36:7' },
    { prueba: '100 Pecho', marcaMinima: '1:21:73' },
    { prueba: '200 Pecho', marcaMinima: '3:0:30' },
    { prueba: '50 Espalda', marcaMinima: '0:32:89' },
    { prueba: '100 Espalda', marcaMinima: '1:12:40' },
    { prueba: '200 Espalda', marcaMinima: '2:38:52' },
    { prueba: '50 Libre', marcaMinima: '0:27:2' },
    { prueba: '100 Libre', marcaMinima: '0:59:41' },
    { prueba: '200 Libre', marcaMinima: '2:12:3' },
    { prueba: '400 Libre', marcaMinima: '4:44:56' },
    { prueba: '800 Libre', marcaMinima: '10:19:96' },
    { prueba: '1500 Libre', marcaMinima: '20:10:13' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:35:0' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:46:0' },
  ]},
  {categoria: 'Juvenil A', edad: [16], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:28:27' },
    { prueba: '100 Mariposa', marcaMinima: '1:4:8' },
    { prueba: '200 Mariposa', marcaMinima: '2:44:50' },
    { prueba: '50 Pecho', marcaMinima: '0:34:61' },
    { prueba: '100 Pecho', marcaMinima: '1:18:13' },
    { prueba: '200 Pecho', marcaMinima: '3:1:52' },
    { prueba: '50 Espalda', marcaMinima: '0:32:57' },
    { prueba: '100 Espalda', marcaMinima: '1:11:46' },
    { prueba: '200 Espalda', marcaMinima: '2:37:49' },
    { prueba: '50 Libre', marcaMinima: '0:26:38' },
    { prueba: '100 Libre', marcaMinima: '0:58:28' },
    { prueba: '200 Libre', marcaMinima: '2:9:62' },
    { prueba: '400 Libre', marcaMinima: '4:41:39' },
    { prueba: '800 Libre', marcaMinima: '10:5:17' },
    { prueba: '1500 Libre', marcaMinima: '19:21:71' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:32:40' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:33:74' },
  ]},
  {categoria: 'Juvenil B', edad: [17, 18], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:27:55' },
    { prueba: '100 Mariposa', marcaMinima: '1:2:25' },
    { prueba: '200 Mariposa', marcaMinima: '2:32:63' },
    { prueba: '50 Pecho', marcaMinima: '0:33:41' },
    { prueba: '100 Pecho', marcaMinima: '1:15:36' },
    { prueba: '200 Pecho', marcaMinima: '2:47:40' },
    { prueba: '50 Espalda', marcaMinima: '0:30:95' },
    { prueba: '100 Espalda', marcaMinima: '1:8:55' },
    { prueba: '200 Espalda', marcaMinima: '2:30:25' },
    { prueba: '50 Libre', marcaMinima: '0:25:85' },
    { prueba: '100 Libre', marcaMinima: '0:56:16' },
    { prueba: '200 Libre', marcaMinima: '2:5:29' },
    { prueba: '400 Libre', marcaMinima: '4:32:49' },
    { prueba: '800 Libre', marcaMinima: '9:44:12' },
    { prueba: '1500 Libre', marcaMinima: '19:5:18' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:24:98' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:20:59' },
  ]},
  {categoria: 'Mayores', edad: [19], genero: 'Masculino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:26:79' },
    { prueba: '100 Mariposa', marcaMinima: '1:0:56' },
    { prueba: '200 Mariposa', marcaMinima: '2:29:2' },
    { prueba: '50 Pecho', marcaMinima: '0:32:40' },
    { prueba: '100 Pecho', marcaMinima: '1:13:55' },
    { prueba: '200 Pecho', marcaMinima: '2:48:11' },
    { prueba: '50 Espalda', marcaMinima: '0:30:22' },
    { prueba: '100 Espalda', marcaMinima: '1:7:54' },
    { prueba: '200 Espalda', marcaMinima: '2:29:72' },
    { prueba: '50 Libre', marcaMinima: '0:25:2' },
    { prueba: '100 Libre', marcaMinima: '0:55:25' },
    { prueba: '200 Libre', marcaMinima: '2:1:11' },
    { prueba: '400 Libre', marcaMinima: '4:31:0' },
    { prueba: '800 Libre', marcaMinima: '9:37:44' },
    { prueba: '1500 Libre', marcaMinima: '18:32:45' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:25:0' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:11:42' },
  ]},


  {categoria: 'Juvenil A', edad: [14], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:34:98' },
    { prueba: '100 Mariposa', marcaMinima: '1:23:61' },
    { prueba: '200 Mariposa', marcaMinima: '3:22:20' },
    { prueba: '50 Pecho', marcaMinima: '0:43:33' },
    { prueba: '100 Pecho', marcaMinima: '1:34:93' },
    { prueba: '200 Pecho', marcaMinima: '3:25:86' },
    { prueba: '50 Espalda', marcaMinima: '0:38:44' },
    { prueba: '100 Espalda', marcaMinima: '1:25:10' },
    { prueba: '200 Espalda', marcaMinima: '3:4:20' },
    { prueba: '50 Libre', marcaMinima: '0:31:78' },
    { prueba: '100 Libre', marcaMinima: '1:9:32' },
    { prueba: '200 Libre', marcaMinima: '2:33:94' },
    { prueba: '400 Libre', marcaMinima: '5:30:38' },
    { prueba: '800 Libre', marcaMinima: '11:48:76' },
    { prueba: '1500 Libre', marcaMinima: '22:25:75' },
    { prueba: '200 Combinado Ind.', marcaMinima: '3:0:81' },
    { prueba: '400 Combinado Ind.', marcaMinima: '6:29:42' },
  ]},
  {categoria: 'Juvenil A', edad: [15], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:34:33' },
    { prueba: '100 Mariposa', marcaMinima: '1:20:99' },
    { prueba: '200 Mariposa', marcaMinima: '3:9:50' },
    { prueba: '50 Pecho', marcaMinima: '0:42:80' },
    { prueba: '100 Pecho', marcaMinima: '1:32:89' },
    { prueba: '200 Pecho', marcaMinima: '3:27:11' },
    { prueba: '50 Espalda', marcaMinima: '0:36:79' },
    { prueba: '100 Espalda', marcaMinima: '1:21:69' },
    { prueba: '200 Espalda', marcaMinima: '3:2:9' },
    { prueba: '50 Libre', marcaMinima: '0:31:0' },
    { prueba: '100 Libre', marcaMinima: '1:7:89' },
    { prueba: '200 Libre', marcaMinima: '2:31:35' },
    { prueba: '400 Libre', marcaMinima: '5:28:33' },
    { prueba: '800 Libre', marcaMinima: '11:27:90' },
    { prueba: '1500 Libre', marcaMinima: '21:46:14' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:58:3' },
    { prueba: '400 Combinado Ind.', marcaMinima: '6:17:96' },
  ]},
  {categoria: 'Juvenil B', edad: [16, 17], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:32:33' },
    { prueba: '100 Mariposa', marcaMinima: '1:16:30' },
    { prueba: '200 Mariposa', marcaMinima: '3:8:10' },
    { prueba: '50 Pecho', marcaMinima: '0:39:16' },
    { prueba: '100 Pecho', marcaMinima: '1:27:55' },
    { prueba: '200 Pecho', marcaMinima: '3:13:82' },
    { prueba: '50 Espalda', marcaMinima: '0:35:73' },
    { prueba: '100 Espalda', marcaMinima: '1:17:43' },
    { prueba: '200 Espalda', marcaMinima: '2:50:44' },
    { prueba: '50 Libre', marcaMinima: '0:29:94' },
    { prueba: '100 Libre', marcaMinima: '1:5:32' },
    { prueba: '200 Libre', marcaMinima: '2:23:92' },
    { prueba: '400 Libre', marcaMinima: '5:10:59' },
    { prueba: '800 Libre', marcaMinima: '11:2:82' },
    { prueba: '1500 Libre', marcaMinima: '20:59:87' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:46:11' },
    { prueba: '400 Combinado Ind.', marcaMinima: '6:4:57' },
  ]},
  {categoria: 'Mayores', edad: [18], genero: 'Femenino', pruebas: [
    { prueba: '50 Mariposa', marcaMinima: '0:32:0' },
    { prueba: '100 Mariposa', marcaMinima: '1:13:34' },
    { prueba: '200 Mariposa', marcaMinima: '3:3:44' },
    { prueba: '50 Pecho', marcaMinima: '0:38:90' },
    { prueba: '100 Pecho', marcaMinima: '1:26:0' },
    { prueba: '200 Pecho', marcaMinima: '3:10:41' },
    { prueba: '50 Espalda', marcaMinima: '0:35:7' },
    { prueba: '100 Espalda', marcaMinima: '1:16:75' },
    { prueba: '200 Espalda', marcaMinima: '2:49:77' },
    { prueba: '50 Libre', marcaMinima: '0:29:33' },
    { prueba: '100 Libre', marcaMinima: '1:3:24' },
    { prueba: '200 Libre', marcaMinima: '2:19:91' },
    { prueba: '400 Libre', marcaMinima: '5:5:1' },
    { prueba: '800 Libre', marcaMinima: '10:32:4' },
    { prueba: '1500 Libre', marcaMinima: '20:10:21' },
    { prueba: '200 Combinado Ind.', marcaMinima: '2:44:90' },
    { prueba: '400 Combinado Ind.', marcaMinima: '5:50:20' },
  ]},
]