export function coloresCategoria(categoria, listadoCategorias){
  let color = '';
  const auxColor = listadoCategorias.filter((prue)=>{return prue.valor === categoria});
  if(auxColor.length !== 0){
    color = auxColor[0].color;
  }
  return color;
}

export function acomodarTextoCategoria(categorias, listadoCategorias){
  let text = [];
  categorias.forEach((categoria)=>{
    const auxText = listadoCategorias.filter((prue)=>{return prue.valor === categoria});
    if(auxText.length !== 0){
      text.push(auxText[0].nombre + '/');
    }
  })
  return text;
}