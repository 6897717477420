import React, { useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion";
import Swal from 'sweetalert2';
import { toast } from "react-hot-toast";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { cerrarInscripcionesTorneo, edicionesDelTorneo, editarTorneoTemporal, eliminarArchivo, eliminarFotoTorneo, eliminarTorneoTemporal, generarId, registrarTorneoTemporal, reinicioTotalTorneo, subirConvocatoriaTorneo, subirConvocatoriaTorneoEditar, subirFotoTorneo, subirFotoTorneoEditar, torneoRegistradoPWS, traerArchivos, traerTorneosTemporales } from '../controllers/configuracionFirebase';
import PublicarTorneo from './PublicarTorneo';
import { Tooltip as Mensaje } from "react-tooltip";
import { FcFolder, FcAbout } from "react-icons/fc";
import { Button } from 'primereact/button';
import { datosAdmin } from '../controllers/administrador';
import { buscarLatitudYLongitud } from '../controllers/apiGoogleMaps';

const CrearTorneo = ({ usuario, listPruebas, listadoCategorias }) => {

  const location = useLocation();
  const [ torneoTemporal, setTorneoTemporal ] = useState([]);
  const [ indexTorneo, setIndexTorneo ] = useState(-1);
  const [ imgCurso, setImgCurso ] = useState();
  const [ imgCursoNombre, setImgCursoNombre ] = useState();
  const [ idTorneo, setIdTorneo ] = useState();
  const [ pdfTorneo, setPdfTorneo ] = useState();
  const [ pdfTorneoNombre, setPdfTorneoNombre ] = useState();
  const [ archivos, setArchivos ] = useState();
  const [ editarTorneo, setEditarTorneo ] = useState([]);

  function sacarNombreDeLaUrl(url){
    const urlParts = url.split('F');
    const encodedFileName = urlParts[urlParts.length - 1];
    const urlParts1 = encodedFileName.split('?');
    const fileName = urlParts1[0];
    return decodeURIComponent(fileName);
  }

  /* la funcion eliminarCursoTemporal nos ayudara a eliminar el curso temporal que se va a registrar. */
  function eliminarTorneoTem(idCurso) {
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 0; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Está seguro de eliminar </br> el torneo?</p>
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 8,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        eliminarTorneoTemporal(idCurso);
        toast.success("El Torneo se esta eliminado. Esto puede tardar unos segundos o minutos.");
      }
    })
  }

  /* la funcion changeFileTorneo nos ayudara a subir una foto a firebase */
  function changeFileTorneo(e) {
    if (e.target.files.length !== 0) {
      const img = e.target.files[0];
      subirFotoTorneo(img, usuario.id, setImgCurso);
      setImgCursoNombre(img.name);
    }
  }

  function changeFileTorneoEditar(e) {
    if (e.target.files.length !== 0) {
      const img = e.target.files[0];
      subirFotoTorneoEditar(img, usuario.id, idTorneo);
    }
  }

  /* la funcion changeFileConvocatoria nos ayudara a subir la convocatoria a firebase */
  function changeFileConvocatoria(e) {
    if (e.target.files.length !== 0) {
      const pdf = e.target.files[0];
      subirConvocatoriaTorneo(pdf, usuario.id, setPdfTorneo);
      setPdfTorneoNombre(pdf.name);
    }
  }

  function changeFileConvocatoriaEditar(e) {
    if (e.target.files.length !== 0) {
      const pdf = e.target.files[0];
      subirConvocatoriaTorneoEditar(pdf, usuario.id, idTorneo);
    }
  }

  function eliminarFoto() {
    if (imgCursoNombre) {
      eliminarFotoTorneo(setImgCurso, usuario.id, imgCursoNombre);
    } else {
      toast.error(
        "Esta foto es la que viene por defecto, lo que no se puede eliminar."
      );
    }
  }

  function eliminarFotoEditar(torn) {
    const fileName = sacarNombreDeLaUrl(torn.image);
    if (fileName !== '') {
      eliminarFotoTorneo(setImgCurso, usuario.id, fileName, torn.id);
    } else {
      toast.error(
        "Esta foto es la que viene por defecto, lo que no se puede eliminar."
      );
    }
  }

  function eliminacionDeArchivos(id, tipo, name, index){
    eliminarArchivo(id, tipo, name);
    const auxArchivos = [...archivos];
    auxArchivos.splice(index, 1);
    setArchivos(auxArchivos);
  }

  function editarAdicionesTorneo(index){
    const auxEditar = [...editarTorneo];
    auxEditar[index] = true;
    setEditarTorneo(auxEditar);
  }

  function cerrarLasInscripciones(id, inscripcionesCerradas){
    cerrarInscripcionesTorneo(id, !inscripcionesCerradas, true);
  }

  function guardarCambiosEditado(index, id){
    const auxEditar = [...editarTorneo];
    auxEditar[index] = false;
    
    const titulo = document.getElementById(`titulo${index}`) ? document.getElementById(`titulo${index}`).value : '';
    const descripcion = document.getElementById(`descripcion${index}`) ? document.getElementById(`descripcion${index}`).value : '';
    const carril = document.getElementById(`carrilEditar${index}`) ? document.getElementById(`carrilEditar${index}`).value : '';
    const valorClub = document.getElementById(`valorClub${index}`) ? document.getElementById(`valorClub${index}`).value : '';
    const valorDeportista = document.getElementById(`valorDeportista${index}`) ? document.getElementById(`valorDeportista${index}`).value : '';
    const direccion = document.getElementById(`direccion${index}`) ? document.getElementById(`direccion${index}`).value : '';
    const fechaTorneo = document.getElementById(`fechaTorneo1${index}`) ? document.getElementById(`fechaTorneo1${index}`).value : '';
    const fechaTorneoF = document.getElementById(`fechaTorneoFin1${index}`) ? document.getElementById(`fechaTorneoFin1${index}`).value : '';
    const edCumplida = document.getElementById(`edadCumplida${index}`) ? document.getElementById(`edadCumplida${index}`).value : '';
    const regTiempo = document.getElementById(`registrarTiempo${index}`) ? document.getElementById(`registrarTiempo${index}`).value : '';
    const publicidad = document.getElementById(`publicidad${index}`) ? document.getElementById(`publicidad${index}`).value : '';
    const idFinales = document.getElementById(`idFinales${index}`) ? document.getElementById(`idFinales${index}`).value : '';
    const fechaInscripciones = document.getElementById(`fechaInscripciones${index}`) ? document.getElementById(`fechaInscripciones${index}`).value : ''; 
    const piscina = document.getElementById(`piscina${index}`) ? document.getElementById(`piscina${index}`).value : ''; 
    const cronometros = document.getElementById(`cronometros${index}`) ? document.getElementById(`cronometros${index}`).value : '';
    
    if(titulo !== '' && valorClub !== '' && valorDeportista !== '' && fechaInscripciones !== '' && carril !== ''){
      buscarLatitudYLongitud(direccion).then((coor)=>{
        const objTorneo = {
          titulo,
          descripcion,
          carril,
          valorClub,
          valorDeportista,
          direccion,
          fechaTorneo,
          fechaTorneoF,
          edCumplida,
          regTiempo,
          publicidad,
          idFinales,
          fechaInscripciones,
          ubicacionLatLng: coor.ubicacion,
          ciudad: coor.ciudad,
          piscina,
          cronometros
        }

        editarTorneoTemporal(id, objTorneo);
        edicionesDelTorneo(id, datosAdmin.conexion, objTorneo);
        setEditarTorneo(auxEditar);
      });
    }else{
      toast.error('Por favor, llene todos los campos.')
    }
  }

  function onSubmit(e) {
    e.preventDefault();
    const titulo = e.target.titulo.value
    const descripcion = e.target.descripcion.value;
    const carril = e.target.carril.value;
    const valorClub = e.target.valorClub.value;
    const valorDeportista = e.target.valorDeportista.value;
    const direccion = e.target.direccion.value;
    const fechaTorneo = e.target.fechaTorneo.value;
    const fechaTorneoF = e.target.fechaTorneoFinal.value;
    const edCumplida = e.target.edadCumplida.value;
    const regTiempo = e.target.registrarTiempo.value;
    const publicidad = e.target.publicidad.value;
    const evento = e.target.evento.value;
    const fechaInscripciones = e.target.fechaInscripciones.value;
    const piscina = e.target.piscina.value;
    const cronometros = e.target.cronometros.value;
    const id = generarId();

    const auxNuevoTorneo = {
      id: id,
      idForanea: usuario.id,
      idFinales: '',
      titulo,
      descripcion,
      carril,
      valorClub,
      valorDeportista,
      direccion,
      convocatoria: pdfTorneo ? pdfTorneo : "",
      image: imgCurso ? imgCurso : "",
      fechaTorneo,
      fechaTorneoF,
      edCumplida,
      regTiempo,
      publicidad,
      evento,
      fechaInscripciones,
      inscripcionesCerradas: true,
      jornadas: [],
      ubicacionLatLng: '3.3237482,-76.273715',
      ciudad: 'Florida',
      piscina,
      cronometros
    }

    try {
      registrarTorneoTemporal(id, auxNuevoTorneo);
      torneoRegistradoPWS(id, datosAdmin.conexion, titulo, fechaTorneo, fechaTorneoF, publicidad, fechaInscripciones);
      const alerta = Swal.mixin({
        toast: true
      })
      alerta.fire({
        html: `<div class="w-100 position-relative">
        <img style="width: 100px; position: absolute; top: 50px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
        <p style="z-index: 10; font-family: Poppins" class="mb-0"><b>¡EXCELENTE!</b></br>Torneo creado correctamente. </br> Cuando ya tengas el </br> torneo configurado, </br> abre las inscripciones. </br>
        Ahora dale clic en </br> <b>"Siguiente"</b> para configurar el torneo.</p>
        </div>`,
        confirmButtonText: 'Entendido',
        confirmButtonColor: 'rgb(18, 53, 248)',
        padding: 8
      })

      e.target.reset();
      setPdfTorneoNombre();
    } catch (error) {
      toast.error("`UPS!! ocurrio un error, intentalo de nuevo.");
    }
  }

  function ReiniciarTorneo(id){
    const alerta = Swal.mixin({
      toast: true,
      showCancelButton: true
    })
    alerta.fire({
      html: `<div class="w-100 position-relative">
      <img style="width: 100px; position: absolute; top: 33px; right: 0; z-index: -10" src="${require('../img/img-logo-swimmingt.webp')}" />
      <p style="z-index: 10; font-family: Poppins" class="mb-0">¿Esta seguro de reiniciar el torneo? </br>
      - Reinicio de tiempos </br>
      - Reinicio de planillas </br>
      - Reinicio de clubes </br>
      - Reinicio de nadadores
      </div>`,
      confirmButtonText: 'Confirmar',
      confirmButtonColor: 'rgb(18, 53, 248)',
      padding: 8,
      cancelButtonText: 'Cancelar',
    }).then((respuesta) =>{
      if(respuesta.isConfirmed){
        reinicioTotalTorneo(id);
        const torneoAReiniciar = torneoTemporal.filter((torn)=>{return torn.id === id})
        //torneoRegistradoPWS(datosAdmin.conexion, torneoAReiniciar[0].titulo, torneoAReiniciar[0].fechaTorneo, torneoAReiniciar[0].fechaTorneoF);
      }
    })
  }
  
  useEffect(() => {
    function manejoNavegacion(){
      if(location.pathname === '/creartorneo'){
        setIndexTorneo(-1);
      }
    }

    function controlEditar(){
      const auxEditar = [];
      torneoTemporal.forEach((torn)=>{
        auxEditar.push(false);
      })
    }
    
    controlEditar();
    traerTorneosTemporales(setTorneoTemporal);
    manejoNavegacion();
    traerArchivos(usuario.id, setArchivos);
  }, [usuario, location])

  return (
    <>
      {indexTorneo < 0 ? ( 
        <div className="container-fluid m-auto row row-cols-1 row-cols-sm-2 row-cols-md-4 justify-content-center align-items-start">
          <motion.div
            className="position-relative card m-2 pt-2 col shadow card-crear-torneo"
            style={{ width: "25rem" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1}}
          >
            <span className='linea linea-left'></span>
            <span className='linea linea-right'></span>
            <span className='linea linea-top'></span>
            <span className='linea linea-bottom'></span>
            <form onSubmit={onSubmit}>
              <div>
                <p
                  className="mb-0 d-flex justify-content-between align-items-center"
                  style={{ fontSize: "28px", fontFamily: "Calistoga", cursor: 'pointer' }}
                >
                  <FcFolder
                    data-bs-toggle="modal" data-bs-target="#exampleModal1" id='archivosGeneral'
                  />
                </p>
                <Mensaje
                  anchorId="archivosGeneral"
                  place="bottom"
                  content="Archivos"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
                <Mensaje
                  anchorId="ayuda"
                  place="bottom"
                  content="Algunas ayudas para ti."
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
              </div>
              {imgCurso ? (
                <img
                  src={imgCurso}
                  className="card-img-top m-auto"
                  alt="imagen del torneo"
                  style={{width: '200px'}}
                />
              ) : (
                <img
                  src={require(`../img/img_logo.png`)}
                  className="card-img-top m-auto"
                  alt="imagen del torneo"
                  style={{width: '200px'}}
                />
              )}
              <br />
              <label
                htmlFor="fotoTorneo"
                style={{ cursor: "pointer", color: "blue", width: "35px" }}
                className="me-2"
              >
                <img
                  style={{ cursor: "pointer", color: "blue" }}
                  id="subirFoto1"
                  className="w-100 mb-2"
                  src={require("../img/icono-subirFoto.webp")}
                  alt="icono del usuario"
                />
                <Mensaje
                  anchorId="subirFoto1"
                  place="bottom"
                  content="Sube una foto"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
              </label>
              <input
                type="file"
                id="fotoTorneo"
                onChange={changeFileTorneo}
                style={{ display: "none" }}
              />
              <label
                htmlFor="f"
                style={{ cursor: "pointer", color: "blue", width: "35px" }}
                onClick={() => eliminarFoto()}
              >
                <img
                  style={{ cursor: "pointer", color: "blue" }}
                  id="eliminarFoto1"
                  className="w-100 mb-2"
                  src={require("../img/icono-eliminarFoto.webp")}
                  alt="icono del usuario"
                />
                <Mensaje
                  anchorId="eliminarFoto1"
                  place="bottom"
                  content="Quitar foto"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
              </label>
              <div className="card-body">
                <div className='d-flex'>
                  <input
                    name="titulo"
                    type="text"
                    className="card-title form-control me-2"
                    placeholder="Titulo (max: 57 caracteres)"
                    maxLength={57}
                    required
                  />
                  <select name="evento" className='form-select mb-2' required style={{width: '100px'}}>
                    <option value="torneo">Torneo</option>
                    <option value="finales">Finales</option>
                  </select>
                </div>
                <div className='d-flex'>
                  <select name="carril" className='form-select me-2 mb-2' id='carril' required style={{width: '68px'}}>
                    <option value="">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                  </select>
                  <input
                    name="valorClub"
                    type="number"
                    className="card-title form-control me-2"
                    placeholder="Valor club"
                    required
                  />
                  <input
                    name="valorDeportista"
                    type="number"
                    className="card-title form-control"
                    placeholder="Valor deportista"
                    required
                  />
                </div>
                <Mensaje
                  anchorId="carril"
                  place="bottom"
                  content="Carriles"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
                <div className='d-flex'>
                  <input
                    name="fechaTorneo"
                    id="fechaTorneo"
                    type="date"
                    className="card-title form-control me-2"
                    placeholder="Valor club"
                  />
                  <input
                    name="fechaTorneoFinal"
                    id="fechaTorneoFinal"
                    type="date"
                    className="card-title form-control"
                    required
                  />
                </div>
                <div className='d-flex'>
                  <select name="edadCumplida" className='form-select me-2 mb-2' required>
                    <option value="si">Edad cumplida año actual</option>
                    <option value="no">Edad cumplida año anterior</option>
                  </select>
                  <input
                    name="fechaInscripciones"
                    id="fechaInscripcion"
                    type="datetime-local"
                    className="card-title form-control"
                    placeholder="Valor deportista"
                    required
                  />
                </div>
                <div className='d-flex'>
                  <select name="piscina" id='pisci' className='form-select me-2 mb-2' required>
                    <option value="25">25 mts</option>
                    <option value="50">50 mts</option>
                    <option value="ninguno">Ninguno</option>
                  </select>
                  <select name="cronometros" className='form-select mb-2' required>
                    <option value="si">CRONOSPORTS</option>
                    <option value="no">Sin CRONOSPORTS</option>
                  </select>
                </div>
                <div className='d-flex'>
                  <select name="registrarTiempo" className='form-select me-2 mb-2' required>
                    <option value="si">Habilitar el registro de tiempos</option>
                    <option value="no">Inhabilitar el registro de tiempos</option>
                  </select>
                  <select name="publicidad" className='form-select mb-2' required>
                    <option value="si">Publicar en PROWEBSPORTS</option>
                    <option value="no">No Publicar en PROWEBSPORTS</option>
                  </select>
                </div>
                <Mensaje
                  anchorId="pisci"
                  place="bottom"
                  content="Distancia Piscina"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
                <Mensaje
                  anchorId="fechaTorneo"
                  place="bottom"
                  content="Fecha del Torneo"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
                <Mensaje
                  anchorId="fechaInscripcion"
                  place="bottom"
                  content="Fecha cierre de Inscripciones"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
                <Mensaje
                  anchorId="fechaTorneoFinal"
                  place="bottom"
                  content="Fecha Fin Torneo"
                  className="bg-primary shadow"
                  style={{ zIndex: 1 }}
                />
                <input
                  name="direccion"
                  type="text"
                  className="card-title form-control"
                  placeholder="Dirección de Google Maps"
                />
                <textarea
                  name="descripcion"
                  className="card-text form-control"
                  placeholder="Descripción... (OPCIONAL)"
                ></textarea>
                <div className='d-flex align-items-center mt-2'>
                  <label
                  htmlFor="convocatoriaTorneo"
                  style={{ cursor: "pointer", color: "blue", width: "35px" }}
                  >
                  <img
                    style={{ cursor: "pointer", color: "blue" }}
                    id="subirDocumento"
                    className="w-100"
                    src={require("../img/icon-pdf.png")}
                    alt="icono del usuario"
                  />
                  <Mensaje
                    anchorId="subirDocumento"
                    place="bottom"
                    content="Sube la convocatoria"
                    className="bg-primary shadow"
                    style={{ zIndex: 1 }}
                    />
                  </label>
                  {pdfTorneoNombre ? pdfTorneoNombre : 'Convocatoria.pdf'}
                  <br />
                </div>
                <input
                  type="file"
                  id="convocatoriaTorneo"
                  onChange={changeFileConvocatoria}
                  style={{ display: "none" }}
                />
                <Button
                type="submit"
                icon='pi pi-plus'
                label="Crear Evento"
                className="btn btn-success mt-2"></Button>
              </div>
            </form>
          </motion.div>
          {torneoTemporal.map((torneo, indexTorneo) => {
            return (
              <motion.div
                className={`position-relative card m-2 pt-2 col shadow card-torneo ${torneo.inscripcionesCerradas ? 'animacion-rojo' : 'animacion-verde'}`}
                style={{ width: "25rem" }}
                key={indexTorneo}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: `0.${indexTorneo}5` }}
              >
                <span className='linea linea-left'></span>
                <span className='linea linea-right'></span>
                <span className='linea linea-top'></span>
                <span className='linea linea-bottom'></span>
                <div className="d-flex justify-content-between">
                  <p
                    className="mb-0 d-flex align-items-center"
                    style={{ fontSize: "18px", fontFamily: "Calistoga" }}
                  >
                    <img
                      style={{ width: "18px" }}
                      src={require("../img/icon-usuario-curso.webp")}
                      alt="icono del usuario"
                    />
                    {0}
                  </p>
                  {usuario.nombreclub === 'PROWEBSPORTS' ?(
                    <img
                      style={{ width: "25px", cursor: "pointer" }}
                      src={require("../img/icono-eliminarFoto.webp")}
                      alt="icono eliminar"
                      onClick={() => eliminarTorneoTem(torneo.id)}
                    />
                  ):(
                    <></>
                  )}
                </div>
                <div className={`alert mb-0 ${!torneo.inscripcionesCerradas ? 'alert-success' : 'alert-danger'}`} role="alert">
                  {!torneo.inscripcionesCerradas ? `Limite Inscripciones ${torneo.fechaInscripciones}` : 'Inscripciones Cerradas'}
                </div>
                {torneo.image !== "" ? (
                  <img
                    src={torneo.image}
                    className="card-img-top m-auto"
                    alt="imagen del torneo"
                    style={{width: '200px'}}
                  />
                ) : (
                  <img
                    src={require("../img/img_logo.png")}
                    className="card-img-top m-auto"
                    alt="imagen del torneo"
                    style={{width: '200px'}}
                  />
                )}
                <div className="card-body">
                    {editarTorneo[indexTorneo] ? (
                      <>
                      <label
                      htmlFor="fotoTorneoEditar"
                      style={{ cursor: "pointer", color: "blue", width: "35px" }}
                      className="me-2"
                      >
                        <img
                          style={{ cursor: "pointer", color: "blue" }}
                          id={`editaFoto${indexTorneo}`}
                          className="w-100 mb-2"
                          src={require("../img/icono-subirFoto.webp")}
                          alt="icono del usuario"
                        />
                        <Mensaje
                          anchorId={`editaFoto${indexTorneo}`}
                          place="bottom"
                          content="Sube otra foto"
                          className="bg-primary shadow"
                          style={{ zIndex: 1 }}
                        />
                      </label>
                      <input
                        type="file"
                        id="fotoTorneoEditar"
                        onChange={changeFileTorneoEditar}
                        onClick={() => setIdTorneo(torneo.id)}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor="fe"
                        style={{ cursor: "pointer", color: "blue", width: "35px" }}
                        onClick={() => eliminarFotoEditar(torneo)}
                      >
                        <img
                          style={{ cursor: "pointer", color: "blue" }}
                          id={`eliminarFoto${indexTorneo}`}
                          className="w-100 mb-2"
                          src={require("../img/icono-eliminarFoto.webp")}
                          alt="icono del usuario"
                        />
                        <Mensaje
                          anchorId={`eliminarFoto${indexTorneo}`}
                          place="bottom"
                          content="Quitar foto"
                          className="bg-primary shadow"
                          style={{ zIndex: 1 }}
                        />
                      </label>
                      <input
                        id={`idFinales${indexTorneo}`}
                        type="text"
                        defaultValue={torneo.idFinales}
                        className="card-title form-control"
                        placeholder="Codigo Finales"
                      />
                      <input
                        id={`titulo${indexTorneo}`}
                        type="text"
                        defaultValue={torneo.titulo}
                        className="card-title form-control"
                        placeholder="Titulo (max: 57 caracteres)"
                        maxLength={57}
                        required
                      />
                      <div className='d-flex'>
                        <select name="carril" className='form-select me-2 mb-2' id={`carrilEditar${indexTorneo}`} defaultValue={torneo.carril} required style={{width: '68px'}}>
                          <option value="">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        <input
                          id={`valorClub${indexTorneo}`}
                          type="number"
                          defaultValue={torneo.valorClub}
                          className="card-title form-control me-2"
                          placeholder="Valor club"
                          required
                        />
                        <input
                          id={`valorDeportista${indexTorneo}`}
                          type="number"
                          defaultValue={torneo.valorDeportista}
                          className="card-title form-control"
                          placeholder="Valor deportista"
                          required
                        />
                      </div>
                      <div className='d-flex'>
                        <input
                          id={`fechaTorneo1${indexTorneo}`}
                          type="date"
                          defaultValue={torneo.fechaTorneo}
                          className="card-title form-control me-2"
                          placeholder="Valor club"
                        />
                        <input
                          id={`fechaTorneoFin1${indexTorneo}`}
                          type="date"
                          defaultValue={torneo.fechaTorneoF}
                          className="card-title form-control"
                        />
                      </div>
                      <div className='d-flex'>
                        <select className='form-select me-2 mb-2' defaultValue={torneo.edCumplida} id={`edadCumplida${indexTorneo}`} required>
                          <option value="si">Edad cumplida año actual</option>
                          <option value="no">Edad cumplida año anterior</option>
                        </select>
                        <input
                          id={`fechaInscripciones${indexTorneo}`}
                          type="datetime-local"
                          defaultValue={torneo.fechaInscripciones}
                          className="card-title form-control"
                          placeholder="Valor deportista"
                          required
                        />
                      </div>
                      <div className='d-flex'>
                        <select className='form-select me-2 mb-2' defaultValue={torneo.piscina} id={`piscina${indexTorneo}`} required>
                          <option value="25">25 mts</option>
                          <option value="50">50 mts</option>
                          <option value="ninguno">Ninguno</option>
                        </select>
                        <select className='form-select mb-2' defaultValue={torneo.cronometros} id={`cronometros${indexTorneo}`} required>
                          <option value="si">CRONOSPORTS</option>
                          <option value="no">Sin CRONOSPORTS</option>
                        </select>
                      </div>
                      <div className='d-flex'>
                        <select className='form-select me-2 mb-2' defaultValue={torneo.regTiempo} id={`registrarTiempo${indexTorneo}`} required>
                          <option value="si">Habilitar el registro de tiempos</option>
                          <option value="no">Inhabilitar el registro de tiempos</option>
                        </select>
                        <select className='form-select mb-2' defaultValue={torneo.publicidad} id={`publicidad${indexTorneo}`} required>
                          <option value="si">Publicar en PROWEBSPORTS</option>
                          <option value="no">No Publicar en PROWEBSPORTS</option>
                        </select>
                      </div>
                      <Mensaje
                        anchorId={`piscina${indexTorneo}`}
                        place="bottom"
                        content="Distancia Piscina"
                        className="bg-primary shadow"
                        style={{ zIndex: 1 }}
                      />
                      <Mensaje
                        anchorId={`fechaTorneo1${indexTorneo}`}
                        place="bottom"
                        content="Fecha del Torneo"
                        className="bg-primary shadow"
                        style={{ zIndex: 1 }}
                      />
                      <Mensaje
                        anchorId={`fechaInscripciones${indexTorneo}`}
                        place="bottom"
                        content="Fecha cierre de Inscripciones"
                        className="bg-primary shadow"
                        style={{ zIndex: 1 }}
                      />
                      <Mensaje
                        anchorId={`fechaTorneoFin1${indexTorneo}`}
                        place="bottom"
                        content="Fecha Fin Torneo"
                        className="bg-primary shadow"
                        style={{ zIndex: 1 }}
                      />
                      <input
                        id={`direccion${indexTorneo}`}
                        type="text"
                        className="card-title form-control"
                        placeholder="Dirección de Google Maps"
                        defaultValue={torneo.direccion}
                      />
                      <textarea
                        id={`descripcion${indexTorneo}`}
                        defaultValue={torneo.descripcion}
                        className="card-text form-control mb-2"
                        placeholder="Descripción..."
                      ></textarea>
                      <div className='d-flex align-items-center mt-2'>
                        <label
                        htmlFor="convocatoriaTorneoEditar"
                        style={{ cursor: "pointer", color: "blue", width: "35px" }}
                        >
                        <img
                          style={{ cursor: "pointer", color: "blue" }}
                          id={`subirDocumento${indexTorneo}`}
                          className="w-100"
                          src={require("../img/icon-pdf.png")}
                          alt="icono del usuario"
                        />
                        <Mensaje
                          anchorId={`subirDocumento${indexTorneo}`}
                          place="bottom"
                          content="Sube otra convocatoria"
                          className="bg-primary shadow"
                          style={{ zIndex: 1 }}
                          />
                        </label>
                        {torneo.convocatoria !== '' ? sacarNombreDeLaUrl(torneo.convocatoria) : 'Convocatoria.pdf'}
                        <br />
                      </div>
                      <input
                        type="file"
                        id="convocatoriaTorneoEditar"
                        onChange={changeFileConvocatoriaEditar}
                        onClick={() => setIdTorneo(torneo.id)}
                        style={{ display: "none" }}
                      />
                      </>
                    ):(
                      <>
                        <h5 className="card-title" style={{ fontFamily: "Alkatra" }}>
                          {torneo.titulo}
                        </h5>
                        {torneo.evento === 'finales' ?(
                          <p className='m-0' style={{
                            fontSize: "20px",
                            fontFamily: "Poppins",
                            textAlign: "center",
                          }}>
                            {torneo.id}
                          </p>
                        ):(
                          <></>
                        )}
                        <p
                          className="card-text"
                          style={{
                            fontSize: "14px",
                            fontFamily: "Poppins",
                            textAlign: "justify",
                          }}
                        >
                          Valor por club: ${parseInt(torneo.valorClub).toLocaleString()} <br />
                          Valor por deportista: ${parseInt(torneo.valorDeportista).toLocaleString()} <br />
                          Fecha torneo: {torneo.fechaTorneo} <br />
                          {torneo.descripcion} {torneo.descripcion !== '' ? <br /> : <></>}
                          {torneo.convocatoria !== '' ? (
                            <Link to={torneo.convocatoria} target='_blank' className='d-flex align-items-center'>
                              <img
                              style={{ cursor: "pointer", color: "blue", width: "38px" }}
                              src={require("../img/icon-pdf.png")}
                              alt="icono del pdf"
                              />
                              Convocatoria.pdf
                            </Link>
                          ):(<></>)}
                        </p>
                      </>
                    )}
                  <Link
                    to="/creartorneo/configurar"
                    onClick={() => setIndexTorneo(indexTorneo)}
                  >
                    <Button
                      type='button'
                      className='btn submit mb-1'
                      icon='pi pi-spin pi-cog'
                      label='Siguiente'
                    ></Button>
                  </Link>
                  <Button
                  type="button"
                  icon='pi pi-pencil'
                  label={`${editarTorneo[indexTorneo] ? 'Guardar' : 'Editar'}`}
                  className="btn btn-primary mb-1 ms-1"
                  onClick={editarTorneo[indexTorneo] ? () => guardarCambiosEditado(indexTorneo, torneo.id) : () => editarAdicionesTorneo(indexTorneo)}></Button>
                  <Button
                  type="button"
                  icon={torneo.inscripcionesCerradas ? 'pi pi-check-circle' : 'pi pi-times-circle'}
                  label={`${torneo.inscripcionesCerradas ? 'Abrir Inscripciones' : 'Cerrar Inscripciones'}`}
                  className={`btn ${torneo.inscripcionesCerradas ? 'btn-success' : 'btn-danger'}`}
                  onClick={() => cerrarLasInscripciones(torneo.id, torneo.inscripcionesCerradas)}></Button>
                  {usuario.nombreclub === 'PROWEBSPORTS' ?(
                    <Button
                    type="button"
                    icon='pi pi-refresh'
                    label='Reiniciar Evento'
                    className="btn btn-warning mt-1"
                    onClick={() => ReiniciarTorneo(torneo.id)}></Button>
                  ):(
                    <></>
                  )}
                </div>
              </motion.div>
            );
          })}
        </div>
      ):(
        <Routes>
          <Route
            path="/configurar"
            element={
              <PublicarTorneo
                torneoTemporal={torneoTemporal}
                indexTorneo={indexTorneo}
                listPruebas={listPruebas}
                listadoCategorias={listadoCategorias}
              />
            }
          />
        </Routes>
      )}
      <div className="modal fade" id="exampleModal1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Archivos</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {archivos ? (
                <table className='table table-striped table-hover table-bordered'>
                  <thead>
                    <tr>
                      <th>Archivo</th>
                      <th>Nombre</th>
                      <th>Tipo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {archivos.map((arch, indexArch)=>{
                      return <tr key={indexArch}>
                        <td>
                          <img
                          style={{ cursor: "pointer", color: "blue", width: "38px" }}
                          src={arch.tipo === 'convocatorias' ? require('../img/icon-pdf.png') : arch.url}
                          alt="icono"
                          />
                        </td>
                        <td>{arch.name}</td>
                        <td>{arch.tipo}</td>
                        <td>
                        <img
                          style={{ width: "25px", cursor: "pointer" }}
                          src={require("../img/icono-eliminarFoto.webp")}
                          alt="icono eliminar"
                          onClick={() => eliminacionDeArchivos(usuario.id, arch.tipo, arch.name, indexArch)}
                        />
                        </td>
                      </tr>
                    })}
                  </tbody>
                </table>
              ):(
                <></>
              )}
            </div>
            <div className="modal-footer">
              <div className="alert alert-danger w-100" role="alert">
                Los archivos que no este utilizando en la plaforma, eliminelos,
                asi libera el espacio del almacenamiento y baja los costos.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) 
}

export default CrearTorneo;