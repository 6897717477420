import axios from "axios";
import { cerrarInscripcionesTorneo } from "./configuracionFirebase";

export const fetchData = async (setFechaGoogle) => {
  try {
    const response = await axios.get('https://worldtimeapi.org/api/ip');
    // Manejar la respuesta
    const currentDate = new Date(response.data.datetime);
    setFechaGoogle(currentDate);
  } catch (error) {
    const auxFechaDispositivo = new Date();
    setFechaGoogle(auxFechaDispositivo);
  }
}; 

export async function time(fechaCierre, idTorneo, index, fecha){
    const date = fecha;
    const festivalDias = document.getElementById(`dia${index}`);
    const dia_hora = document.getElementById(`dia-hora${index}`);
    const contenedorFecha = document.getElementById(`contenedor-fecha${index}`);
    
    if (date) {
      //fecha a futuro
      const fechaFuturo = new Date(fechaCierre);
      //Obtener fecha actual y milisegundos
      const fechaActual = new Date(date);
      //Obtener la distancia entre ambas fechas
      let distancia = fechaFuturo.getTime() - fechaActual.getTime()
      
      //Calculos dias, horas, minutos, segundos
      let dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
      let horas = Math.floor((distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))

      if(festivalDias){
        //Escribimos resultados
        if(dias > 0){
          festivalDias.innerHTML = ('0' + dias).slice(dias >= 100 ? -3 : -2);
          dia_hora.innerHTML = (' Dias');
        }else{
          if(horas > 0){
            festivalDias.innerHTML = ('0' + horas).slice(-2);
            dia_hora.innerHTML = (' Horas');
          }
          if(horas === 0){
            festivalDias.style.display = 'none';
            dia_hora.innerHTML = (' Minutos');
          }
        }
        
        if (distancia < 0) {
            contenedorFecha.style.display = 'none';
            cerrarInscripcionesTorneo(idTorneo, true, false);
        }
      }
    }
  }

export function timeLocal(fechaCierre, index){
  function festival() {
    const festivalDias = document.getElementById(`diaLocal${index}`);
    const festivalHoras = document.getElementById(`horaLocal${index}`);
    const festivalMinutos = document.getElementById(`minutoLocal${index}`);
    const festivalSegundos = document.getElementById(`segundoLocal${index}`);
    const contenedorFecha = document.getElementById(`contenedor-diaLocal${index}`);
    const messageFechaCierre = document.getElementById(`messageFechaCierre${index}`);
    const torneo = document.getElementById(`card-torneo${index}`);
    //fecha a futuro
    const fechaFuturo = new Date(fechaCierre).getTime()
    //Obtener fecha actual y milisegundos
    const fechaActual = new Date().getTime()
    //Obtener la distancia entre ambas fechas
    let distancia = fechaFuturo - fechaActual
    //Calculos dias, horas, minutos, segundos
    let dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
    let horas = Math.floor((distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutos = Math.floor((distancia % (1000 * 60 * 60)) / (1000 * 60))
    let segundos = Math.floor((distancia % (1000 * 60)) / (1000))
    
    if(festivalDias && festivalHoras && festivalMinutos && festivalSegundos){
      if(dias >= 5){
        torneo.classList.add("animacion-verde");
        messageFechaCierre.classList.add("border-success");
      messageFechaCierre.classList.add("text-success");
      }else if(dias > 1 && dias < 5){
        torneo.classList.add("animacion-azul");
        messageFechaCierre.classList.add("border-primary");
      messageFechaCierre.classList.add("text-primary");
      }else if(dias <= 1){
        torneo.classList.add("animacion-rojo");
        messageFechaCierre.classList.add("border-danger");
      messageFechaCierre.classList.add("text-danger");
      }
      //Escribimos resultados
      festivalDias.innerHTML = ('0' + dias).slice(dias >= 100 ? -3 : -2)
      festivalHoras.innerHTML = ('0' + horas).slice(-2)
      festivalMinutos.innerHTML = ('0' + minutos).slice(-2)
      festivalSegundos.innerHTML = ('0' + segundos).slice(-2)
      if (distancia < 0) {
        clearInterval(interval);
        contenedorFecha.style.display = 'none';
        torneo.classList.add("animacion-rojo");
        messageFechaCierre.classList.add("border-danger");
      messageFechaCierre.classList.add("text-danger");
      }
    }
  }
      
  let interval = setInterval(function () {
    festival()
  }, 1000)
}

export function coloresFechaCierre(fechaCierre){
  //fecha a futuro
  const fechaFuturo = new Date(fechaCierre).getTime()
  //Obtener fecha actual y milisegundos
  const fechaActual = new Date().getTime()
  //Obtener la distancia entre ambas fechas
  let distancia = fechaFuturo - fechaActual
  //Calculos dias, horas, minutos, segundos
  let dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
  
  if(dias >= 5){
    return 'success';
  }else if(dias > 1 && dias < 5){
    return 'info';
  }else if(dias <= 1){
    return 'error';
  }

  if (distancia < 0) {
    return 'error';
  }
}

/*
export function timeLocalPublicidad(fechaCierre, index, setFestival){
  function festival() {
    const festivalDias = document.getElementById(`diaLocal${index}`);
    const festivalHoras = document.getElementById(`horaLocal${index}`);
    const festivalMinutos = document.getElementById(`minutoLocal${index}`);
    const festivalSegundos = document.getElementById(`segundoLocal${index}`);
    const contenedorFecha = document.getElementById(`contenedor-diaLocal${index}`);
    //fecha a futuro
    const fechaFuturo = new Date(fechaCierre).getTime()
    //Obtener fecha actual y milisegundos
    const fechaActual = new Date().getTime()
    //Obtener la distancia entre ambas fechas
    let distancia = fechaFuturo - fechaActual
    //Calculos dias, horas, minutos, segundos
    let dias = Math.floor(distancia / (1000 * 60 * 60 * 24))
    let horas = Math.floor((distancia % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    let minutos = Math.floor((distancia % (1000 * 60 * 60)) / (1000 * 60))
    let segundos = Math.floor((distancia % (1000 * 60)) / (1000))
    
    if(festivalDias && festivalHoras && festivalMinutos && festivalSegundos){
      if(dias >= 5){
        festivalDias.style.color = 'yellowgreen';
        festivalHoras.style.color = 'yellowgreen';
        festivalMinutos.style.color = 'yellowgreen';
        festivalSegundos.style.color = 'yellowgreen';
      }else if(dias > 1 && dias < 5){
        festivalDias.style.color = 'blue';
        festivalHoras.style.color = 'blue';
        festivalMinutos.style.color = 'blue';
        festivalSegundos.style.color = 'blue';
      }else if(dias <= 1){
        festivalDias.style.color = 'red';
        festivalHoras.style.color = 'red';
        festivalMinutos.style.color = 'red';
        festivalSegundos.style.color = 'red';
      }
      //Escribimos resultados
      festivalDias.innerHTML = ('0' + dias).slice(dias >= 100 ? -3 : -2)
      festivalHoras.innerHTML = ('0' + horas).slice(-2)
      festivalMinutos.innerHTML = ('0' + minutos).slice(-2)
      festivalSegundos.innerHTML = ('0' + segundos).slice(-2)
      if (distancia < 0) {
        clearInterval(interval);
        contenedorFecha.style.display = 'none';
        setFestival(false);
      }
    }
  }
      
  let interval = setInterval(function () {
    festival()
  }, 1000)
}
*/

export function reloj() {
  function tiempoActual(){
    const relojSpan = document.getElementById(`reloj`);
    const ahora = new Date();
    let horas = ahora.getHours();
    const minutos = ahora.getMinutes();
    const segundos = ahora.getSeconds();
    const ampm = horas >= 12 ? 'PM' : 'AM';

    // Convertir a formato de 12 horas
    horas = horas % 12 || 12;

    const tiempoFormateado = `${horas < 10 ? '0' : ''}${horas}:${minutos < 10 ? '0' : ''}${minutos}:${segundos < 10 ? '0' : ''}${segundos} ${ampm}`;
    if(relojSpan){
      relojSpan.innerHTML = tiempoFormateado;
    }
  }
  setInterval(function () {
    tiempoActual();
  }, 1000)
}
export function anioAutor(){
  const ahora = new Date();
  return ahora.getFullYear();
}