import React, { useState, useEffect } from 'react'
import { guardarJPPlanilla } from '../controllers/configuracionFirebase';
import { acomodarTextoCategoria, coloresCategoria } from '../controllers/estilos';
import toast from 'react-hot-toast';
import { Tooltip as Mensaje } from "react-tooltip";
import { Button } from 'primereact/button';
import { SpeedDial } from 'primereact/speeddial';
import { Tooltip } from 'primereact/tooltip';

const PublicarTorneo = ({ torneoTemporal, indexTorneo, listPruebas, listadoCategorias}) => {

  const [ editarJP, setEditarJP ] = useState(false);
  const [ jornadas, setJornadas ] = useState([]);
  const [ categorias, setCategorias ] = useState(['']);
  
  function anadirRequisito(index){
    const auxJornada = [...jornadas];
    const auxRequisito = {
      edadStart: '0',
      edadEnd: '0',
      cantPruebas: '0'
    }

    auxJornada[index].requisitos.push(auxRequisito);
    setJornadas(auxJornada);
  }

  function quitarRequisito(index){
    const auxJornada = [...jornadas];
    auxJornada[index].requisitos.splice(auxJornada[index].requisitos.length - 1, 1);
    setJornadas(auxJornada);
  }

  function anadirJornada(){
    const auxJornada = {
      requisitos: [{
        edadStart: '0',
        edadEnd: '0',
        cantPruebas: '0'
      }],
      pruebas: [],
      categoria: []
    }

    const auxAnadir = [...jornadas, auxJornada];
    setJornadas(auxAnadir);
  }

  function quitarJornada(){
    const auxJornada = [...jornadas];
    auxJornada.splice(jornadas.length - 1, 1);
    setJornadas(auxJornada);
  }

  function anadirCategoria(indexJor, indexCate){
    const auxJornada = [...jornadas];
    const auxCategoria = '';
    auxJornada[indexJor].categoria.splice(indexCate + 1, 0, auxCategoria);
    setJornadas(auxJornada);
  }

  function quitarCategoria(indexJor, indexCate){
    const auxJornada = [...jornadas];
    auxJornada[indexJor].categoria.splice(indexCate, 1);
    setJornadas(auxJornada);
  }

  function anadirPrueba(indexJor, indexPrue){
    const auxCategorias = [];
    categorias.forEach((cate, indexCate)=>{
      const categoria = document.getElementById(`categoria${indexCate}/${indexJor}`).value;
      auxCategorias.push(categoria);
    })
    const auxJornada = [...jornadas];
    const auxPrueba = {
      idPrueba: '',
      prueba: '',
      mm: ''
    }

    if(listPruebas.length !== 0){
      const auxLisPruebas = [];
      auxCategorias.forEach((auxCate)=>{
        listPruebas.forEach((listPrue)=>{
          if(listPrue.categoria === auxCate){
            auxLisPruebas.push(listPrue);
          }
        })
      })
      if(auxLisPruebas.length !== 0){
        auxJornada[indexJor].pruebas.splice(indexPrue + 1, 0, auxPrueba);
      }else{
        toast.error('No tenemos pruebas registradas para esta categoria');
      }
    }else{
      toast.error('No tenemos pruebas registradas para esta categoria');
    }

    setJornadas(auxJornada);
  }

  function quitarPrueba(indexJor, indexPrue){
    const auxJornada = [...jornadas];

    if(listPruebas.length !== 0){
      auxJornada[indexJor].pruebas.splice(indexPrue, 1);
    }

    setJornadas(auxJornada); 
  }
 
  function pruebasEspecificas(index){
    const auxJornada = [...jornadas];
    if(!editarJP){
      const auxCategorias = [];
      auxJornada[index].categoria.forEach((cate, indexCate)=>{
        const auxCategoria = document.getElementById(`categoria${indexCate}/${index}`) ? document.getElementById(`categoria${indexCate}/${index}`).value : '';
        if(auxCategoria !== ''){
          auxCategorias.push(auxCategoria);
          auxJornada[index].categoria[indexCate] = auxCategoria;
        }
      })
      const auxLisPruebas = [];
      auxJornada[index].categoria.forEach((auxCate)=>{
        if(listPruebas.length !== 0){
          listPruebas.forEach((listPrue)=>{
            if(listPrue.categoria === auxCate){
              auxLisPruebas.push(listPrue);
            }
          })
        } 
      })
      
      return auxLisPruebas;
    }else{
      const auxCategorias = [];
      auxJornada[index].categoria.forEach((cate, indexCate)=>{
        const aux = document.getElementById(`categoria${indexCate}/${index}`) ? document.getElementById(`categoria${indexCate}/${index}`).value : ''
        if(aux !== ''){
          const auxCategoria = aux;
          if(auxCategoria !== ''){
            auxCategorias.push(auxCategoria);
          }
        }else{
          const auxCategoria = cate;
          if(auxCategoria !== ''){
            auxCategorias.push(auxCategoria);
          }
        }
      })
      const auxLisPruebas = [];
      auxCategorias.forEach((auxCate)=>{
        if(listPruebas.length !== 0){
          listPruebas.forEach((listPrue)=>{
            if(listPrue.categoria === auxCate){
              auxLisPruebas.push(listPrue);
            }
          })
        } 
      })

      return auxLisPruebas;
    }
  }
 
  function agregarCambiosJPPlanilla(e){
    e.preventDefault();
    const auxJornada = [...jornadas];

    for (let i = 0; i < auxJornada.length; i++) {
      for (let j = 0; j < auxJornada[i].requisitos.length; j++) {
        auxJornada[i].requisitos[j].cantPruebas = document.getElementById(`cantPruebas${j}/${i}`).value;
        auxJornada[i].requisitos[j].edadStart = document.getElementById(`edadStart${j}/${i}`).value;
        auxJornada[i].requisitos[j].edadEnd = document.getElementById(`edadEnd${j}/${i}`).value;
      }
      for (let j = 0; j < auxJornada[i].categoria.length; j++) {
        auxJornada[i].categoria[j] = document.getElementById(`categoria${j}/${i}`).value;
      }
      for (let j = 0; j < auxJornada[i].pruebas.length; j++) {
        auxJornada[i].pruebas[j].idPrueba = document.getElementById(`prueba${j}/${i}`).value;
        const aux = listPruebas.filter((prue)=>{return prue.id === document.getElementById(`prueba${j}/${i}`).value});
        auxJornada[i].pruebas[j].prueba = aux[0].prueba;

        if(torneoTemporal[indexTorneo].evento === 'finales'){
          const min = document.getElementById(`jornada${i}prueba${j}TiempoMinutos`).value;
          const seg = document.getElementById(`jornada${i}prueba${j}TiempoSegundos`).value;;
          const mili = document.getElementById(`jornada${i}prueba${j}TiempoMilisegundos`).value;;
          const tiempo = min + ':' + seg + ':' + mili;
          auxJornada[i].pruebas[j].mm = tiempo;
        }
      }
      auxJornada[i].calentamiento = document.getElementById(`horaCalentamiento${i}`).value;
      auxJornada[i].fecha = document.getElementById(`fechaJor${i}`).value;
      auxJornada[i].inicio = document.getElementById(`horaInicio${i}`).value;
      auxJornada[i].cantPruebas = document.getElementById(`cantPruebas${i}`).value;
      auxJornada[i].jornada = i;
    }
    guardarJPPlanilla(torneoTemporal[indexTorneo].id, auxJornada);
    setEditarJP(false);
  }

  function mensajesGuia(indexJor, id, text, estilo){
    return <Mensaje
      anchorId={`${id + indexJor}`}
      place="bottom"
      content={text}
      className={`${estilo} shadow`}
      style={{ zIndex: 1 }}
    />
  }

  const items = [
    {
        label: 'Quitar Jornada',
        icon: 'pi pi-minus',
        command: () => quitarJornada()
    },
    {
        label: 'Agregar Jornada',
        icon: 'pi pi-plus',
        command: () => anadirJornada()
    }
  ];

  useEffect(() => {
    setJornadas(torneoTemporal[indexTorneo].jornadas);
    setEditarJP(torneoTemporal[indexTorneo].jornadas !== 0 ? false : true);
  }, []) 

  return (
    <>
      <div className="w-100 bg-light m-auto position-relative">
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <h1>{torneoTemporal[indexTorneo] ? torneoTemporal[indexTorneo].titulo : ''}</h1>
          <p>{torneoTemporal[indexTorneo] ? torneoTemporal[indexTorneo].descripcion : ''}</p>   
        </div>
        <div>
          <div>
            {torneoTemporal[indexTorneo].jornadas.length !== 0 ? (
              <>
                {editarJP ? (
                  <>
                    <form onSubmit={agregarCambiosJPPlanilla}>
                    {jornadas.map((jor, indexJor)=>{
                      return <div key={indexJor}>
                      <div className="alert alert-primary alert-dismissible fade show" role="alert">
                        <h1>{`Jornada ${indexJor + 1}`}</h1>
                      </div>
                      <div className='d-flex justify-content-around align-items-center mt-2'>
                        <div className="form-floating">
                          <input type="time" id={`horaCalentamiento${indexJor}`} defaultValue={jor.calentamiento} className='form-control'/>
                          <label htmlFor="deportCC">Calentamiento</label>
                        </div>
                        <div className="form-floating">
                          <input type="text" className='text-center form-control' id={`fechaJor${indexJor}`} defaultValue={jor.fecha} placeholder='Fecha de inicio ej: 02 de Julio del 2023'/>
                          <label htmlFor="fechaPlanilla">Fecha Jornada {indexJor + 1}</label>
                        </div>
                        <div className="form-floating">
                          <input type="number" id={`cantPruebas${indexJor}`} className='form-control' defaultValue={jor.cantPruebas} placeholder='10'/>
                          <label htmlFor="deportCC">Cantidad Pruebas</label>
                        </div>
                        <div className="form-floating">
                          <input type="time" id={`horaInicio${indexJor}`} className='form-control' defaultValue={jor.inicio}/>
                          <label htmlFor="deportCC">Competencia</label>
                        </div>
                      </div>
                      <hr />
                      <div className='d-flex flex-wrap justify-content-center'>
                        <Button type='button' icon='pi pi-plus' className='btn btn-success me-1' label='Requisito' onClick={() => anadirRequisito(indexJor)}></Button>
                        <Button type='button' icon='pi pi-minus' className='btn btn-danger' label='Requisito' onClick={() => quitarRequisito(indexJor)}></Button>
                      </div>
                      {jor.requisitos.length !== 0 ?(
                        jor.requisitos.map((req, indexReq)=>{
                          return <div key={indexReq}>
                            <div className='d-flex flex-wrap justify-content-around align-items-center mt-1'>
                              <div className="form-floating">
                                <input type="number" id={`edadStart${indexReq}/${indexJor}`} className='form-control' defaultValue={req.edadStart} placeholder='0'/>
                                <label htmlFor="deportCC">Edad Inicio</label>
                              </div>
                              <div className="form-floating">
                                <input type="number" id={`cantPruebas${indexReq}/${indexJor}`} className='form-control' defaultValue={req.cantPruebas} placeholder='10'/>
                                <label htmlFor="deportCC">Cantidad Pruebas</label>
                              </div>
                              <div className="form-floating">
                                <input type="number" id={`edadEnd${indexReq}/${indexJor}`} className='form-control' defaultValue={req.edadEnd} placeholder='100'/>
                                <label htmlFor="deportCC">Edad Final</label>
                              </div>
                            </div>
                            <hr />
                          </div>
                        })
                      ):(
                        <></>
                      )}
                      {mensajesGuia(indexJor, 'horaCalentamiento', 'Hora de Calentamiento', 'bg-primary')}
                      {mensajesGuia(indexJor, 'horaInicio', 'Hora de Inicio', 'bg-primary')}
                      {mensajesGuia(indexJor, 'cantPruebas', '¿Cuantas pruebas vas a permitir?', 'bg-primary')}
                      {mensajesGuia(indexJor, 'edadStart', 'Edad Inicial que se le aplicara el requisito', 'bg-primary')}
                      {mensajesGuia(indexJor, 'edadEnd', 'Edad Final que se le aplicara el requisito', 'bg-primary')}
                      {jor.categoria.length === 0 ? (
                        <Button type='button' icon='pi pi-plus-circle' label='Categoria' className='btn btn-success mt-1' onClick={() => anadirCategoria(indexJor)}></Button>
                      ):(
                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                          <h3>{`Categorias Inscritas ${jor.categoria.length}`}</h3>
                        </div>
                      )}
                      {jor.categoria.length !== 0 ? (
                        jor.categoria.map((cate, indexCate)=>{
                          return <div key={cate} className='d-flex justify-content-center align-items-center'>
                            <div className="form-floating">
                              <select name={`categoria${indexCate}/${indexJor}`} defaultValue={cate} id={`categoria${indexCate}/${indexJor}`} className='form-select mt-2' required>
                                <option value="">Elige la categoria</option>
                                {listadoCategorias.length !== 0 ?(
                                  listadoCategorias.map((prue, indexPrue)=>{
                                    return <option key={indexPrue} value={prue.valor}>{prue.valor}</option>
                                  })
                                ):(
                                  <></>
                                )}
                              </select>
                              <label htmlFor="deportCC">{listadoCategorias.length} Categorias Disponibles</label>
                            </div>
                            <div>
                              <Button type='button' icon='pi pi-plus' severity='success' className='ms-1 me-1' raised style={{borderRadius: '50%'}} onClick={() => anadirCategoria(indexJor, indexCate)}></Button>
                              <Button type='button' icon='pi pi-minus' severity='danger' raised style={{borderRadius: '50%'}} onClick={() => quitarCategoria(indexJor, indexCate)}></Button>
                            </div>
                          </div>
                        })
                      ):(
                        <></>
                      )}
                      <hr />
                      {jor.pruebas.length === 0 && jor.categoria.length !== 0 ?(
                        <Button type='button' icon='pi pi-plus-circle' label='Prueba' className='btn btn-success mt-1' onClick={() => anadirPrueba(indexJor)}></Button>
                      ):(
                        <div className="alert alert-primary alert-dismissible fade show" role="alert">
                          <h3>{`Pruebas Inscritas ${jor.pruebas.length}`}</h3>
                        </div>
                      )}
                      {jor.pruebas.length !== 0 ? (
                        jor.pruebas.map((prue, indexPrue)=>{
                          return <div key={prue.idPrueba} className='d-flex justify-content-center align-items-center'>
                            <div className="form-floating">
                              <select id={`prueba${indexPrue}/${indexJor}`} defaultValue={prue.idPrueba} className='form-select' required>
                                <option value="">Elige la prueba</option>
                                {pruebasEspecificas(indexJor).map((prueEspe, indexPrueEspe)=>{
                                  return <option className={coloresCategoria(prueEspe.categoria, listadoCategorias)} value={prueEspe.id} key={indexPrueEspe}>{prueEspe.prueba + ' - ' + acomodarTextoCategoria([prueEspe.categoria], listadoCategorias) + (prueEspe.requisitos[0].edadInicio !== '' ? prueEspe.requisitos[0].edadInicio + ' a ' + prueEspe.requisitos[prueEspe.requisitos.length - 1].edadFin : '')}</option>
                                })}
                              </select>
                              <label htmlFor="prueba">{pruebasEspecificas(indexJor).length} Pruebas Disponibles</label>
                            </div>
                            {torneoTemporal[indexTorneo].evento === 'finales' ? (
                              <div className='d-flex justify-content-around align-items-center'>
                                <div className="form-floating">
                                  <input style={{width: '68px'}} type="number" id={`jornada${indexJor}prueba${indexPrue}TiempoMinutos`} className='form-control' placeholder='0' defaultValue={prue.mm.split(':')[0]}/>
                                  <label htmlFor="deportCC">Min.</label>
                                </div>
                                <div className="form-floating">
                                  <input style={{width: '68px'}} type="number" id={`jornada${indexJor}prueba${indexPrue}TiempoSegundos`} className='form-control' placeholder='0' defaultValue={prue.mm.split(':')[1]}/>
                                  <label htmlFor="deportCC">Seg.</label>
                                </div>
                                <div className="form-floating">
                                  <input style={{width: '68px'}} type="number" id={`jornada${indexJor}prueba${indexPrue}TiempoMilisegundos`} className='form-control' placeholder='0' defaultValue={prue.mm.split(':')[2]}/>
                                  <label htmlFor="deportCC">Mili.</label>
                                </div>
                              </div>
                            ):(
                              <></>
                            )}
                            <div>
                              <Button type='button' icon='pi pi-plus' severity='success' raised style={{borderRadius: '50%'}} className='me-1 ms-1' onClick={() => anadirPrueba(indexJor, indexPrue)}></Button>
                              <Button type='button' icon='pi pi-minus' severity='danger' raised style={{borderRadius: '50%'}} onClick={() => quitarPrueba(indexJor, indexPrue)}></Button>
                            </div>
                          </div>
                        })
                      ):(
                        <></>
                      )}
                    </div>
                    })}
                    <hr />
                    <Button type='submit' icon='pi pi-check-circle' label='Guardar Cambios' className='btn btn-success mb-1'></Button>
                    <Button type='button' icon='pi pi-times-circle' label='Cancelar' className='btn btn-secondary ms-2 mb-1' onClick={() => setEditarJP(false)}></Button>
                    </form>
                  </>
                ):(
                  <>
                  <Button type='button' icon='pi pi-pencil' label='Editar JCP' className='btn btn-secondary ms-2' onClick={() => setEditarJP(true)}></Button>
                  {torneoTemporal[indexTorneo].jornadas.map((jor, indexJor)=>{
                    return <form onSubmit={agregarCambiosJPPlanilla} className='pb-4' key={indexJor}>
                    <table className='table table-bordered mt-2'>
                      <thead>
                        <tr>
                          <th colSpan={1}>JORNADA</th>
                          <th colSpan={2}>Cantidad Pruebas</th>
                        </tr>
                        <tr className='table-active'>
                          <td colSpan={1}>{indexJor + 1}</td>
                          <td colSpan={2}>{jor.cantPruebas}</td>
                        </tr>
                        <tr>
                          <th>Calentamiento</th>
                          <th>Fecha Jornada</th>
                          <th>Competencia</th>
                        </tr>
                        <tr className='table-active'>
                          <td>{jor.calentamiento}</td>
                          <td>{jor.fecha}</td>
                          <td>{jor.inicio}</td>
                        </tr>
                        <tr>
                          <th colSpan={3}>Requisitos rango de edades</th>
                        </tr>
                        <tr>
                          <th>Edad Inicial</th>
                          <th>Pruebas max.</th>
                          <th>Edad Final</th>
                        </tr>
                        {jor.requisitos.length !== 0?(
                          jor.requisitos.map((req, indexReq)=>{
                            return <tr key={indexReq} className='table-active'>
                            <td>{parseInt(req.edadStart) === 0 ? 'Minima' : req.edadStart}</td>
                            <td>{req.cantPruebas}</td>
                            <td>{parseInt(req.edadEnd) === 0 ? 'Maxima' : req.edadEnd}</td>
                          </tr>
                          })
                        ):(
                          <tr className='table-active'>
                            <td>No hay requisitos registrados en esta jornada.</td>
                          </tr>
                        )}
                        <tr>
                          <th colSpan={3}>Categorias</th>
                        </tr>
                      </thead>
                      <tbody>
                        {jor.categoria.length !== 0 ?(
                          jor.categoria.map((cate, indexCate)=>{
                            return <tr key={indexCate} className='table-active'>
                              <td colSpan={3}>{cate}</td>
                            </tr>
                          })
                        ):(
                          <tr className='table-active'>
                            <td>No hay categorias registradas en esta jornada.</td>
                          </tr>
                        )}
                        <tr>
                          <th colSpan={torneoTemporal[indexTorneo].evento === 'finales' ? 1 : 3}>Pruebas</th>
                          {torneoTemporal[indexTorneo].evento === 'finales' ?(
                            <>
                              <th></th>
                              <th>Clasificación</th>
                            </>
                          ):(
                            <></>
                          )}
                        </tr>
                        {jor.pruebas.length !== 0 ?(
                          jor.pruebas.map((prue, indexPrue)=>{
                            return <tr key={indexPrue} className='table-active'>
                              <td colSpan={torneoTemporal[indexTorneo].evento === 'finales' ? 1 : 3}>{prue.prueba}</td>
                              {torneoTemporal[indexTorneo].evento === 'finales' ?(
                                <>
                                  <td></td>
                                  <td>{prue.mm}</td>
                                </>
                              ):(
                                <></>
                              )}
                            </tr>
                          })
                        ):(
                          <tr className='table-active'>
                            <td>No hay pruebas registradas en esta jornada.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </form>
                })}
                  </>
                )}
              </>
            ):(
              <>
                <form onSubmit={agregarCambiosJPPlanilla}>
                {jornadas.map((jor, indexJor)=>{
                return <div key={indexJor}>
                  <div className="alert alert-primary alert-dismissible fade show" role="alert">
                    <h1>{`Jornada ${indexJor + 1}`}</h1>
                  </div>
                  <div className='d-flex justify-content-around align-items-center mt-2'>
                    <div className="form-floating">
                      <input type="time" id={`horaCalentamiento${indexJor}`} className='form-control'/>
                      <label htmlFor="deportCC">Calentamiento</label>
                    </div>
                    <div className="form-floating">
                      <input type="text" className='text-center form-control' id={`fechaJor${indexJor}`} placeholder='Fecha de inicio ej: 02 de Julio del 2023'/>
                      <label htmlFor="fechaPlanilla">Fecha Jornada {indexJor + 1}</label>
                    </div>
                    <div className="form-floating">
                      <input type="number" id={`cantPruebas${indexJor}`} className='form-control' defaultValue={jor.cantPruebas} placeholder='10'/>
                      <label htmlFor="deportCC">Cantidad Pruebas</label>
                    </div>
                    <div className="form-floating">
                      <input type="time" id={`horaInicio${indexJor}`} className='form-control'/>
                      <label htmlFor="deportCC">Competencia</label>
                    </div>
                  </div>
                  <hr />
                  <div className='d-flex flex-wrap justify-content-center'>
                    <Button type='button' icon='pi pi-plus' className='btn btn-success me-1' label='Requisito' onClick={() => anadirRequisito(indexJor)}></Button>
                    <Button type='button' icon='pi pi-minus' className='btn btn-danger' label='Requisito' onClick={() => quitarRequisito(indexJor)}></Button>
                  </div>
                  {jor.requisitos.length !== 0 ?(
                    jor.requisitos.map((req, indexReq)=>{
                      return <div key={indexReq}>
                        <div className='d-flex flex-wrap justify-content-around align-items-center mt-1'>
                          <div className="form-floating">
                            <input type="number" id={`edadStart${indexReq}/${indexJor}`} className='form-control' defaultValue={req.edadStart} placeholder='0'/>
                            <label htmlFor="deportCC">Edad Inicio</label>
                          </div>
                          <div className="form-floating">
                            <input type="number" id={`cantPruebas${indexReq}/${indexJor}`} className='form-control' defaultValue={req.cantPruebas} placeholder='10'/>
                            <label htmlFor="deportCC">Cantidad Pruebas</label>
                          </div>
                          <div className="form-floating">
                            <input type="number" id={`edadEnd${indexReq}/${indexJor}`} className='form-control' defaultValue={req.edadEnd} placeholder='100'/>
                            <label htmlFor="deportCC">Edad Final</label>
                          </div>
                        </div>
                        <hr />
                      </div>
                    })
                  ):(
                    <></>
                  )}
                  {mensajesGuia(indexJor, 'horaCalentamiento', 'Hora de Calentamiento', 'bg-primary')}
                  {mensajesGuia(indexJor, 'horaInicio', 'Hora de Inicio', 'bg-primary')}
                  {mensajesGuia(indexJor, 'cantPruebas', '¿Cuantas pruebas vas a permitir?', 'bg-primary')}
                  {mensajesGuia(indexJor, 'edadStart', 'Edad Inicial que se le aplicara el requisito', 'bg-primary')}
                  {mensajesGuia(indexJor, 'edadEnd', 'Edad Final que se le aplicara el requisito', 'bg-primary')}
                  {jor.categoria.length === 0 ? (
                    <Button type='button' icon='pi pi-plus-circle' label='Categoria' className='btn btn-success mt-1' onClick={() => anadirCategoria(indexJor)}></Button>
                  ):(
                    <div className="alert alert-primary alert-dismissible fade show" role="alert">
                      <h3>{`Categorias Inscritas ${jor.categoria.length}`}</h3>
                    </div>
                  )}
                  {jor.categoria.length !== 0 ? (
                    jor.categoria.map((cate, indexCate)=>{
                      return <div key={cate} className='d-flex justify-content-center align-items-center'>
                        <div className="form-floating">
                          <select name={`categoria${indexCate}/${indexJor}`} defaultValue={cate} id={`categoria${indexCate}/${indexJor}`} className='form-select mt-2' required>
                            <option value="">Elige la categoria</option>
                            {listadoCategorias.length !== 0 ?(
                              listadoCategorias.map((prue, indexPrue)=>{
                                return <option key={indexPrue} value={prue.valor}>{prue.valor}</option>
                              })
                            ):(
                              <></>
                            )}
                          </select>
                          <label htmlFor="deportCC">{listadoCategorias.length} Categorias Disponibles</label>
                        </div>
                        <div>
                          <Button type='button' icon='pi pi-plus' severity='success' className='ms-1 me-1' raised style={{borderRadius: '50%'}} onClick={() => anadirCategoria(indexJor, indexCate)}></Button>
                          <Button type='button' icon='pi pi-minus' severity='danger' raised style={{borderRadius: '50%'}} onClick={() => quitarCategoria(indexJor, indexCate)}></Button>
                        </div>
                      </div>
                    })
                  ):(
                    <></>
                  )}
                  <hr />
                  {jor.pruebas.length === 0 && jor.categoria.length !== 0 ?(
                    <Button type='button' icon='pi pi-plus-circle' label='Prueba' className='btn btn-success mt-1' onClick={() => anadirPrueba(indexJor)}></Button>
                  ):(
                    <div className="alert alert-primary alert-dismissible fade show" role="alert">
                      <h3>{`Pruebas Inscritas ${jor.pruebas.length}`}</h3>
                    </div>
                  )}
                  {jor.pruebas.length !== 0 ? (
                    jor.pruebas.map((prue, indexPrue)=>{
                      return <div key={prue.idPrueba} className='d-flex justify-content-center align-items-center'>
                        <div className="form-floating">
                          <select key={indexPrue} id={`prueba${indexPrue}/${indexJor}`} className='form-select mt-2' required>
                            <option value="">Elige la prueba</option>
                            {pruebasEspecificas(indexJor).map((prueEspe, indexPrueEspe)=>{
                              return <option className={coloresCategoria(prueEspe.categoria, listadoCategorias)} value={prueEspe.id} key={indexPrueEspe}>{prueEspe.prueba + ' - ' + acomodarTextoCategoria([prueEspe.categoria], listadoCategorias) + (prueEspe.requisitos[0].edadInicio !== '' ? prueEspe.requisitos[0].edadInicio + ' a ' + prueEspe.requisitos[prueEspe.requisitos.length - 1].edadFin : '')}</option>
                            })}
                          </select>
                          <label htmlFor="prueba">{pruebasEspecificas(indexJor).length} Pruebas Disponibles</label>
                        </div>
                        {torneoTemporal[indexTorneo].evento === 'finales' ? (
                          <div className='d-flex justify-content-around align-items-center'>
                            <div className="form-floating">
                              <input style={{width: '68px'}} type="number" id={`jornada${indexJor}prueba${indexPrue}TiempoMinutos`} className='form-control' placeholder='0' defaultValue={0}/>
                              <label htmlFor="deportCC">Min.</label>
                            </div>
                            <div className="form-floating">
                              <input style={{width: '68px'}} type="number" id={`jornada${indexJor}prueba${indexPrue}TiempoSegundos`} className='form-control' placeholder='0' defaultValue={0}/>
                              <label htmlFor="deportCC">Seg.</label>
                            </div>
                            <div className="form-floating">
                              <input style={{width: '68px'}} type="number" id={`jornada${indexJor}prueba${indexPrue}TiempoMilisegundos`} className='form-control' placeholder='0' defaultValue={0}/>
                              <label htmlFor="deportCC">Mili.</label>
                            </div>
                          </div>
                        ):(
                          <></>
                        )}
                        <div>
                          <Button type='button' icon='pi pi-plus' severity='success' raised style={{borderRadius: '50%'}} className='me-1 ms-1' onClick={() => anadirPrueba(indexJor, indexPrue)}></Button>
                          <Button type='button' icon='pi pi-minus' severity='danger' raised style={{borderRadius: '50%'}} onClick={() => quitarPrueba(indexJor, indexPrue)}></Button>
                        </div>
                      </div>
                    })
                  ):(
                    <></>
                  )}
                </div>
                })}
                <hr />
                <Button type='submit' icon='pi pi-check-circle' label='Guardar Cambios' className='btn btn-success mb-1'></Button>
                </form>
              </>
            )}
          </div>
        </div>
        <Tooltip target=".speeddial-bottom-right .p-speeddial-action" position="left" />
        <SpeedDial model={items} direction="up" className="position-fixed speeddial-bottom-right planilla" buttonClassName="p-button-danger" style={{bottom: '10px', right: '10px'}}/>
      </div>
    </>
  )
}

export default PublicarTorneo;