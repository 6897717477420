export function acmodarEdadesRelevosMaster(edad){
  let text = '';
  switch (edad) {
    case 120:
      text = '120 - 159'
      break;
    case 160:
      text = '160 - 199'
      break;
    case 200:
      text = '200 - 239'
      break;
    case 240:
      text = '240 - 279'
      break;
    case 280:
      text = '280 - 310'
      break;
    default:
      break;
  }
  return text;
}

export function completarPruebas(torneoEspecifico, listPruebas, listadoCategorias) {
  let auxPruebas = [];
  let aux = [];
  let contador = 1;

  if (torneoEspecifico.length !== 0) {
    torneoEspecifico.jornadas.forEach((torn) => {
      torn.categoria.forEach((cate) => {
        const categoria = listadoCategorias.find((c) => c.valor === cate);
        const auxListPruebas = listPruebas.filter((list)=> list.categoria === cate);
        if (categoria && torn.pruebas.length !== 0) {
          if(categoria.valor === cate){
            torn.pruebas.forEach((prue)=>{
              const auxPrueba = auxListPruebas.filter((auxList)=>{return auxList.id === prue.idPrueba});
              if(auxPrueba.length !== 0){
                auxPrueba[0].requisitos.forEach((req)=>{
                  const auxObjPrueba = {
                    id: contador++,
                    categoriaDeportistas: categoria.valor,
                    categoria: `${req.genero === 'Femenino' ? '(F)' : req.genero === 'Masculino' ? '(M)' : '(X)'} ${categoria.titulo !== ''? categoria.titulo : req.edadInicio === req.edadFin ? req.edadInicio + ' Años' : `${req.edadInicio} - ${req.edadFin} Años`} ${req.genero === 'Femenino' ? 'F' : req.genero === 'Masculino' ? 'M' : 'X'}`,
                    prueba: {
                      id: auxPrueba[0].id,
                      prueba: auxPrueba[0].prueba,
                    },
                    edadInicio: req.edadInicio,
                    edadFin: req.edadFin,
                    genero: req.genero,
                    deportistas: [],
                  };
                  auxPruebas.push(auxObjPrueba);
                })
              }
            })
          }
        }
      });
    });
    torneoEspecifico.jornadas.forEach((tor)=>{
      // Crear un nuevo arreglo grande organizado según el arreglo pequeño
      const arregloOrganizado = [];
      tor.pruebas.forEach((objPequeño) => {
        const objetosCorrespondientes = auxPruebas.filter(objGrande => objGrande.prueba.prueba === objPequeño.prueba && objGrande.prueba.id === objPequeño.idPrueba);
        arregloOrganizado.push(...objetosCorrespondientes);
      });
      aux.push(arregloOrganizado)
    })
    let contadorr = 1;
    for (let i = 0; i < aux.length; i++) {
      for (let j = 0; j < aux[i].length; j++) {
         aux[i][j].id = contadorr;
         contadorr++;
       }
    }
  }

  return aux;
}